import React, { useCallback, useEffect, useRef, useState } from 'react';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  grantEmployeeRole,
  credentialActionResult,
  revokeEmployeeRole,
  allowedDomains,
  AdminFilter,
  actionErrorMap,
  convertFilters,
} from '../../pages/users/utils/userCredentilasUtils';
import { theme } from '../../assets/styles/theme';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import InputWithLabel from '../inputs/InputWithLabel';
import RectangularButton from '../buttons/RectangularButton';
import DataTable, { Column, TableFilterStateDto } from '../common/table/DataTable';
import { CredentialDto } from '../../services/Auth/authService.dto';
import { authService } from '../../services/Auth/authService';
import { DataTableRef } from '../common/table/dataTableRef';
import { useAuth } from '../../providers/AuthProvider';
import { TextSmall } from '../typography/Texts';
import AdminsPopup from './AdminsPopup';

const FilterSection = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
`;

const ApplyButton = styled(RectangularButton)`
  min-width: 80px;
  font-weight: normal;
  height: 30px;
  margin: 5px;
`;

const DomainInfo = styled(TextSmall)`
  color: ${theme.color.lightBlack};
  margin-bottom: 10px;
`;

const iconStyle = {
  fontSize: 20,
  cursor: 'pointer',
};

interface Props {
  displayAdmins: boolean;
}

const AdminsTable = (props: Props) => {
  const { t } = useTranslation();
  const [popupAnchor, setPopupAnchor] = useState<SVGSVGElement>();
  const [selectedCredentials, setSelectedCredentials] = useState<CredentialDto>();
  const { handleError, errorMessage } = useGlobalError();
  const [filterData, setFilterData] = useState<AdminFilter>();
  const [filterState, setFilterState] = useState<AdminFilter>({ employee: false });
  const dataTableRef = useRef<DataTableRef>(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (props.displayAdmins !== filterState.employee) clearFilters(props.displayAdmins);
  }, [props.displayAdmins]);

  const clearFilters = (employee: boolean) => {
    setFilterState({ employee });
    setFilterData({ employee });
  };

  const requestErrorMap = new Map([
    [404, t('users.error.credential-not-found')],
    [409, t('users.error.role-frozen')],
    [422, t('users.error.empty-authorities')],
  ]);

  const columns: Column<CredentialDto>[] = [
    {
      id: 'username',
      title: t('users.columns.username'),
      isSortable: true,
      align: 'left',
      renderCell: data => data.username,
    },
    {
      id: 'role',
      title: t('users.columns.role'),
      align: 'left',
      renderCell: data => (data.isEmployee ? t('users.role-employee').toString() : t('users.role-user').toString()),
    },
    {
      id: 'actions',
      title: t('users.columns.actions'),
      align: 'left',
      renderCell: data => {
        const displayRemovePermission = props.displayAdmins && currentUser?.id !== data.userId;
        const displayAddPermission = !props.displayAdmins;

        if (displayRemovePermission) {
          return (
            <div title={t('users.revoke-tooltip')}>
              <CancelPresentationIcon
                style={iconStyle}
                onClick={elem => {
                  setSelectedCredentials(data);
                  setPopupAnchor(elem.currentTarget);
                }}
              />
            </div>
          );
        } else if (displayAddPermission) {
          return (
            <div title={t('users.grant-tooltip')}>
              <VpnKeyIcon
                style={iconStyle}
                onClick={elem => {
                  setSelectedCredentials(data);
                  setPopupAnchor(elem.currentTarget);
                }}
              />
            </div>
          );
        }
      },
    },
  ];

  const onAccept = (credentials?: CredentialDto) => {
    if (credentials) {
      const result = props.displayAdmins ? revokeEmployeeRole(credentials) : grantEmployeeRole(credentials);
      if (result.actionResult === credentialActionResult.UPDATED)
        authService
          .updateCredentials(result.credentials.id, result.credentials.authorities)
          .then(() => dataTableRef.current?.refresh())
          .catch(e => handleError(e, t('error.generic'), requestErrorMap));
      else errorMessage(actionErrorMap.get(result.actionResult) || t('error.generic'));
    }
    onCancel();
  };

  const handleApply = () => {
    setFilterData(filterState);
  };

  const onCancel = () => {
    setPopupAnchor(undefined);
    setSelectedCredentials(undefined);
  };

  const fetchCredentials = useCallback((state: TableFilterStateDto<any>) => {
    return authService.fetchCredentials(convertFilters(state)).then(response => response.data);
  }, []);

  return (
    <>
      <FilterSection>
        <InputWithLabel
          key={'username'}
          label={t('users.filters.email')}
          value={filterState.username || ''}
          minWidth={300}
          onChange={value => setFilterState({ ...filterState, username: value })}
        />
        <ApplyButton label={t('common.apply')} onClick={handleApply} />
      </FilterSection>
      <DomainInfo>
        {props.displayAdmins ? '\u00a0' : t('users.allowed-domains', { domains: allowedDomains.join(', ') })}
      </DomainInfo>
      <DataTable columns={columns} filterData={filterData} onTableStateChanged={fetchCredentials} ref={dataTableRef} />
      <AdminsPopup
        anchor={popupAnchor}
        credentials={selectedCredentials}
        grantAuthorities={!props.displayAdmins}
        onClosePressed={onCancel}
        onConfirm={onAccept}
      />
    </>
  );
};

export default AdminsTable;
