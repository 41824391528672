import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import { theme } from '../../assets/styles/theme';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import UserDetails from '../users/UserDetails';
import { itemService } from '../../services/Item/itemService';
import { userService } from '../../services/User/userService';
import { campaignService } from '../../services/Campaign/campaignService';
import { UserPublicDto } from '../../services/User/userService.dto';
import { ObjectType } from '../../pages/report/utils/dictionary';
import { ItemPublicDto } from '../../services/Item/itemService.dto';
import { CampaignPublicDto, CommentDto } from '../../services/Campaign/campaignService.dto';
import closeIcon from '../../assets/svg/close.svg';
import CampaignDetails from '../campaign/CampaignDetails';
import CommentDetails from '../campaign/CommentDetails';
import ItemDetails from '../item/ItemDetails';
import { useGlobalError } from '../../providers/GlobalErrorProvider';

const StyledModal = styled(Modal)`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  z-index: ${theme.zIndex.modal};
`;

const Container = styled.div`
  width: 60%;
  min-height: 40%;
  border-radius: 12px;
  background-color: ${theme.color.white};
  display: flex;
  outline: none;
  padding: 10px 10px 50px;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const CloseButton = styled.img`
  display: flex;
  cursor: pointer;
`;

export interface ModalDetails {
  type: string;
  id: number;
}

interface Props {
  modalDetails: ModalDetails;
  open: boolean;
  onClose: () => void;
}

const ReportDetailsModal = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserPublicDto>();
  const [campaignDetails, setCampaignDetails] = useState<CampaignPublicDto>();
  const [itemDetails, setItemDetails] = useState<ItemPublicDto>();
  const [commentDetails, setCommentDetails] = useState<CommentDto>();
  const { handleError } = useGlobalError();

  useEffect(() => {
    if (props.open) {
      if (props.modalDetails.type === ObjectType.USER) fetchUserDetails();
      if (props.modalDetails.type === ObjectType.CAMPAIGN) fetchCampaignDetails();
      if (props.modalDetails.type === ObjectType.ITEM) fetchItemDetails();
      if (props.modalDetails.type === ObjectType.COMMENT) fetchCommentDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.modalDetails]);

  const fetchUserDetails = () => {
    setIsLoading(true);
    userService
      .fetchPublicUser(props.modalDetails.id)
      .then(response => {
        setUserDetails(response.data);
      })
      .catch(error => {
        handleError(error, error.message);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchCampaignDetails = () => {
    setIsLoading(true);
    campaignService
      .fetchCampaign(props.modalDetails.id)
      .then(response => {
        setCampaignDetails(response.data);
      })
      .catch(error => {
        handleError(error, error.message);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchItemDetails = () => {
    setIsLoading(true);
    itemService
      .fetchItem(props.modalDetails.id)
      .then(response => {
        setItemDetails(response.data);
      })
      .catch(error => {
        handleError(error, error.message);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchCommentDetails = () => {
    setIsLoading(true);
    campaignService
      .fetchComment(props.modalDetails.id)
      .then(response => {
        setCommentDetails(response.data);
      })
      .catch(error => {
        handleError(error, error.message);
      })
      .finally(() => setIsLoading(false));
  };

  const onModalClose = () => {
    setUserDetails(undefined);
    setCampaignDetails(undefined);
    setItemDetails(undefined);
    setCommentDetails(undefined);
    props.onClose();
  };

  const renderView = () => {
    if (userDetails) return <UserDetails userDetails={userDetails} />;
    if (campaignDetails) return <CampaignDetails campaignDetails={campaignDetails} />;
    if (itemDetails) return <ItemDetails itemDetails={itemDetails} />;
    if (commentDetails) return <CommentDetails commentDetails={commentDetails} />;
  };

  return (
    <StyledModal open={props.open} onClose={onModalClose}>
      <Container>
        <HeaderContainer>
          <p style={{ margin: 0 }}>{`${props.modalDetails.type} ID: ${props.modalDetails.id}`}</p>
          <CloseButton src={closeIcon} alt={'close'} onClick={onModalClose} />
        </HeaderContainer>
        {isLoading ? <LoadingIndicator /> : renderView()}
      </Container>
    </StyledModal>
  );
};

export default ReportDetailsModal;
