import React, { useEffect, useState } from 'react';
import { UserPublicDto } from '../../services/User/userService.dto';
import { userService } from '../../services/User/userService';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import LoadingIndicator from '../common/LoadingIndicator';
import UserDetails from './UserDetails';

interface Props {
  userId: number;
}

const UserDetailsPopover = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<UserPublicDto>();
  const { handleError } = useGlobalError();

  useEffect(() => {
    setIsLoading(true);
    userService
      .fetchPublicUser(props.userId)
      .then(response => {
        setUserDetails(response.data);
      })
      .catch(error => {
        handleError(error, error.message);
      })
      .finally(() => setIsLoading(false));
  }, [props.userId]);

  if (!userDetails || isLoading) return <LoadingIndicator />;
  return <UserDetails userDetails={userDetails} />;
};

export default UserDetailsPopover;
