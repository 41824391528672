import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemPublicDto } from '../../services/Item/itemService.dto';
import { shortDescription, SHORT_DESC_LENGTH, toCurrencyString } from '../../pages/report/utils/reportConverters';
import { DetailViewData, DetailsViewTag } from '../typography/Headers';
import { DetailsViewText } from '../typography/Texts';
import { Container, DetailsContainer, RowContainer } from '../typography/DetailsViewCommons';
import ImagePreview from '../common/image/ImagePreview';

interface Props {
  itemDetails: ItemPublicDto;
}

const ItemDetails = (props: Props) => {
  const { t } = useTranslation();

  let shipping: string[] = [];
  if (props.itemDetails.canNationwideShipping) shipping = ['Shipping'];
  if (props.itemDetails.canLocalPickUpDelivery) shipping = [...shipping, 'Pickup'];

  return (
    <Container>
      <ImagePreview imageUrls={props.itemDetails?.imageUrlList} />
      <DetailsContainer>
        <RowContainer>
          <DetailsViewTag>{t('item-details.title-label')}</DetailsViewTag>
          <DetailViewData>{props.itemDetails.title}</DetailViewData>
        </RowContainer>
        <RowContainer>
          <DetailsViewTag>{t('item-details.description-label')}</DetailsViewTag>
          <DetailViewData>{shortDescription(props.itemDetails.description)}</DetailViewData>
        </RowContainer>
        {props.itemDetails.description && props.itemDetails.description.length >= SHORT_DESC_LENGTH && (
          <DetailsViewText>{props.itemDetails.description}</DetailsViewText>
        )}
        <RowContainer>
          <DetailsViewTag>{t('item-details.price-label')}</DetailsViewTag>
          <DetailViewData>{toCurrencyString(props.itemDetails.price, props.itemDetails.currency)}</DetailViewData>
        </RowContainer>
        <RowContainer>
          <DetailsViewTag>{t('item-details.delivery-label')}</DetailsViewTag>
          <DetailViewData>{shipping.join(', ')}</DetailViewData>
        </RowContainer>
        <RowContainer>
          <DetailsViewTag>{t('item-details.condition-label')}</DetailsViewTag>
          <DetailViewData>{props.itemDetails.condition}</DetailViewData>
        </RowContainer>
      </DetailsContainer>
    </Container>
  );
};

export default ItemDetails;
