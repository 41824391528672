import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessages } from '../../../providers/messages/MessagesProvider';
import { useSearchMessages } from '../../../providers/messages/useSearchMessages';
import { ConversationStatus } from '../../../services/Message/messageService.dto';
import ConversationList from '../ConversationList';
import LoadingIndicator from '../../common/LoadingIndicator';
import SearchConversationList from '../SearchConversationList';
import { SearchType } from '../searchType';

interface Props {
  path: string;
  selectedConversationId?: number;
  onCloseConversation: () => void;
  searchValue: string;
  searchType: SearchType;
}

const ConversationTabSupported = (props: Props) => {
  const { searchValue, searchType } = props;
  const { t } = useTranslation();
  const { supportedConversations } = useMessages();

  const searchSupported = useSearchMessages(ConversationStatus.CLOSED);

  useEffect(() => {
    supportedConversations.fetchConversations();
    return () => supportedConversations.resetConversations();
  }, []);

  useEffect(() => {
    searchSupported.fetchMessagesBySearch(searchValue, searchType);
    if (searchValue) props.onCloseConversation();
  }, [searchValue, searchType]);

  const renderConversationsList = () => {
    if (searchValue) {
      if (!searchSupported.searchResult || searchSupported.isLoading) return <LoadingIndicator />;
      return (
        <SearchConversationList
          path={props.path}
          conversations={searchSupported.searchResult}
          selectedConversationId={props.selectedConversationId}
        />
      );
    } else {
      return (
        <ConversationList
          title={t('messages.header-supported')}
          path={props.path}
          selectedConversationId={props.selectedConversationId}
          {...supportedConversations}
        />
      );
    }
  };

  return renderConversationsList();
};

export default ConversationTabSupported;
