import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TextSmall } from '../../typography/Texts';
import RectangularButton from '../../buttons/RectangularButton';

const Button = styled(RectangularButton)`
  font-weight: normal;
  min-width: 80px;
`;

const ErrorMessage = styled(TextSmall)`
  white-space: pre-wrap;
`;

interface Props {
  errorMsg: string;
  onClose: () => void;
}

const ErrorDialog = (props: Props) => {
  const { t } = useTranslation();

  const handleClose = () => {
    props.onClose();
  };

  return (
    <div>
      <Dialog open={props.errorMsg !== ''} onClose={handleClose}>
        <DialogContent>
          <ErrorMessage>{props.errorMsg}</ErrorMessage>
        </DialogContent>
        <DialogActions>
          <Button label={t('common.confirm-btn')} onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ErrorDialog;
