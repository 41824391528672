import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputLabel, Slider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';
import { TRANSACTION_FILTER_MAX_AMOUNT } from '../payment/payment.types';
import InputWithLabel from './InputWithLabel';

const Container = styled.div`
  min-width: 240px;
  padding: 5px;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface Props {
  label: string;
  onChange: (value: [number, number]) => void;
  max: number;
  min?: number;
}

const RangeInputWithLabel = (props: Props) => {
  const { t } = useTranslation();
  const { min, max, onChange, label } = props;
  const [value, setValue] = useState<[number, number]>([min || 0, max]);

  useEffect(() => {
    setValue([min || 0, max]);
  }, [min, max]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as [number, number]);
  };

  const commitValue = (value: number[]) => {
    setValue(value as [number, number]);
    onChange(value as [number, number]);
  };

  return (
    <Container>
      <InputLabel style={{ fontSize: 12 }} id='label'>
        {label}
      </InputLabel>
      <Slider
        onChangeCommitted={(event, value) => commitValue(value as [number, number])}
        onChange={handleChange}
        valueLabelDisplay='auto'
        value={value}
        disableSwap
        min={min}
        max={max}
        sx={{
          color: `${theme.color.darkerGray}`,
          '& .MuiSlider-thumb': {
            color: `${theme.color.lighterGray}`,
          },
        }}
      />
      <InputContainer>
        <InputWithLabel
          label={t('common.min')}
          onChange={v => Number(v) <= TRANSACTION_FILTER_MAX_AMOUNT && commitValue([Number(v), value[1]])}
          value={value[0]?.toString()}
          inputMode={'number'}
        />
        <InputWithLabel
          label={t('common.max')}
          onChange={v => commitValue([value[0], Number(v)])}
          value={value[1]?.toString()}
          inputMode={'number'}
        />
      </InputContainer>
    </Container>
  );
};

export default RangeInputWithLabel;
