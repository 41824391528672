import React from 'react';
import { Popover } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ConfirmButton } from '../buttons/popoverButtons';
import { FormHeader } from '../typography/PopoverTypography';
import { UserDetailsDto } from '../../services/User/userService.dto';

const FormContainer = styled.div`
  padding: 10px;
`;

const ButtonsContainer = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
`;

interface Props {
  anchor?: HTMLDivElement;
  userData: UserDetailsDto;
  onClosePressed: () => void;
  onConfirm: (credentials?: UserDetailsDto) => void;
}

const CloseAccountPopover = (props: Props) => {
  const { t } = useTranslation();
  const username = `${props.userData.firstName} ${props.userData.lastName}`;

  return (
    <Popover
      anchorEl={props.anchor}
      open={props.anchor !== undefined}
      onClose={props.onClosePressed}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
      <FormContainer>
        <FormHeader>{t('users.confirm-close-account', { username })}</FormHeader>
        <ButtonsContainer>
          <ConfirmButton label={t('common.confirm-btn')} onClick={() => props.onConfirm(props.userData)} />
          <ConfirmButton label={t('common.cancel')} onClick={props.onClosePressed} />
        </ButtonsContainer>
      </FormContainer>
    </Popover>
  );
};

export default CloseAccountPopover;
