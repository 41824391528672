import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';
import PageContainer from '../../components/common/PageContainer';
import CemeteryTable from '../../components/maintenance/CemeteryTable';
import CemeteryHeader from '../../components/maintenance/CemeteryHeader';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const Cemetery = () => {
  const [databaseMessages, setDatabaseMessages] = useState<number>(0);
  const [filtersMap, setFiltersMap] = useState<Map<string, any>>();

  return (
    <PageContainer>
      <Container>
        <CemeteryHeader databaseMessages={databaseMessages} onApply={setFiltersMap} />
        <CemeteryTable messagesInDatabaseCount={setDatabaseMessages} filtersMap={filtersMap} />
      </Container>
    </PageContainer>
  );
};

export default Cemetery;
