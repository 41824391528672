import React from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { TextMain } from '../typography/Texts';
import LoadingIndicator from './LoadingIndicator';

const StyledDialogContent = styled(DialogContent)`
  width: 200px;
  height: 200px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

interface Props {
  isOpen: boolean;
}

const LoadingDialog = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog open={props.isOpen}>
        <StyledDialogContent>
          <LoadingIndicator />
          <TextMain>{t('common.downloading')}</TextMain>
        </StyledDialogContent>
      </Dialog>
    </div>
  );
};

export default LoadingDialog;
