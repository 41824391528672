import React from 'react';
import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';
import LoadingIndicator from './LoadingIndicator';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${theme.zIndex.fullScreenFeatures};
`;

const LoadingOverlay = () => {
  return (
    <Container>
      <LoadingIndicator />
    </Container>
  );
};

export default LoadingOverlay;
