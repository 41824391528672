import React from 'react';
import styled from 'styled-components';
import { ConversationWithDataDto } from '../../../services/Message/messageService.dto';
import { SearchMessageDto } from '../../../services/Search/searchService.dto';
import ProfilePicture from '../../common/ProfilePicture';
import { UserPublicDto } from '../../../services/User/userService.dto';
import { TextMain, TextSmall } from '../../typography/Texts';
import { dateUtils } from '../../../utils/dateUtils';
import { useMessages } from '../../../providers/messages/MessagesProvider';
import { useGlobalData } from '../../../providers/GlobalDataProvider';
import { theme } from '../../../assets/styles/theme';

const Container = styled.div`
  flex-direction: column;
  margin: 10px 0;
  overflow-y: auto;
`;

const MessageContainer = styled.div`
  display: flex;
  margin: 10px 5px 10px 0;
  flex-direction: row;
  cursor: pointer;
  padding: 8px;
  flex: 1;
  border-radius: 10px;
  position: relative;
`;

const TextContainer = styled.div`
  margin-left: 10px;
  background-color: ${theme.color.lighterGray};
  white-space: pre-wrap;
  border-radius: 10px;
  padding: 8px;
  flex: 1;
`;

const MessageDate = styled(TextSmall)`
  font-style: italic;
  position: absolute;
  right: 14px;
`;

const AuthorContainer = styled.div`
  display: flex;
`;

interface Props {
  conversation: ConversationWithDataDto;
  searchValue: string;
}

interface ListItemProps {
  message: SearchMessageDto;
  author?: UserPublicDto;
  searchValue: string;
}

const boldString = (str: string, search: string) => {
  let result = str;
  search.split(' ').forEach(word => {
    const reg = new RegExp('(' + word + ')', 'gi');
    result = result.replace(reg, '<b>$1</b>');
  });
  return result;
};

const SearchMessageListItem = (props: ListItemProps) => {
  const authorName = `${props.author?.firstName} ${props.author?.lastName}`;
  const content = boldString(props.message.content, props.searchValue);

  return (
    <MessageContainer>
      <ProfilePicture imageUrl={props.author?.imageUrl} />
      <TextContainer>
        <AuthorContainer>
          <TextMain>{authorName}</TextMain>
          <MessageDate>{dateUtils.formatTimeElapsed(new Date(props.message.created))}</MessageDate>
        </AuthorContainer>
        <TextSmall dangerouslySetInnerHTML={{ __html: content }} />
      </TextContainer>
    </MessageContainer>
  );
};

const SearchMessagesList = (props: Props) => {
  const { selectConversation } = useMessages();
  const { supporters } = useGlobalData();
  return (
    <Container>
      {props.conversation.searchMessages?.map(message => {
        const author =
          message.authorId === message.endUserId
            ? props.conversation.endUser
            : supporters.filter(supporter => supporter.id === message.authorId)[0];
        return (
          <div key={message.messageId} onClick={() => selectConversation(props.conversation, message.messageId)}>
            <SearchMessageListItem message={message} author={author} searchValue={props.searchValue} />
          </div>
        );
      })}
    </Container>
  );
};

export default SearchMessagesList;
