import Stomp, { Client, Frame, Message } from 'stompjs';
import SockJS from 'sockjs-client';
import { v4 as uuidv4 } from 'uuid';
import { apiUrl } from '../apiService';
import { ConversationWithDataDto, MessageDto } from '../Message/messageService.dto';
import { messageService } from '../Message/messageService';

type MsgNewCallback = (message: MessageDto, conversation?: ConversationWithDataDto) => void;
type MsgConfirmationCallback = (msgIds: number[]) => void;

class WebsocketService {
  stompClient: Client | null = null;
  connecting = false;
  newMsgListeners: Map<string, MsgNewCallback> = new Map<string, MsgNewCallback>();
  msgConfirmationListeners: Map<string, MsgConfirmationCallback> = new Map<string, MsgConfirmationCallback>();

  connect = async () => {
    if (!this.isConnected()) {
      this.connecting = true;
      const token = localStorage.getItem('authToken') || '';
      const socket = new SockJS(`${apiUrl}/api/message/public/iga-message-websocket`);
      this.stompClient = Stomp.over(socket);
      // this.stompClient.debug = null;
      await this.stompClient.connect({ token }, this.connectCallback, this.errorCallback);
    }
  };

  isConnected() {
    return (this.stompClient && this.stompClient.connected) || this.connecting;
  }

  disconnect = async () => {
    if (this.stompClient) this.stompClient.ws.close();
  };

  connectCallback = (frame?: Frame) => {
    this.connecting = false;
    if (this.stompClient) this.stompClient.subscribe('/user/exchange/amq.direct/messages', this.messageCallback);
  };

  errorCallback = (frame: string | Frame) => {
    this.connecting = false;
    if (!this.isConnected()) {
      // try to reconnect after 20s if error occurred
      setTimeout(() => this.connect(), 20000);
    }
  };

  messageCallback = (message: Message) => {
    const payload = JSON.parse(message.body);
    if (payload.type === 'MESSAGE') {
      const messageDto: MessageDto = payload.content;
      if (messageDto.conversationId) {
        messageService.fetchConversationBySupportConversationId(messageDto.conversationId).then(response => {
          if (response.data.content) {
            const conversation = response.data.content[0];
            this.newMsgListeners.forEach((callback: MsgNewCallback) => callback(messageDto, conversation));
          }
        });
      }
    } else if (payload.type === 'CONFIRMATION') {
      this.msgConfirmationListeners.forEach((callback: MsgConfirmationCallback) => callback(payload.content));
    }
  };

  registerNewMsgListener = (msgCallback: MsgNewCallback) => {
    const id = uuidv4();
    this.newMsgListeners.set(id, msgCallback);
    return id;
  };

  unregisterNewMsgListener = (id: string) => this.newMsgListeners.delete(id);

  registerMsgConfirmationListener = (msgCallback: MsgConfirmationCallback) => {
    const id = uuidv4();
    this.msgConfirmationListeners.set(id, msgCallback);
    return id;
  };

  unregisterMsgConfirmationListener = (id: string) => this.msgConfirmationListeners.delete(id);
}

const websocketService = new WebsocketService();
export { websocketService, WebsocketService };
