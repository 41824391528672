import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateUtils } from '../../utils/dateUtils';
import { DataTableRef } from '../common/table/dataTableRef';
import DataTable, { Column, TableFilterStateDto } from '../common/table/DataTable';
import { maintenanceService } from '../../services/Maintenance/maintenanceService';
import { CemeteryDto, PartialMessageUpdateDto } from '../../services/Maintenance/maintenanceService.dto';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import {
  convertFiltersToCemeteryFilterDto,
  getRoutingKeyAsString,
  toPartialMessageUpdateDto,
} from '../../pages/maintenance/utils/maintenanceConverter';
import ConfirmPopover from '../common/ConfirmPopover';
import JSONPopover from './JSONPopover';
import JSONCell from './JSONCell';
import CemeteryActions from './CemeteryActions';

interface Props {
  messagesInDatabaseCount: (count: number) => void;
  filtersMap?: Map<string, any>;
}

const CemeteryTable = (props: Props) => {
  const { t } = useTranslation();
  const [JSONPopoverAnchor, setJSONPopoverAnchor] = useState<HTMLDivElement | SVGSVGElement>();
  const [confirmPopover, setConfirmPopover] = useState<HTMLElement | SVGSVGElement>();
  const [partialMessage, setPartialMessage] = useState<PartialMessageUpdateDto>();
  const [messageDto, setMessageDto] = useState<CemeteryDto>();
  const [JSONString, setJSONString] = useState<string>('');
  const dataTableRef = useRef<DataTableRef>(null);
  const { handleError } = useGlobalError();

  const columns: Column<CemeteryDto>[] = [
    {
      id: 'id',
      title: t('maintenance.cemetery.columns.id'),
      renderCell: data => data.id,
    },
    {
      id: 'headers',
      title: t('maintenance.cemetery.columns.headers'),
      renderCell: data => (
        <JSONCell
          jsonData={data.headers}
          onPress={(element, JSONData) => {
            setJSONPopoverAnchor(element);
            setJSONString(JSONData);
          }}
        />
      ),
    },
    {
      id: 'created',
      title: t('maintenance.cemetery.columns.pulling'),
      minWidth: 100,
      isSortable: true,
      renderCell: data => dateUtils.toLocalDateTimeString(data.created),
    },
    {
      id: 'firstDeathExchange',
      title: t('maintenance.cemetery.columns.first-death-exchange'),
      renderCell: data => data.firstDeathExchange,
    },
    {
      id: 'payload',
      title: t('maintenance.cemetery.columns.payload'),
      renderCell: data => (
        <JSONCell
          jsonData={data.payload}
          onPress={(element, JSONData) => {
            setJSONPopoverAnchor(element);
            setJSONString(JSONData);
          }}
        />
      ),
    },
    {
      id: 'lastDeathTime',
      title: t('maintenance.cemetery.columns.last-death-date'),
      minWidth: 100,
      isSortable: true,
      renderCell: data => dateUtils.toLocalDateTimeString(data.lastDeathTime),
    },
    {
      id: 'firstDeathQueue',
      title: t('maintenance.cemetery.columns.first-death-queue'),
      renderCell: data => data.firstDeathQueue,
    },
    {
      id: 'lastDeathQueue',
      title: t('maintenance.cemetery.columns.last-death-queue'),
      renderCell: data => data.lastDeathQueue,
    },
    {
      id: 'deleted',
      title: t('maintenance.cemetery.columns.deleted'),
      renderCell: data => (data.deleted ? 'Yes' : 'No'),
    },
    {
      id: 'requeued',
      title: t('maintenance.cemetery.columns.requeued'),
      renderCell: data => (data.requeued ? 'Yes' : 'No'),
    },
    {
      id: 'updated',
      title: t('maintenance.cemetery.columns.update'),
      minWidth: 100,
      isSortable: true,
      renderCell: data => dateUtils.toLocalDateTimeString(data.updated),
    },
    {
      id: 'pulledBy',
      title: t('maintenance.cemetery.columns.pulled-by'),
      renderCell: data => data.pulledBy,
    },
    {
      id: 'actions',
      title: t('maintenance.cemetery.columns.actions'),
      renderCell: data => (
        <CemeteryActions
          messageDto={data}
          onDeleteClick={onDeleteMessage}
          onRequeueClick={onRequeue}
          onRetryClick={onRetry}
        />
      ),
    },
  ];

  const onDeleteMessage = (message: CemeteryDto, anchor: SVGSVGElement) => {
    setConfirmPopover(anchor);
    setMessageDto(message);
  };

  const onRequeue = (message: CemeteryDto, anchor: SVGSVGElement) => {
    setJSONPopoverAnchor(anchor);
    setMessageDto(message);
    setPartialMessage(toPartialMessageUpdateDto(message));
  };

  const deleteMessage = () => {
    if (messageDto)
      maintenanceService
        .deleteMessage(messageDto.id)
        .then(() => dataTableRef.current?.refresh())
        .catch(e => handleError(e));
    closeConfirmPopover();
  };

  const requeue = (editedPartialMessage: PartialMessageUpdateDto) => {
    if (messageDto) {
      const requeueMessageDto: PartialMessageUpdateDto = {
        ...editedPartialMessage,
        payload: JSON.stringify(editedPartialMessage.payload),
      };
      if (messageDto.payload === requeueMessageDto.payload) requeueMessageDto.payload = undefined;
      if (requeueMessageDto.requeuedToExchange === '') requeueMessageDto.requeuedToExchange = undefined;
      maintenanceService
        .requeueMessage(messageDto.id, requeueMessageDto)
        .then(() => dataTableRef.current?.refresh())
        .catch(e => handleError(e));
    }
    closeJSONPopover();
  };

  const onRetry = (message: CemeteryDto) => {
    const partialMessageDto: PartialMessageUpdateDto = {
      requeuedToExchange: message.firstDeathExchange,
      requeuedRoutingKey: getRoutingKeyAsString(message),
    };
    maintenanceService
      .requeueMessage(message.id, partialMessageDto)
      .then(() => dataTableRef.current?.refresh())
      .catch(e => handleError(e));
  };

  const closeJSONPopover = () => {
    setJSONPopoverAnchor(undefined);
    setPartialMessage(undefined);
    setMessageDto(undefined);
  };

  const closeConfirmPopover = () => {
    setConfirmPopover(undefined);
    setMessageDto(undefined);
  };

  const handleOnTableStateChanged = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const filters = convertFiltersToCemeteryFilterDto(tableState);
    return maintenanceService.fetchCemeteryMessages(filters).then(response => {
      props.messagesInDatabaseCount(response.data.totalElements || 0);
      return response.data;
    });
  }, []);

  return (
    <>
      <DataTable
        columns={columns}
        onTableStateChanged={handleOnTableStateChanged}
        filterData={props.filtersMap}
        ref={dataTableRef}
      />
      {JSONPopoverAnchor && (
        <JSONPopover
          anchor={JSONPopoverAnchor}
          JSONString={JSONString}
          onClosePressed={closeJSONPopover}
          onConfirm={requeue}
          partialMessage={partialMessage}
        />
      )}
      {confirmPopover && (
        <ConfirmPopover
          anchor={confirmPopover}
          onClosePressed={closeConfirmPopover}
          onContinuePressed={deleteMessage}
          confirmMessage={t('maintenance.cemetery.popover.delete-confirm')}
        />
      )}
    </>
  );
};
export default CemeteryTable;
