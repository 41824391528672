import React, { ReactNode } from 'react';
import styled from 'styled-components';
import LaunchIcon from '@mui/icons-material/Launch';
import { theme } from '../../../assets/styles/theme';

const Container = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  color: inherit;
`;

const DataContainer = styled.div`
  text-align: right;
  padding-right: 5px;
`;

const ButtonContainer = styled.div`
  padding-left: 5px;
  justify-content: flex-end;
  display: flex;
`;

interface Props {
  url: string;
  title: string;
  children: ReactNode;
}

const CellWithNavigate = ({ url, title, children }: Props) => {
  return (
    <Container href={url} title={title}>
      <DataContainer>{children}</DataContainer>
      <ButtonContainer>
        <LaunchIcon style={{ fontSize: 18, cursor: 'pointer', color: theme.color.blue }} />
      </ButtonContainer>
    </Container>
  );
};

export default CellWithNavigate;
