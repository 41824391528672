import { useTranslation } from 'react-i18next';
import { userService } from '../../../services/User/userService';
import { useGlobalError } from '../../../providers/GlobalErrorProvider';

export const useUnblockUser = () => {
  const { handleError } = useGlobalError();
  const { t } = useTranslation();

  const unblockErrorMap = new Map([[409, t('users.error.cannot-unblock-after-termination')]]);

  const unblockUser = (userId: number, onUnblocked?: () => void) => {
    userService
      .unblockUser(userId)
      .then(onUnblocked)
      .catch(error => handleError(error, t('error.generic'), unblockErrorMap));
  };

  return { unblockUser };
};
