import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';

export const Title = styled.h2`
  margin: 0;
  font-family: ${theme.font.family.title};
  font-size: ${theme.font.size.l};
  line-height: 111%;
  letter-spacing: -0.2px;
  ${theme.mq.desktop} {
    font-size: ${theme.font.size.xl};
    line-height: 80px;
  }
`;

export const SubTitle = styled.h3`
  margin: 0;
  font-family: ${theme.font.family.title};
  font-size: ${theme.font.size.l};
  line-height: 111%;
  letter-spacing: -0.2px;
  width: 100%;

  ${theme.mq.tablet} {
    font-size: ${theme.font.size.l};
  }
`;

export const SectionHeader = styled.h3`
  margin: 0;
  font-family: ${theme.font.family.main};
  font-size: ${theme.font.size.m};
  line-height: 111%;
  letter-spacing: -0.2px;

  ${theme.mq.phone} {
    font-size: ${theme.font.size.m};
  }
`;

export const DetailViewData = styled.h2`
  margin: 0px 0px 0px 10px;
  color: ${theme.color.lightBlack};
`;

export const DetailsViewTag = styled.h1`
  margin: 0;
  color: ${theme.color.red};
`;
