import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';

export const TextMain = styled.p`
  margin: 0;
  font-family: ${theme.font.family.main};
  font-size: ${theme.font.size.s};
  line-height: 2.2rem;
  letter-spacing: -0.4px;
`;

export const TextSmall = styled(TextMain)`
  font-size: ${theme.font.size.xs};
`;

export const DetailsViewText = styled.p`
  margin: 0px 0px 0px 10px;
  color: ${theme.color.lightBlack};
`;
