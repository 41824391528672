import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserDetailsDto, UserDto, UserFilterDto } from '../../../services/User/userService.dto';
import SearchInput from '../../inputs/SearchInput';
import { userService } from '../../../services/User/userService';
import UsersList from './UsersList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  font-weight: normal;
`;

interface Props {
  onUserSelected: (user: UserDto) => void;
}

const UserSelector = ({ onUserSelected }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState<UserDetailsDto[]>([]);

  useEffect(() => {
    if (!searchValue) {
      setUsers([]);
    } else {
      const filter: UserFilterDto = {
        page: 0,
        size: 10,
        sort: 'created,desc',
        searchedWords: searchValue.replaceAll(' ', ','),
      };
      userService
        .fetchUsers(filter, false)
        .then(response => setUsers(response.data.content?.filter(user => user.userStatus !== 'DELETED') || []));
    }
  }, [searchValue]);

  return (
    <Container>
      <SearchInput onChange={setSearchValue} value={searchValue} />
      <UsersList users={users} onUserSelected={onUserSelected} />
    </Container>
  );
};

export default UserSelector;
