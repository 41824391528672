import axios, { AxiosPromise, ResponseType } from 'axios';

axios.interceptors.request.use(
  async config => {
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }

    if (config.url && config.url.startsWith(apiUrl)) {
      const token = localStorage.getItem('authToken');
      if (token) {
        config.headers['X-Auth-Token'] = token;
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

class ApiService {
  get(url: string, params: any = {}, headers: any = {}, responseType?: ResponseType) {
    return axios({
      method: 'get',
      responseType,
      url,
      headers,
      params,
    });
  }

  post(url: string, params: any = {}, headers: any = {}, data: any = {}, responseType?: ResponseType): AxiosPromise {
    return axios({
      method: 'post',
      responseType,
      url,
      headers,
      params,
      data,
    });
  }

  put(url: string, params: any = {}, headers: any = {}, data: any = {}): AxiosPromise {
    return axios({
      method: 'put',
      url,
      headers,
      params,
      data,
    });
  }

  delete(url: string, params: any = {}, headers: any = {}, data: any = {}): AxiosPromise {
    return axios({
      method: 'delete',
      url,
      headers,
      params,
      data,
    });
  }

  patch(url: string, params: any = {}, headers: any = {}, data: any = {}): AxiosPromise {
    return axios({
      method: 'patch',
      url,
      headers,
      params,
      data,
    });
  }
}

const apiService = new ApiService();

const apiUrl: string = process.env.REACT_APP_API_URL || '';

export { apiService, ApiService, apiUrl };
