import React from 'react';
import DownloadIcon from '@mui/icons-material/GetApp';
import { Link } from 'react-router-dom';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  display: flex;
  width: 100%;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${theme.color.black};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

interface Props {
  period: string;
  onReportClick: (period: string) => void;
}

const ShipmentsCostsActions = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <StyledLink title={t('shipment-costs.show-tooltip')} to={'/shipment/costs/' + props.period}>
        <SearchSharpIcon onClick={() => null} style={{ fontSize: 20 }} />
      </StyledLink>
      <div title={t('shipment-costs.download-tooltip')}>
        <DownloadIcon
          onClick={() => props.onReportClick(props.period)}
          style={{ fontSize: 20, cursor: 'pointer', marginLeft: 10 }}
        />
      </div>
    </Container>
  );
};

export default ShipmentsCostsActions;
