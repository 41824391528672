import { ShipmentAddress, ShipmentCostReportDto } from '../../../services/Shipment/shipmentService.dto';
import { dateUtils } from '../../../utils/dateUtils';

export const toFlatShipmentCostReport = (report: ShipmentCostReportDto[]) => {
  return report.map(rep => ({
    id: rep.id,
    carrier: rep.carrier,
    customerPrice: rep.customerShipmentPrice,
    charged: rep.totalCharge,
    currency: rep.customerShipmentPriceCurrency,
    trackingNumber: rep.trackingNumber,
    deliveryDate: dateUtils.formatDate(new Date(rep.deliveryStartDate)),
    from: shipmentAddressToString(rep.senderAddress),
    to: shipmentAddressToString(rep.receiverAddress),
  }));
};

const shipmentAddressToString = (customerData: ShipmentAddress) => {
  const address = customerData.addressLine2
    ? `${customerData.addressLine1} ${customerData.addressLine2}`
    : customerData.addressLine1;
  return `${customerData.firstName} ${customerData.lastName}, ${customerData.city} ${address}, ${customerData.postalCode} ${customerData.state}, ${customerData.country}`;
};
