import React from 'react';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import closeIcon from '../../assets/svg/close.svg';
import { ConfirmButton, CloseButton } from '../buttons/popoverButtons';
import { FormHeader } from '../typography/PopoverTypography';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 320px;
`;

interface Props {
  anchor: HTMLDivElement | null;
  orderId: number;
  onClosePressed?: () => void;
}

const CancelOrderPopover = ({ anchor, orderId, onClosePressed }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleConfirmPress = () => {
    navigate(`/orders/manage/${orderId}`);
  };

  return (
    <Popover
      anchorEl={anchor}
      open={anchor !== null}
      onClose={onClosePressed}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
      <FormContainer>
        <FormHeader>{t('auctions.new-winner.cancel-order.menu-title')}</FormHeader>
        <FormHeader>{t('auctions.new-winner.cancel-order.menu-subtitle')}</FormHeader>
        <CloseButton src={closeIcon} alt={'close'} onClick={onClosePressed} />
        <ConfirmButton label={t('auctions.new-winner.cancel-order.button-text')} onClick={handleConfirmPress} />
      </FormContainer>
    </Popover>
  );
};

export default CancelOrderPopover;
