import React from 'react';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';
import { ReportDto } from '../../services/Report/reportService.dto';

const ObjectContainer = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  justify-content: space-between;
  display: flex;
  width: 100%;
`;

interface Props {
  reportDto: ReportDto;
  onClick: () => void;
}

const ObjectIdCell = (props: Props) => {
  const { t } = useTranslation();
  return (
    <ObjectContainer>
      {props.reportDto.objectId}
      <div title={t('report.show-tooltip')}>
        <SearchSharpIcon onClick={props.onClick} style={{ fontSize: 20, cursor: 'pointer' }} />
      </div>
    </ObjectContainer>
  );
};

export default ObjectIdCell;
