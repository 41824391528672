import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { ObjectType } from '../../pages/report/utils/dictionary';

const Container = styled.div`
  flex-direction: row;
  display: flex;
`;

const PointerContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: 5px;
`;

const PopoverContainer = styled.div`
  margin: 20px;
  max-width: 400px;
`;

interface Props {
  reporters: number[];
  onReporterClick: (type: string, userId: number) => void;
  modalToggle: () => void;
}

const Reporters = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const onReportsClick = (event: HTMLDivElement) => {
    setAnchorEl(event);
  };

  let reportersShort;
  if (props.reporters.length > 3) reportersShort = props.reporters.slice(0, 3).join(' ') + ' ...';

  const renderReporters = () => {
    return (
      <Container>
        {props.reporters.map(reporter => (
          <PointerContainer
            key={reporter}
            onClick={() => {
              props.onReporterClick(ObjectType.USER, reporter);
              props.modalToggle();
            }}>
            {reporter}
          </PointerContainer>
        ))}
      </Container>
    );
  };
  return (
    <Container>
      {reportersShort ? (
        <PointerContainer onClick={e => onReportsClick(e.currentTarget)}>{reportersShort}</PointerContainer>
      ) : (
        renderReporters()
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <PopoverContainer>{renderReporters()}</PopoverContainer>
      </Popover>
    </Container>
  );
};

export default Reporters;
