import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import RadioButtonWithLabel from '../inputs/RadioButtonWithLabel';
import { SearchType } from './searchType';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

interface Props {
  value: SearchType;
  onChange: (searchType: SearchType) => void;
}

const SearchTypePicker = (props: Props) => {
  const { value, onChange } = props;
  const { t } = useTranslation();

  return (
    <Container>
      <RadioButtonWithLabel
        label={t('messages.search-content')}
        onChange={() => onChange('Content')}
        checked={value === 'Content'}
      />
      <RadioButtonWithLabel
        label={t('messages.search-users')}
        onChange={() => onChange('Users')}
        checked={value === 'Users'}
      />
    </Container>
  );
};

export default SearchTypePicker;
