import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { theme } from '../../assets/styles/theme';
import RectangularButton from './RectangularButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  min-width: 240px;
`;

const FileInput = styled.input`
  display: none;
`;

const UploadButton = styled(RectangularButton)`
  height: 40px;
`;

const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FileName = styled.span`
  font-size: ${theme.font.size.xs};
`;

interface Props {
  onFileSelect: (file: File | undefined) => void;
  label: string;
}

const FileUploadButton = (props: Props) => {
  const [selectedFileName, setSelectedFileName] = useState('');

  const fileInput = useRef<HTMLInputElement | null>(null);
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFileName(file.name);
      props.onFileSelect(file);
      event.target.value = '';
    }
  };

  const handleButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleClearClick = () => {
    setSelectedFileName('');
    props.onFileSelect(undefined);
  };

  return (
    <Container>
      <FileInput accept='text/plain' id='file-upload' type='file' ref={fileInput} onChange={handleFileSelect} />
      <UploadButton label={props.label} onClick={handleButtonClick} />
      {selectedFileName && (
        <FileNameContainer>
          <FileName>{selectedFileName.length > 30 ? selectedFileName.slice(0, 30) + '...' : selectedFileName}</FileName>
          <IconButton onClick={handleClearClick} size='small'>
            <ClearIcon />
          </IconButton>
        </FileNameContainer>
      )}
    </Container>
  );
};

export default FileUploadButton;
