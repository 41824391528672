import React from 'react';
import styled from 'styled-components';
import { MessageDto, MessageType } from '../../../services/Message/messageService.dto';
import { theme } from '../../../assets/styles/theme';
import { TextMain, TextSmall } from '../../typography/Texts';
import { dateUtils } from '../../../utils/dateUtils';
import indicatorGetMsg from '../../../assets/svg/indicator-get-msg.svg';
import indicatorReadMsg from '../../../assets/svg/indicator-read-msg.svg';
import MessageImage from './MessageImage';

const Container = styled.div<{ isMessageOwner: boolean }>`
  margin-bottom: 15px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isMessageOwner ? 'flex-end' : 'flex-start')};
`;

const MsgWrapper = styled.div`
  flex-direction: column;
  display: flex;
  max-width: 80%;
  align-items: inherit;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;

  ${theme.mq.desktop} {
    max-width: 60%;
  }
`;

const TextWrapper = styled.div<{ isMessageOwner: boolean }>`
  padding: 10px;
  max-width: 100%;
  border-radius: 12px;
  border: ${theme.color.lightGray} ${props => (props.isMessageOwner ? '1px' : '0')} solid;
  word-wrap: break-word;
  background-color: ${props => (props.isMessageOwner ? theme.color.white : theme.color.lighterGray)};
  white-space: pre-wrap;
`;

const AuditWrapper = styled.div`
  color: ${theme.color.red};
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-style: italic;
`;

const TimeTicksWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TextDate = styled(TextMain)`
  font-size: 1.1rem;
  margin: 0 10px;
  text-transform: uppercase;
  color: ${theme.color.darkerGray};
`;

const MsgDateWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
`;

interface Props {
  message: MessageDto;
  isMessageOwner: boolean;
  isFirstMsgInDay: boolean;
}

const MessageBubble = (props: Props) => {
  const text = props.message.content;
  const mediaUrl = props.message.mediaUrl;
  const createdAt = props.message.createdAt ? new Date(props.message.createdAt) : new Date();

  const renderTick = () => {
    const sent: boolean = !props.message.internalStatus;
    const received: boolean =
      (props.message.deliveredToInterlocutors && props.message.deliveredToInterlocutors.length > 0) ||
      props.message.confirmationReceived ||
      false;
    if (props.isMessageOwner) {
      if (received) return <img src={indicatorReadMsg} alt={'received'} />;
      else if (sent) return <img src={indicatorGetMsg} alt={'sent'} />;
    }
  };

  const renderTimeAndTick = () => {
    return (
      <TimeTicksWrapper>
        {renderTick()}
        <TextDate>{dateUtils.formatTime(createdAt)}</TextDate>
      </TimeTicksWrapper>
    );
  };

  return (
    <Container id={props.message.id.toString()} isMessageOwner={props.isMessageOwner}>
      {props.isFirstMsgInDay && (
        <MsgDateWrapper>
          <TextDate>{dateUtils.formatDate(createdAt)}</TextDate>
        </MsgDateWrapper>
      )}
      {props.message.type === MessageType.AUDIT && (
        <AuditWrapper>
          <TextSmall>{props.message.content}</TextSmall>
        </AuditWrapper>
      )}
      <MsgWrapper>
        {props.message.type === MessageType.TEXT && text && (
          <>
            <TextWrapper isMessageOwner={props.isMessageOwner}>
              <TextMain>{text}</TextMain>
            </TextWrapper>
            {renderTimeAndTick()}
          </>
        )}
        {props.message.type === MessageType.PHOTO && mediaUrl && (
          <>
            <MessageImage message={props.message} />
            {renderTimeAndTick()}
          </>
        )}
      </MsgWrapper>
    </Container>
  );
};

export default MessageBubble;
