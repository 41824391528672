import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/common/PageContainer';
import { Column, TableFilterStateDto } from '../../components/common/table/DataTable';
import { theme } from '../../assets/styles/theme';
import { messageService } from '../../services/Message/messageService';
import { MESSAGES_TAB_ONGOING_PATH } from '../../providers/messages/messagesPaths';
import { FundraiserDetailsDto, FundraiserFilterDto } from '../../services/User/userService.dto';
import { userService } from '../../services/User/userService';
import { TableFilter } from '../../components/common/table/FiltersPanel';
import DataTableWithFilters from '../../components/common/table/DataTableWithFilters';
import { DataTableRef } from '../../components/common/table/dataTableRef';
import { dateUtils } from '../../utils/dateUtils';
import FundraiserActions from '../../components/users/FundraiserActions';
import { phoneUtils } from '../../utils/phoneUtils';
import FundraiserVerificationPopover from '../../components/users/FundraiserVerificationPopover';
import { useGlobalError } from '../../providers/GlobalErrorProvider';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

type UrlParams = {
  userId: string;
};

const Fundraisers = () => {
  const { t } = useTranslation();
  const [selectedFundraiser, setSelectedFundraiser] = useState<FundraiserDetailsDto>();
  const [verificationPopoverAnchor, setVerificationPopoverAnchor] = useState<HTMLDivElement | null>(null);
  const dataTableRef = useRef<DataTableRef>(null);
  const navigate = useNavigate();
  const { handleError } = useGlobalError();

  const { userId } = useParams<UrlParams>();

  const columns: Column<FundraiserDetailsDto>[] = [
    {
      id: 'id',
      title: t('users.fundraisers-columns.id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.id,
    },
    {
      id: 'email',
      title: t('users.fundraisers-columns.email'),
      isSortable: true,
      minWidth: 100,
      maxWidth: 200,
      renderCell: data => data.email,
    },
    {
      id: 'firstName',
      title: t('users.fundraisers-columns.first-name'),
      minWidth: 100,
      maxWidth: 200,
      isSortable: true,
      renderCell: data => data.firstName,
    },
    {
      id: 'lastName',
      title: t('users.fundraisers-columns.last-name'),
      minWidth: 100,
      maxWidth: 200,
      isSortable: true,
      renderCell: data => data.lastName,
    },
    {
      id: 'fundraiserVerifiedByAdmin',
      title: t('users.fundraisers-columns.verified-by-admin'),
      renderCell: data => (data.fundraiserVerificationCompleted ? t('common.yes') : t('common.no')),
    },
    {
      id: 'phoneNumber',
      title: t('users.fundraisers-columns.phone-number'),
      renderCell: data => phoneUtils.convertToPhoneDisplayNumber(data.phone),
    },
    {
      id: 'fundraiserType',
      title: t('users.fundraisers-columns.fundraiser-type'),
      renderCell: data => data.fundraiserType,
    },
    {
      id: 'organizationName',
      title: t('users.fundraisers-columns.organization-name'),
      isSortable: true,
      renderCell: data => data.organizationName,
    },
    {
      id: 'ein',
      title: t('users.fundraisers-columns.ein'),
      isSortable: true,
      renderCell: data => data.ein,
    },
    {
      id: 'address',
      title: t('users.fundraisers-columns.address'),
      renderCell: data =>
        `${data.addressLine1}${data.addressLine2 ? ` ${data.addressLine2}` : ''}, ${data.city}, ${data.state}, ${
          data.country
        }`,
    },
    {
      id: 'bankAccountNumber',
      title: t('users.fundraisers-columns.bank-account-number'),
      renderCell: data => data.bankAccountNumber,
    },
    {
      id: 'inAppVerificationDate',
      title: t('users.fundraisers-columns.in-app-verification-date'),
      isSortable: true,
      renderCell: data => dateUtils.toLocalDateTimeString(data.inAppVerificationDate),
    },
    {
      id: 'adminVerificationDate',
      title: t('users.fundraisers-columns.admin-verification-date'),
      isSortable: true,
      renderCell: data =>
        data.adminVerificationDate ? dateUtils.toLocalDateTimeString(data.adminVerificationDate) : undefined,
    },
    {
      id: 'verifyingAdminId',
      title: t('users.fundraisers-columns.verifying-admin-id'),
      renderCell: data => data.verifyingAdminId,
    },
    {
      id: 'actions',
      title: t('users.fundraisers-columns.actions'),
      align: 'center',
      minWidth: 60,
      renderCell: data => {
        return (
          <FundraiserActions
            fundraiser={data}
            onContactUser={() => openConversation(data.id)}
            onVerify={(e, fundraiser) => displayVerificationPopover(e, fundraiser)}
          />
        );
      },
    },
  ];

  const filters: TableFilter[] = [
    { type: 'numeric', name: 'id', label: t('users.fundraisers-filters.id') },
    { type: 'text', name: 'email', label: t('users.fundraisers-filters.email') },
    { type: 'text', name: 'firstName', label: t('users.fundraisers-filters.first-name') },
    { type: 'text', name: 'lastName', label: t('users.fundraisers-filters.last-name') },
    {
      type: 'dropdown',
      name: 'fundraiserVerificationCompleted',
      label: t('users.fundraisers-filters.fundraiser-verified-by-admin'),
      options: [t('common.true'), t('common.false')],
    },
  ];

  const convertFiltersToFundraiserFilterDto = (filter: TableFilterStateDto<Map<string, any>>): FundraiserFilterDto => {
    return {
      page: filter.page,
      size: filter.pageSize,
      sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : undefined,
      id: filter.filtersMap?.get('id'),
      firstName: filter.filtersMap?.get('firstName'),
      lastName: filter.filtersMap?.get('lastName'),
      email: filter.filtersMap?.get('email'),
      fundraiserVerificationCompleted: filter.filtersMap?.get('fundraiserVerificationCompleted'),
    };
  };

  const handleOnTableStateChanged = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const fundraiserFilter = convertFiltersToFundraiserFilterDto(tableState);
    return userService.fetchFundraisers(fundraiserFilter).then(response => response.data);
  }, []);

  const openConversation = (endUserId: number) =>
    messageService
      .createConversation(endUserId)
      .then(({ data }) => navigate(`${MESSAGES_TAB_ONGOING_PATH}/${data.id}`));

  const completeFundraiserVerification = (userId: number) => {
    userService
      .verifyFundraiser(userId)
      .then(() => {
        closeVerificationPopover();
        dataTableRef.current?.refresh();
      })
      .catch(error => handleError(error));
  };

  const displayVerificationPopover = (element: HTMLDivElement, content: FundraiserDetailsDto) => {
    setSelectedFundraiser(content);
    setVerificationPopoverAnchor(element);
  };

  const closeVerificationPopover = () => {
    setVerificationPopoverAnchor(null);
    setSelectedFundraiser(undefined);
  };

  const filterInitialState = useMemo(() => {
    const userIdFromPath = userId ? parseInt(userId) : undefined;
    if (userIdFromPath && Number.isInteger(userIdFromPath)) {
      return new Map([['id', userIdFromPath]]);
    } else {
      return new Map();
    }
  }, [userId]);

  return (
    <PageContainer>
      <Container>
        <DataTableWithFilters
          ref={dataTableRef}
          columns={columns}
          filters={filters}
          onTableStateChanged={handleOnTableStateChanged}
          filterInitState={filterInitialState}
        />
      </Container>
      {verificationPopoverAnchor && selectedFundraiser && (
        <FundraiserVerificationPopover
          fundraiser={selectedFundraiser}
          anchor={verificationPopoverAnchor}
          onConfirmPressed={completeFundraiserVerification}
          onClosePressed={closeVerificationPopover}
        />
      )}
    </PageContainer>
  );
};

export default Fundraisers;
