import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ConversationWithDataDto } from '../../services/Message/messageService.dto';
import LoadingIndicator from '../common/LoadingIndicator';
import { SectionHeader } from '../typography/Headers';
import { useMessages } from '../../providers/messages/MessagesProvider';
import ConversationListItem from './ConversationListItem';
import ConversationEmptyList from './ConversationEmptyList';

const Container = styled.div`
  flex: 1;
  margin: 10px 0;
  overflow-y: auto;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

interface Props {
  title: string;
  path: string;
  selectedConversationId?: number;
  conversations: ConversationWithDataDto[];
  fetchConversations: () => void;
  isLoading: boolean;
}

const SCROLL_THRESHOLD = 5;

const ConversationList = (props: Props) => {
  const { selectConversation } = useMessages();

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const diffToBottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight;
    if (diffToBottom < SCROLL_THRESHOLD) props.fetchConversations();
  };

  const renderConversations = () => {
    if (props.conversations.length === 0 && !props.isLoading) {
      return <ConversationEmptyList />;
    } else {
      return props.conversations.map(item => {
        const isSelected = item.id === props.selectedConversationId;
        return (
          <StyledLink key={item.id} to={`${props.path}/${item.id}`} onClick={() => selectConversation(item)}>
            <ConversationListItem key={item.id} isSelected={isSelected} conversation={item} />
          </StyledLink>
        );
      });
    }
  };

  return (
    <>
      <SectionHeader>{props.title}</SectionHeader>
      <Container onScroll={handleScroll}>
        {renderConversations()}
        {props.isLoading && <LoadingIndicator />}
      </Container>
    </>
  );
};

export default ConversationList;
