import React from 'react';
import styled from 'styled-components';
import { createTheme, IconButton, TextField, ThemeProvider } from '@mui/material';
import { CalendarPickerView, DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { theme } from '../../assets/styles/theme';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const customTheme = createTheme({
  typography: {
    fontSize: 18,
  },
  palette: {
    primary: {
      main: theme.color.darkerGray,
      dark: theme.color.red,
      contrastText: theme.color.white,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.8rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: theme.color.lighterGray,
          minHeight: '40px',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          backgroundColor: theme.color.red,
          color: theme.color.white,
          maxHeight: 50,
          minHeight: 50,
          marginTop: 0,
          fontSize: '1.4rem',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: theme.color.white,
          '&.Mui-selected': {
            backgroundColor: theme.color.red,
            '&.Mui-focusVisible': {
              background: theme.color.darkGray,
            },
          },
          fontSize: '1.4rem',
        },
        today: {
          color: theme.color.red,
        },
      },
    },
  },
});

const Container = styled.div`
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 190px;
`;

export enum DateTimePickerFormat {
  DATE,
  DATE_TIME,
  MONTH_YEAR,
}

interface Props {
  label: string;
  onChange: (value: Date | null) => void;
  onError?: (error: boolean) => void;
  value?: Date | null;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  pickerFormat?: DateTimePickerFormat;
}

const DATE_PICKER_FORMAT = 'MMM d, yyyy';
const DATE_PICKER_FORMAT_EXCLUDED_DAYS = 'MM/yyyy';

const isValidDate = (date: Date) => !isNaN(date.valueOf()) && date.getFullYear() >= 1000;

const DateInputWithLabel = (props: Props) => {
  const { onChange, onError } = props;

  const pickerFormat = props.pickerFormat ?? DateTimePickerFormat.DATE;
  const PickerComponent = pickerFormat === DateTimePickerFormat.DATE_TIME ? DateTimePicker : DatePicker;

  const resolveFormat = (): { views?: CalendarPickerView[]; inputFormat?: string } => {
    if (pickerFormat === DateTimePickerFormat.DATE) {
      return { views: ['day'], inputFormat: DATE_PICKER_FORMAT };
    } else if (pickerFormat === DateTimePickerFormat.MONTH_YEAR) {
      return { views: ['month', 'year'], inputFormat: DATE_PICKER_FORMAT_EXCLUDED_DAYS };
    } else {
      return {};
    }
  };

  const handleChange = (value: Date | null) => {
    if (pickerFormat === DateTimePickerFormat.DATE) {
      if (!value || isValidDate(value)) {
        onChange(value);
      }
    } else {
      onChange(value);
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Container>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <PickerComponent
            {...resolveFormat()}
            disabled={props.disabled}
            minDate={props.minDate}
            maxDate={props.maxDate}
            value={props.value || null}
            label={props.label}
            onChange={handleChange}
            onError={error => onError?.(!!error)}
            InputProps={{
              endAdornment: props.value && !props.disabled && (
                <IconButton onClick={() => handleChange(null)}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
            InputAdornmentProps={{
              position: 'start',
            }}
            renderInput={params => <TextField size='small' {...params} />}
            disableHighlightToday={true}
          />
        </LocalizationProvider>
      </Container>
    </ThemeProvider>
  );
};

export default DateInputWithLabel;
