import React from 'react';
import styled from 'styled-components';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ReportIcon from '@mui/icons-material/Report';
import { useTranslation } from 'react-i18next';
import { ActionType, ObjectType } from '../../pages/report/utils/dictionary';

const Container = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const iconStyle = {
  fontSize: 20,
  cursor: 'pointer',
};

interface Props {
  isHidden: boolean;
  isDeleted: boolean;
  objectType: string;
  id: number;
  onPress: (element: SVGSVGElement, action: ActionType) => void;
}

const ReportActions = (props: Props) => {
  const { t } = useTranslation();
  if (props.isHidden || props.isDeleted) return <></>;
  const notUserActions = () => {
    return props.objectType === ObjectType.COMMENT ? (
      <div title={t('report.remove-tooltip')}>
        <ReportIcon style={iconStyle} onClick={e => props.onPress(e.currentTarget, ActionType.DELETE)} />
      </div>
    ) : (
      <div title={t('report.hide-tooltip')}>
        <VisibilityOffIcon style={iconStyle} onClick={e => props.onPress(e.currentTarget, ActionType.HIDE)} />
      </div>
    );
  };
  return (
    <Container>
      <div title={t('report.ignore-tooltip')}>
        <ThumbDownOutlinedIcon style={iconStyle} onClick={e => props.onPress(e.currentTarget, ActionType.IGNORE)} />
      </div>
      {props.objectType === ObjectType.USER ? (
        <div title={t('report.remove-tooltip')}>
          <ReportIcon style={iconStyle} onClick={e => props.onPress(e.currentTarget, ActionType.REMOVE)} />
        </div>
      ) : (
        notUserActions()
      )}
    </Container>
  );
};

export default ReportActions;
