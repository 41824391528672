import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import {
  ConversationDto,
  ConversationStatus,
  ConversationWithDataDto,
} from '../../services/Message/messageService.dto';
import { searchService } from '../../services/Search/searchService';
import { SearchMessageDto } from '../../services/Search/searchService.dto';
import { UserPublicDto } from '../../services/User/userService.dto';
import { userService } from '../../services/User/userService';
import { useGlobalData } from '../GlobalDataProvider';
import { SearchType } from '../../components/messages/searchType';
import { messageService } from '../../services/Message/messageService';

const SEARCH_PAGE_SIZE = 1000;

export const useSearchMessages = (status: ConversationStatus, supporterId?: number) => {
  const [searchResult, setSearchResult] = useState<ConversationWithDataDto[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { supporters } = useGlobalData();

  const fetchMessagesBySearch = useCallback(
    debounce(async (searchValue: string, searchType: SearchType) => {
      if (!searchValue) return;
      setIsLoading(true);
      const conversations = await fetchMessagesBySearchType(searchValue, searchType);
      const users = await getConversationUsers(conversations);
      const conversationsWithUsers = assignUsersToConversations(conversations, users, supporters);
      setSearchResult(conversationsWithUsers);
      setIsLoading(false);
    }, 200),
    []
  );

  const fetchMessagesBySearchType = async (value: string, type: SearchType) => {
    if (type === 'Content') {
      const response = await searchService.fetchMessages(value, status, SEARCH_PAGE_SIZE, supporterId);
      return groupMessagesByConversations(response.data.content);
    } else {
      const response = await messageService.searchConversationsByUsers(value, status, SEARCH_PAGE_SIZE, supporterId);
      return convertToConversationsWithData(response.data.content);
    }
  };

  return { searchResult, isLoading, fetchMessagesBySearch };
};

const convertToConversationsWithData = (conversations?: ConversationDto[]): ConversationWithDataDto[] => {
  return conversations?.map(conversation => ({ ...conversation, searchMessages: [] })) || [];
};

const groupMessagesByConversations = (messages?: SearchMessageDto[]): ConversationWithDataDto[] => {
  if (!messages) return [];
  const conversationsMap: Map<number, ConversationWithDataDto> = new Map<number, ConversationWithDataDto>();
  messages.forEach(message => {
    const convId = message.conversationId;
    let conversation = conversationsMap.get(convId);
    if (conversation) conversation.searchMessages?.push(message);
    else {
      conversation = {
        id: message.supportConversationId,
        conversationId: message.conversationId,
        endUserId: message.endUserId,
        supportEmployeeId: message.supporterId,
        lastMessageCreated: message.created,
        lastMessageContent: message.content,
        status: message.status,
        searchMessages: [message],
        resolved: false, // TODO get value from response while it's ready on backend
      };
    }
    conversationsMap.set(convId, conversation);
  });
  return Array.from(conversationsMap.values());
};

const getConversationUsers = async (conversations: ConversationWithDataDto[]): Promise<UserPublicDto[]> => {
  const userIds = conversations.map(conversation => conversation.endUserId);
  const usersResponse = await userService.fetchPublicUsers(userIds);
  return usersResponse.data;
};

const assignUsersToConversations = (
  conversations: ConversationWithDataDto[],
  users: UserPublicDto[],
  supporters: UserPublicDto[]
): ConversationWithDataDto[] => {
  return conversations.map(conversation => {
    return {
      ...conversation,
      endUser: users.filter(user => conversation.endUserId === user.id)[0],
      supporter: conversation.supportEmployeeId
        ? supporters.filter(user => conversation.supportEmployeeId === user.id)[0]
        : undefined,
    };
  });
};
