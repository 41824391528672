import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, TableFilterStateDto } from '../common/table/DataTable';
import { DataTableRef } from '../common/table/dataTableRef';
import { TableFilter } from '../common/table/FiltersPanel';
import DataTableWithFilters from '../common/table/DataTableWithFilters';
import { paymentService } from '../../services/Payment/paymentService';
import { TransactionInfoDto, TransactionsFilterDto } from '../../services/Payment/paymentService.dto';
import { dateUtils } from '../../utils/dateUtils';
import CellWithNavigate from '../common/table/CellWithNavigate';
import CellWithPopover from '../common/table/CellWithPopover';
import UserDetailsPopover from '../users/UserDetailsPopover';
import { parseMultiChoice } from '../common/table/filterConverterUtils';
import { DEFAULT_CURRENCY, TRANSACTION_FILTER_MAX_AMOUNT, TRANSACTION_STATUS_OPTIONS } from './payment.types';
import TransactionErrorsPopover from './TransactionErrorsPopover';

const convertFiltersToTransactionsFilterDto = (
  filter: TableFilterStateDto<Map<string, any>>
): TransactionsFilterDto => {
  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'transactionDate,desc',
    orderId: filter.filtersMap?.get('orderId'),
    status: parseMultiChoice(filter, 'status'),
    currency: parseMultiChoice(filter, 'currency'),
    payerId: filter.filtersMap?.get('payerId'),
    totalAmountFrom: !!filter.filtersMap?.get('amount') ? filter.filtersMap?.get('amount')[0] : undefined,
    totalAmountTo: !!filter.filtersMap?.get('amount') ? filter.filtersMap?.get('amount')[1] : undefined,
    creationDateDay: !!filter.filtersMap?.get('date')
      ? dateUtils.formatYearMonthDay(filter.filtersMap?.get('date'))
      : undefined,
    errors: !!filter.filtersMap?.get('errors') ? true : undefined,
  };
};

const TransactionsTable = () => {
  const { t } = useTranslation();
  const dataTableRef = useRef<DataTableRef>(null);

  const columns: Column<TransactionInfoDto>[] = [
    {
      id: 'transactionId',
      title: t('transactions.columns.transaction-id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.transactionId,
    },
    {
      id: 'transactionDate',
      title: t('transactions.columns.transaction-date'),
      isSortable: true,
      align: 'center',
      renderCell: data => dateUtils.formatDateDate(new Date(data.transactionDate)),
    },
    {
      id: 'status',
      title: t('transactions.columns.status'),
      align: 'center',
      renderCell: data => data.transactionStatus,
    },
    {
      id: 'amount',
      title: t('transactions.columns.total-amount'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.amount,
    },
    {
      id: 'currency',
      title: t('transactions.columns.currency'),
      align: 'center',
      renderCell: data => data.currency,
    },
    {
      id: 'errors',
      title: t('transactions.columns.errors'),
      align: 'center',
      renderCell: data =>
        data.errors ? (
          <CellWithPopover popover={<TransactionErrorsPopover transactionId={data.transactionId} />}>
            {t('common.yes')}
          </CellWithPopover>
        ) : (
          t('common.no')
        ),
    },
    {
      id: 'method',
      title: t('transactions.columns.method-type'),
      align: 'center',
      renderCell: data => data.methodType,
    },
    {
      id: 'provider',
      title: t('transactions.columns.provider'),
      align: 'center',
      renderCell: data => data.provider,
    },
    {
      id: 'transactionType',
      title: t('transactions.columns.transaction-type'),
      align: 'center',
      renderCell: data => data.transactionType,
    },
    {
      id: 'payerId',
      title: t('transactions.columns.payer-id'),
      align: 'center',
      renderCell: data =>
        data.payerId && (
          <CellWithPopover popover={<UserDetailsPopover userId={data.payerId} />}>{data.payerId}</CellWithPopover>
        ),
    },
    {
      id: 'orderId',
      title: t('transactions.columns.order-id'),
      align: 'center',
      renderCell: data => (
        <CellWithNavigate
          url={`/orders/manage/${data.orderId}`}
          title={t('transactions.go-to-orders-tooltip', { id: data.orderId })}>
          {data.orderId}
        </CellWithNavigate>
      ),
    },
  ];

  const filters: TableFilter[] = [
    {
      type: 'numeric',
      name: 'orderId',
      label: t('transactions.filters.order-id'),
    },
    {
      type: 'multi-choice',
      name: 'status',
      options: TRANSACTION_STATUS_OPTIONS,
      label: t('transactions.filters.status'),
    },
    {
      type: 'multi-choice',
      name: 'currency',
      options: [DEFAULT_CURRENCY],
      label: t('transactions.filters.currency'),
    },
    {
      type: 'numeric',
      name: 'payerId',
      label: t('transactions.filters.payer-id'),
    },
    {
      type: 'date',
      name: 'date',
      label: t('transactions.filters.date'),
    },
    {
      type: 'checkbox',
      name: 'errors',
      label: t('transactions.filters.errors'),
      checked: false,
    },
    {
      type: 'range',
      name: 'amount',
      label: t('transactions.filters.amount'),
      max: TRANSACTION_FILTER_MAX_AMOUNT,
    },
  ];

  const onTableStateChange = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const transactionsFilter = convertFiltersToTransactionsFilterDto(tableState);
    return paymentService.fetchTransactions(transactionsFilter).then(response => response.data);
  }, []);

  return (
    <>
      <DataTableWithFilters
        ref={dataTableRef}
        columns={columns}
        filters={filters}
        hideApplyButton={true}
        refreshOnFiltersChange={true}
        onTableStateChanged={onTableStateChange}
      />
    </>
  );
};

export default TransactionsTable;
