import i18n, { LanguageDetectorAsyncModule } from 'i18next';
import { initReactI18next } from 'react-i18next';

const languageDetector: LanguageDetectorAsyncModule = {
  // TODO: handle language detection in next versions of IGA
  type: 'languageDetector',
  async: true,
  detect: callback => callback('en'),
  // tslint:disable-next-line:no-empty
  init: () => {},
  // tslint:disable-next-line:no-empty
  cacheUserLanguage: () => {},
};

const resources = {
  en: {
    translations: require('./en/translations.json'),
  },
};

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    returnNull: false,
    resources,
    debug: false,
    joinArrays: '',
    fallbackLng: 'en',
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
