import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/common/PageContainer';
import { Column, TableFilterStateDto } from '../../components/common/table/DataTable';
import { theme } from '../../assets/styles/theme';
import { messageService } from '../../services/Message/messageService';
import { MESSAGES_TAB_ONGOING_PATH } from '../../providers/messages/messagesPaths';
import { UserDetailsDto } from '../../services/User/userService.dto';
import { authService } from '../../services/Auth/authService';
import { userService } from '../../services/User/userService';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import { TableFilter } from '../../components/common/table/FiltersPanel';
import DataTableWithFilters from '../../components/common/table/DataTableWithFilters';
import UserActions from '../../components/users/UserActions';
import BlockUserPopover from '../../components/users/BlockUserPopover';
import UnblockUserPopover from '../../components/users/UnblockUserPopover';
import { useGlobalData } from '../../providers/GlobalDataProvider';
import VerificationPopover, { VerificationMessages } from '../../components/users/VerificationPopover';
import { DataTableRef } from '../../components/common/table/dataTableRef';
import { dateUtils } from '../../utils/dateUtils';
import { phoneUtils } from '../../utils/phoneUtils';
import { convertFiltersToUserFilterDto } from './utils/filterConverter';
import { useUnblockUser } from './utils/useUnblockUser';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const mapUserStatus = (user: UserDetailsDto) => {
  if (user.userStatus === 'DELETED') {
    return 'Deleted';
  } else if (user.blocked) {
    return 'Blocked';
  } else if (user.userStatus === 'NEW') {
    return 'New';
  } else {
    return 'Active';
  }
};

const FindUser = () => {
  const { t } = useTranslation();
  const { blockadeReasons } = useGlobalData();
  const [selectedUser, setSelectedUser] = useState<UserDetailsDto>();
  const [blockButtonAnchor, setBlockButtonAnchor] = useState<HTMLDivElement | null>(null);
  const [unblockButtonAnchor, setUnblockButtonAnchor] = useState<HTMLDivElement | null>(null);
  const [verificationPopoverAnchor, setVerificationPopoverAnchor] = useState<HTMLDivElement | null>(null);
  const [verificationContent, setVerificationContent] = useState<VerificationMessages | number | undefined>();
  const dataTableRef = useRef<DataTableRef>(null);
  const navigate = useNavigate();
  const { handleError } = useGlobalError();
  const { unblockUser } = useUnblockUser();

  const columns: Column<UserDetailsDto>[] = [
    { id: 'id', title: t('users.columns.id'), isSortable: true, align: 'center', renderCell: data => data.id },
    {
      id: 'email',
      title: t('users.columns.username'),
      isSortable: true,
      minWidth: 100,
      maxWidth: 200,
      renderCell: data => data.email,
    },
    {
      id: 'firstName',
      title: t('users.columns.first-name'),
      minWidth: 100,
      maxWidth: 200,
      isSortable: true,
      renderCell: data => data.firstName,
    },
    {
      id: 'lastName',
      title: t('users.columns.last-name'),
      minWidth: 100,
      maxWidth: 200,
      isSortable: true,
      renderCell: data => data.lastName,
    },
    {
      id: 'phoneNumber',
      title: t('users.columns.phone-number'),
      renderCell: data => phoneUtils.convertToPhoneDisplayNumber(data.phone),
    },
    {
      id: 'fundraiserVerified',
      title: t('users.columns.verified'),
      renderCell: data => (data.fundraiserVerified ? t('common.yes') : t('common.no')),
    },
    {
      id: 'localization',
      title: t('users.columns.localization'),
      renderCell: data => data.city,
    },
    {
      id: 'zipCode',
      title: t('users.columns.zip-code'),
      renderCell: data => data.zip,
    },
    {
      id: 'created',
      title: t('users.columns.creation-date'),
      renderCell: data => dateUtils.toLocalDateTimeString(data.created),
      isSortable: true,
    },
    {
      id: 'userStatus',
      title: t('users.columns.status'),
      renderCell: mapUserStatus,
    },
    {
      id: 'actions',
      title: t('users.columns.actions'),
      align: 'center',
      minWidth: 60,
      renderCell: data => {
        return (
          <UserActions
            user={data}
            onBlockPressed={element => {
              setBlockButtonAnchor(element);
              setSelectedUser(data);
            }}
            onUnblockPressed={element => {
              setUnblockButtonAnchor(element);
              setSelectedUser(data);
            }}
            onContactUser={() => openConversation(data.id)}
            onPinVerification={showPinPopup}
            onConfirmSignup={confirmUserSignup}
          />
        );
      },
    },
  ];

  const filters: TableFilter[] = [
    { type: 'numeric', name: 'id', label: t('users.filters.id') },
    { type: 'text', name: 'email', label: t('users.filters.email') },
    { type: 'text', name: 'phoneNumber', label: t('users.filters.phone-number') },
    { type: 'text', name: 'firstName', label: t('users.filters.first-name') },
    { type: 'text', name: 'lastName', label: t('users.filters.last-name') },
    { type: 'text', name: 'localization', label: t('users.filters.localization') },
    { type: 'text', name: 'zipCode', label: t('users.filters.zip-code') },
  ];

  const handleBlockUser = (userId: number, reason: string, comment?: string) => {
    userService.blockUser(userId, reason, comment).then(() => dataTableRef.current?.refresh());
    handleClosePopup();
  };

  const handleUnblockUser = (userId: number) => {
    unblockUser(userId, dataTableRef.current?.refresh);
    handleClosePopup();
  };

  const handleClosePopup = () => {
    setSelectedUser(undefined);
    setBlockButtonAnchor(null);
    setUnblockButtonAnchor(null);
  };

  const handleOnTableStateChanged = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const userFilter = convertFiltersToUserFilterDto(tableState);
    return userService.fetchUsers(userFilter).then(response => response.data);
  }, []);

  const openConversation = (endUserId: number) =>
    messageService
      .createConversation(endUserId)
      .then(({ data }) => navigate(`${MESSAGES_TAB_ONGOING_PATH}/${data.id}`));

  const displayVerificationPopover = (
    element: HTMLDivElement,
    content: number | VerificationMessages,
    shouldRefresh?: boolean
  ) => {
    setVerificationContent(content);
    setVerificationPopoverAnchor(element);
    if (shouldRefresh) dataTableRef.current?.refresh();
  };

  const showPinPopup = (element: HTMLDivElement, user: UserDetailsDto) => {
    if (user.phoneConfirmed)
      authService
        .fetchAuthenticationPin(user.id)
        .then(response => displayVerificationPopover(element, response.data.pin))
        .catch(error => {
          if (error.response.status === 400) displayVerificationPopover(element, 'USER_NOT_EXISTS');
          else if (error.response.status === 404) displayVerificationPopover(element, 'NO_PIN');
          else handleError(error);
        });
    else
      userService
        .fetchPhoneActivationPin(user.id)
        .then(response => displayVerificationPopover(element, response.data.pin))
        .catch(error => {
          if (error.response.status === 400) displayVerificationPopover(element, 'USER_NOT_EXISTS');
          else if (error.response.status === 404) displayVerificationPopover(element, 'NO_PIN');
          else if (error.response.status === 409) displayVerificationPopover(element, 'PHONE_VERIFIED');
          else handleError(error);
        });
  };

  const confirmUserSignup = (element: HTMLDivElement, user: UserDetailsDto) => {
    userService
      .confirmSignup(user.id)
      .then(() => displayVerificationPopover(element, 'SUCCESSFULLY_CONFIRMED', true))
      .catch(error => {
        if (error.response.status === 404) displayVerificationPopover(element, 'USER_NOT_EXISTS');
        else if (error.response.status === 409) displayVerificationPopover(element, 'USER_ALREADY_CONFIRMED');
        else handleError(error);
      });
  };

  const closeVerificationPopover = () => {
    setVerificationPopoverAnchor(null);
    setVerificationContent(undefined);
  };

  return (
    <PageContainer>
      <Container>
        <DataTableWithFilters
          ref={dataTableRef}
          columns={columns}
          filters={filters}
          onTableStateChanged={handleOnTableStateChanged}
        />
      </Container>
      {selectedUser && blockButtonAnchor && (
        <BlockUserPopover
          anchor={blockButtonAnchor}
          userData={selectedUser}
          blockadeReasons={blockadeReasons}
          onBlockPressed={(reason, comment) => handleBlockUser(selectedUser.id, reason, comment)}
          onClosePressed={handleClosePopup}
        />
      )}
      {selectedUser && unblockButtonAnchor && (
        <UnblockUserPopover
          anchor={unblockButtonAnchor}
          userData={selectedUser}
          onUnblockPressed={() => handleUnblockUser(selectedUser.id)}
          onClosePressed={handleClosePopup}
        />
      )}
      {verificationPopoverAnchor && (
        <VerificationPopover
          anchor={verificationPopoverAnchor}
          content={verificationContent}
          onClosePressed={closeVerificationPopover}
        />
      )}
    </PageContainer>
  );
};

export default FindUser;
