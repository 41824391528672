import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';
import PageContainer from '../../components/common/PageContainer';
import DateInputWithLabel, { DateTimePickerFormat } from '../../components/inputs/DateInputWithLabel';
import RectangularButton from '../../components/buttons/RectangularButton';
import InputWithLabel from '../../components/inputs/InputWithLabel';
import ConfirmPopover from '../../components/common/ConfirmPopover';
import RecoveryPasswordPopover from '../../components/maintenance/RecoveryPasswordPopover';
import { RecoveryBackupDto } from '../../services/Maintenance/maintenanceService.dto';
import { maintenanceService } from '../../services/Maintenance/maintenanceService';
import { useGlobalError } from '../../providers/GlobalErrorProvider';

const Container = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  flex-direction: row;
  align-self: start;
  align-items: center;
  display: flex;
  width: 100%;
`;

const RecoveryButton = styled(RectangularButton)`
  height: 30px;
  margin: 5px;
`;

const Recovery = () => {
  const { t } = useTranslation();
  const [from, setFrom] = useState<Date | null>();
  const [to, setTo] = useState<Date | null>();
  const [rabbitExchange, setExchange] = useState<string | null>();
  const [recoveryButtonAnchor, setRecoveryButtonAnchor] = useState<HTMLButtonElement | null>(null);
  const [confirmButtonAnchor, setConfirmButtonAnchor] = useState<HTMLButtonElement | null>(null);
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  const { handleError } = useGlobalError();

  const today = new Date();
  const errorMap = new Map([
    [400, t('maintenance.recovery.date-error')],
    [403, t('maintenance.recovery.password-error')],
    [404, t('maintenance.recovery.exchange-not-found')],
    [409, t('maintenance.recovery.exchange-locked')],
    [422, t('maintenance.recovery.payload-error')],
  ]);

  const resetForm = () => {
    setFrom(null);
    setTo(null);
    setExchange(null);
  };

  const closeConfirmPopover = () => {
    setRecoveryButtonAnchor(null);
  };

  const closePasswordPopover = () => {
    setConfirmButtonAnchor(null);
  };

  const onContinuePress = () => {
    setConfirmButtonAnchor(recoveryButtonAnchor);
    closeConfirmPopover();
  };

  const recovery = (recoveryPassword: string) => {
    if (from && to && rabbitExchange) {
      const storedFrom = from.toISOString();
      const storedTo = to.toISOString();
      const recoveryDto: RecoveryBackupDto = {
        storedFrom,
        storedTo,
        rabbitExchange,
        recoveryPassword,
      };
      closePasswordPopover();

      maintenanceService
        .backupRecovery(recoveryDto)
        .then(() => resetForm)
        .catch(e => handleError(e, t('maintenance.recovery.default-error'), errorMap));
    }
  };

  return (
    <PageContainer>
      <Container>
        <DateInputWithLabel
          label={t('maintenance.recovery.date-from')}
          onChange={setFrom}
          value={from}
          onError={setInvalidDate}
          maxDate={to!}
          pickerFormat={DateTimePickerFormat.DATE_TIME}
        />
        <DateInputWithLabel
          label={t('maintenance.recovery.date-to')}
          onChange={setTo}
          value={to}
          onError={setInvalidDate}
          minDate={from!}
          maxDate={today}
          pickerFormat={DateTimePickerFormat.DATE_TIME}
        />
        <InputWithLabel
          label={t('maintenance.recovery.exchange-input')}
          onChange={setExchange}
          value={rabbitExchange ? rabbitExchange : ''}
          minWidth={400}
        />
        <RecoveryButton
          label={t('maintenance.recovery.recovery-btn')}
          onClick={setRecoveryButtonAnchor}
          disabled={!from || !to || !rabbitExchange || invalidDate}
        />
      </Container>
      <ConfirmPopover
        anchor={recoveryButtonAnchor}
        onClosePressed={closeConfirmPopover}
        onContinuePressed={onContinuePress}
        confirmMessage={t('maintenance.recovery.recovery-confirm')}
      />
      <RecoveryPasswordPopover
        anchor={confirmButtonAnchor}
        onClosePressed={closePasswordPopover}
        onContinuePressed={recovery}
      />
    </PageContainer>
  );
};

export default Recovery;
