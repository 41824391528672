import React from 'react';
import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';

const Container = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  display: flex;
  background-color: ${theme.color.lighterGray};
  font-family: ${theme.font.family.main};
  font-size: ${theme.font.size.s};
  line-height: 2.2rem;
  letter-spacing: -0.4px;
  align-items: center;
  border: 0;
  outline: 0;
  flex: 1;
  border-radius: 22px;
  padding: 10px;
`;

interface Props {
  onChange: (value: number) => void;
  value: number;
}

const PriceInput = ({ value, onChange }: Props) => {
  const handleChange = (value: string) => {
    const trimmedValue = value.replace('$', '').trim();
    onChange(Number.parseInt(trimmedValue));
  };

  return (
    <Container>
      <StyledInput
        value={!!value ? `$ ${value}` : ''}
        placeholder={'$'}
        inputMode={'numeric'}
        onChange={e => handleChange(e.target.value)}
      />
    </Container>
  );
};

export default PriceInput;
