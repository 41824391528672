import { Reducer, useReducer } from 'react';
import { ConversationWithDataDto, MessageDto } from '../../services/Message/messageService.dto';
import PageableType from '../../services/utils/pageableType';
import { UserPublicDto } from '../../services/User/userService.dto';

export enum ConversationActionTypes {
  CONVERSATIONS_FETCH_REQUEST = 'CONVERSATIONS_FETCH_REQUEST',
  CONVERSATIONS_FETCH_SUCCESS = 'CONVERSATIONS_FETCH_SUCCESS',
  CONVERSATIONS_FETCH_ERROR = 'CONVERSATIONS_FETCH_ERROR',
  CONVERSATION_UPDATE = 'CONVERSATION_UPDATE',
  CONVERSATION_ADD_MESSAGE = 'CONVERSATION_ADD_MESSAGE',
  CONVERSATIONS_RESET = 'CONVERSATIONS_RESET',
}

type Action =
  | { type: ConversationActionTypes.CONVERSATIONS_FETCH_REQUEST }
  | {
      type: ConversationActionTypes.CONVERSATIONS_FETCH_SUCCESS;
      payload: {
        conversations: PageableType<ConversationWithDataDto>;
        users: UserPublicDto[];
        supporters: UserPublicDto[];
      };
    }
  | { type: ConversationActionTypes.CONVERSATIONS_FETCH_ERROR }
  | { type: ConversationActionTypes.CONVERSATION_UPDATE; payload: ConversationWithDataDto }
  | { type: ConversationActionTypes.CONVERSATION_ADD_MESSAGE; payload: MessageDto }
  | { type: ConversationActionTypes.CONVERSATIONS_RESET };

interface State {
  conversations: ConversationWithDataDto[];
  isLoading: boolean;
  pageNumber: number;
  isLastPage: boolean;
}

const initialState: State = {
  conversations: [],
  isLoading: false,
  pageNumber: 0,
  isLastPage: false,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ConversationActionTypes.CONVERSATIONS_FETCH_REQUEST:
      return { ...state, isLoading: true };
    case ConversationActionTypes.CONVERSATIONS_FETCH_SUCCESS:
      if (!action.payload.conversations.content) return state;
      const newConversations = action.payload.conversations.content.map(item => {
        return {
          ...item,
          endUser: action.payload.users.filter(user => user.id === item.endUserId)[0],
          supporter: item.supportEmployeeId
            ? action.payload.supporters.filter(user => user.id === item.supportEmployeeId)[0]
            : undefined,
        };
      });
      return {
        ...state,
        isLoading: false,
        conversations: [...state.conversations, ...newConversations],
        isLastPage: action.payload.conversations.last || false,
        pageNumber: state.pageNumber + 1,
      };
    case ConversationActionTypes.CONVERSATIONS_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ConversationActionTypes.CONVERSATION_UPDATE:
      return {
        ...state,
        conversations: state.conversations
          .map(conversation => {
            if (conversation.id === action.payload.id) {
              return {
                ...conversation,
                lastMessageContent: action.payload.lastMessageContent,
                lastMessageCreated: action.payload.lastMessageCreated,
                unreadMessageCount: action.payload.unreadMessageCount,
              };
            }
            return conversation;
          })
          .sort((a, b) => {
            return new Date(b.lastMessageCreated).getTime() - new Date(a.lastMessageCreated).getTime();
          }),
      };
    case ConversationActionTypes.CONVERSATION_ADD_MESSAGE:
      return {
        ...state,
        conversations: state.conversations
          .map(conversation => {
            if (conversation.conversationId === action.payload.conversationId) {
              return {
                ...conversation,
                lastMessageContent: action.payload.content,
                lastMessageCreated: action.payload.createdAt,
              };
            }
            return conversation;
          })
          .sort((a, b) => {
            return new Date(b.lastMessageCreated).getTime() - new Date(a.lastMessageCreated).getTime();
          }),
      };
    case ConversationActionTypes.CONVERSATIONS_RESET:
      return initialState;
    default:
      return state;
  }
};

export const useConversationsReducer = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState);
  return { state, dispatch };
};
