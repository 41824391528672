import { AxiosPromise } from 'axios';
import { apiUrl, apiService } from '../apiService';
import PageableType from '../utils/pageableType';
import {
  CemeteryCountDto,
  CemeteryDto,
  CemeteryFilterDto,
  PartialMessageUpdateDto,
  RecoveryBackupDto,
} from './maintenanceService.dto';

class MaintenanceService {
  static BASE_URL = `${apiUrl}/api/backend-maintenance`;

  fetchCemeteryMessages(filters: CemeteryFilterDto): AxiosPromise<PageableType<CemeteryDto>> {
    const url = `${MaintenanceService.BASE_URL}/admin/cemetery-messages`;
    return apiService.get(url, { ...filters });
  }

  cemeteryMessagesCount(): AxiosPromise<CemeteryCountDto> {
    const url = `${MaintenanceService.BASE_URL}/admin/iga-cemetery/count`;
    return apiService.get(url);
  }

  backupRecovery(recoveryDto: RecoveryBackupDto) {
    const url = `${MaintenanceService.BASE_URL}/admin/event-store/restore`;
    return apiService.post(url, {}, {}, recoveryDto);
  }

  pullToDatabase(messageCount?: CemeteryCountDto) {
    const url = `${MaintenanceService.BASE_URL}/admin/iga-cemetery/pull`;
    return apiService.post(url, { ...messageCount });
  }

  requeueMessage(id: number, message: PartialMessageUpdateDto) {
    const url = `${MaintenanceService.BASE_URL}/admin/cemetery-messages/${id}`;
    return apiService.patch(url, {}, {}, message);
  }

  deleteMessage(id: number) {
    const url = `${MaintenanceService.BASE_URL}/admin/cemetery-messages/${id}`;
    return apiService.delete(url);
  }
}

const maintenanceService = new MaintenanceService();

export { maintenanceService, MaintenanceService };
