import { AxiosPromise } from 'axios';
import { apiUrl, apiService } from '../apiService';
import { AppVersionResponse } from './frontendMetaService.dto';

class FrontendMetaService {
  static BASE_URL = `${apiUrl}/api/frontend-meta`;

  getVersions(): AxiosPromise<AppVersionResponse[]> {
    const url = `${FrontendMetaService.BASE_URL}/admin/mobile-app-versions`;
    return apiService.get(url);
  }

  getVersion(appVersion: string, platform: string): AxiosPromise<AppVersionResponse> {
    const url = `${FrontendMetaService.BASE_URL}/admin/mobile-app-versions/${appVersion}?platform=${platform}`;
    return apiService.get(url);
  }

  updateFeatureFlag(featureFlagId: number, value: boolean) {
    const url = `${FrontendMetaService.BASE_URL}/admin/feature-flags/${featureFlagId}`;
    return apiService.patch(url, {}, {}, { enabled: value });
  }
}

const frontendMetaService = new FrontendMetaService();

export { frontendMetaService, FrontendMetaService };
