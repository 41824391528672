import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, TableFilterStateDto } from '../common/table/DataTable';
import { DataTableRef } from '../common/table/dataTableRef';
import { dateUtils } from '../../utils/dateUtils';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import { frontendMetaService } from '../../services/FrontendMeta/frontendMetaService';
import { FeatureFlagResponse } from '../../services/FrontendMeta/frontendMetaService.dto';
import DataTableWithFilters from '../common/table/DataTableWithFilters';
import { TableFilter } from '../common/table/FiltersPanel';
import CheckboxInputWithLabel from '../inputs/CheckboxInputWithLabel';
import { useGlobalData } from '../../providers/GlobalDataProvider';
import FeatureFlagsUpdateDialog from './FeatureFlagsUpdateDialog';

const PLATFORMS = ['ios', 'android'];

const FeatureFlagsTable = () => {
  const { t } = useTranslation();
  const dataTableRef = useRef<DataTableRef>(null);

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [selectedFeatureFlag, setSelectedFeatureFlag] = useState<FeatureFlagResponse>();
  const { appVersions } = useGlobalData();
  const { handleError } = useGlobalError();

  const columns: Column<FeatureFlagResponse>[] = [
    {
      id: 'id',
      title: 'ID',
      isSortable: true,
      align: 'center',
      renderCell: data => data.id,
    },
    {
      id: 'feature',
      title: t('feature-flags.feature'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.name,
    },
    {
      id: 'enabled',
      title: t('feature-flags.enabled'),
      isSortable: false,
      align: 'center',
      renderCell: data => (
        <CheckboxInputWithLabel
          alignCenter={true}
          label={booleanValuesMap.get(data.enabled)!}
          checked={data.enabled}
          onChange={() => openUpdateDialog(data)}
        />
      ),
    },
    {
      id: 'description',
      title: t('feature-flags.description'),
      isSortable: false,
      align: 'center',
      renderCell: data => data.description,
    },
    {
      id: 'defaultValue',
      title: t('feature-flags.default-value'),
      isSortable: false,
      align: 'center',
      renderCell: data => booleanValuesMap.get(data.defaultValue)!,
    },
    {
      id: 'lastChangeDate',
      title: t('feature-flags.last-change-date'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.lastChangeDate && dateUtils.formatDateDate(new Date(data.lastChangeDate)),
    },
    {
      id: 'lastChangeUserId',
      title: t('feature-flags.last-change-user-id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.lastChangeUserId,
    },
  ];

  const booleanValuesMap = new Map<boolean, string>([
    [true, t('feature-flags.true')],
    [false, t('feature-flags.false')],
  ]);

  const filters: TableFilter[] = [
    {
      type: 'dropdown',
      name: 'appVersion',
      options: appVersions.map(it => it.appVersion),
      hideEmptyOption: true,
      label: t('feature-flags.app-version'),
    },
    {
      type: 'dropdown',
      name: 'platform',
      options: PLATFORMS,
      hideEmptyOption: true,
      label: t('feature-flags.platform'),
    },
  ];

  const onTableStateChange = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const appVersion = tableState.filtersMap?.get('appVersion');
    const platform = tableState.filtersMap?.get('platform');
    if (appVersion && platform) {
      return frontendMetaService
        .getVersion(appVersion, platform)
        .then(response => ({ content: response.data.featureFlags }));
    }
    return Promise.resolve({ content: [] });
  }, []);

  const openUpdateDialog = (featureFlag: FeatureFlagResponse) => {
    setSelectedFeatureFlag(featureFlag);
    setUpdateDialogOpen(true);
  };

  const closeUpdateDialog = () => {
    setSelectedFeatureFlag(undefined);
    setUpdateDialogOpen(false);
  };

  const confirmUpdate = (enabled: boolean) =>
    frontendMetaService
      .updateFeatureFlag(selectedFeatureFlag!!.id, enabled)
      .then(closeUpdateDialog)
      .then(() => dataTableRef.current?.refresh())
      .catch(error => handleError(error));

  return (
    <>
      <DataTableWithFilters
        ref={dataTableRef}
        columns={columns}
        filters={filters}
        hideApplyButton={true}
        refreshOnFiltersChange={true}
        onTableStateChanged={onTableStateChange}
        disablePagination={true}
      />

      {!!selectedFeatureFlag && (
        <FeatureFlagsUpdateDialog
          open={updateDialogOpen}
          featureFlag={selectedFeatureFlag}
          onClosePressed={closeUpdateDialog}
          onConfirmPress={confirmUpdate}
        />
      )}
    </>
  );
};

export default FeatureFlagsTable;
