import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Popover } from '@mui/material';
import { theme } from '../../assets/styles/theme';
import { TextMain, TextSmall } from '../typography/Texts';
import { NavItemType } from './navItems';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const MenuItem = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const MenuItemText = styled(TextMain)<{ active: boolean }>`
  display: inline;
  border-bottom: ${props => (props.active ? theme.color.lightBlack + ' 2px solid' : 'none')};
  color: ${props => (props.active ? theme.color.lightBlack : theme.color.darkerGray)};
  font-weight: bold;
  :hover {
    color: ${props => (props.active ? theme.color.lightBlack : theme.color.lightGray)};
  }
`;

const UnreadCount = styled(TextSmall)`
  font-weight: bold;
  color: ${theme.color.red};
  margin-top: -20px;
`;

interface Props {
  navItem: NavItemType;
  unreadCount?: number;
}

const NavMenuItem = (props: Props) => {
  const location = useLocation();
  const { path, name, subItems } = props.navItem;
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const hasSubmenu = subItems ? true : false;
  return (
    <>
      <StyledLink to={hasSubmenu ? '#' : path}>
        <MenuItem onClick={e => setAnchorEl(e.currentTarget)}>
          <MenuItemText active={location.pathname.startsWith(path)}>{name}</MenuItemText>
          {props.unreadCount !== undefined && props.unreadCount > 0 && <UnreadCount>{props.unreadCount}</UnreadCount>}
        </MenuItem>
      </StyledLink>
      {hasSubmenu && (
        <Popover
          anchorEl={anchorEl}
          open={anchorEl !== null}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <div>{subItems && subItems.map(item => <NavMenuItem key={item.name} navItem={item} />)}</div>
        </Popover>
      )}
    </>
  );
};

export default NavMenuItem;
