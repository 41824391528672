import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';
import PageContainer from '../../components/common/PageContainer';
import AdminsTable from '../../components/users/AdminsTable';
import AdminsSelector from '../../components/users/AdminsSelector';

const Container = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 10px;
`;

const Admins = () => {
  const [displayAdmins, setDisplayAdmins] = useState(false);

  return (
    <PageContainer>
      <Container>
        <AdminsSelector displayAdmins={displayAdmins} setDisplayAdmins={setDisplayAdmins} />
        <AdminsTable displayAdmins={displayAdmins} />
      </Container>
    </PageContainer>
  );
};

export default Admins;
