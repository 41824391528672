import { AxiosPromise } from 'axios';
import { apiService, apiUrl } from '../apiService';
import { ItemPublicDto } from './itemService.dto';

class ItemService {
  static BASE_URL = `${apiUrl}/api/item`;

  fetchItem(id: number): AxiosPromise<ItemPublicDto> {
    const url = `${ItemService.BASE_URL}/admin/items/${id}`;
    return apiService.get(url);
  }

  fetchItems(ids: number[]): AxiosPromise<ItemPublicDto[]> {
    let url = `${ItemService.BASE_URL}/public/items/bulk`;
    if (ids.length > 0) {
      const idQuery = ids.map(it => `ids=${it}`).join('&');
      url += `?${idQuery}`;
    }
    return apiService.get(url);
  }
}

const itemService = new ItemService();

export { itemService, ItemService };
