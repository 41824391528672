import { UserPublicDto } from '../User/userService.dto';
import { SearchMessageDto } from '../Search/searchService.dto';

export interface ConversationDto {
  id: number;
  conversationId: number;
  endUserId: number;
  supportEmployeeId?: number;
  lastMessageContent: string;
  lastMessageCreated: string;
  status: string;
  unreadMessageCount?: number;
  resolved: boolean;
}

export interface ConversationWithDataDto extends ConversationDto {
  endUser?: UserPublicDto;
  supporter?: UserPublicDto;
  searchMessages?: SearchMessageDto[];
}

export enum ConversationStatus {
  WAITING = 'WAITING',
  ONGOING = 'ONGOING',
  CLOSED = 'CLOSED',
}

export interface InterlocutorDto {
  id: number;
  name: string;
  profileImageUrl: string;
  role: string;
  bankAccountVerified: boolean;
}

export interface MessageDto {
  id: number;
  temporaryId?: string;
  mediaUrl?: string;
  notificationId?: number;
  conversationId?: number;
  payload?: any;
  authorId?: number;
  content: string;
  deliveredToCurrentUser?: boolean;
  deliveredToInterlocutors?: InterlocutorDto[];
  type?: MessageType;
  createdAt: string;
  mediaUrlExpirationTime?: string;
  updatedAt?: string;
  internalStatus?: MessageInternalStatus;
  confirmationReceived?: boolean;
}

export enum MessageType {
  TEXT = 'TEXT',
  AUDIT = 'AUDIT',
  PHOTO = 'PHOTO',
}

export enum MessageInternalStatus {
  UPLOAD_ERROR = 'UPLOAD_ERROR',
  ERROR = 'ERROR',
  UPLOADING = 'UPLOADING',
  SYNC = 'UPLOADED',
}

export interface MessageUrlsDto {
  expirationTime: Date;
  presignedUrl: string;
  url: string;
}

export interface MessageAuditDto {
  id: number;
  supportConversationId: number;
  sourceStatus: string;
  destinationStatus: string;
  supportEmployeeId: number;
  created: string;
}
