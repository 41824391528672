import EditIcon from '@mui/icons-material/Edit';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const DataContainer = styled.div`
  text-align: right;
  width: 100%;
  padding-right: 5px;
`;

const ButtonContainer = styled.div`
  text-align: left;
  width: 100%;
  padding-left: 5px;
`;

interface Props {
  onClick: (element: HTMLDivElement) => void;
  children: ReactNode;
}

const CellWithEdit = ({ onClick, children }: Props) => {
  return (
    <Container onClick={event => onClick(event.currentTarget)}>
      <DataContainer>{children}</DataContainer>
      <ButtonContainer>
        <EditIcon />
      </ButtonContainer>
    </Container>
  );
};

export default CellWithEdit;
