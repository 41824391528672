import React from 'react';
import styled from 'styled-components';
import { MoonLoader } from 'react-spinners';
import { theme } from '../../assets/styles/theme';

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
`;

const LoadingIndicator = () => {
  return (
    <Container>
      <MoonLoader size={40} color={theme.color.black} />
    </Container>
  );
};

export default LoadingIndicator;
