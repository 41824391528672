import React from 'react';
import styled from 'styled-components';
import { Switch } from '@mui/material';
import { theme } from '../../assets/styles/theme';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledSwitch = styled(Switch)<{ checked: boolean }>`
  & .MuiSwitch-thumb {
    background-color: ${props => (props.checked ? theme.color.red : theme.color.darkerGray)};
  }
  & .MuiSwitch-track {
    background-color: ${theme.color.darkerGray} !important;
  }
`;

interface Props {
  checked: boolean;
  onChange: (value: boolean) => void;
}

const SwitchButton = ({ checked, onChange }: Props) => {
  return (
    <Container>
      <StyledSwitch checked={checked} onChange={event => onChange(event.target.checked)} />
    </Container>
  );
};

export default SwitchButton;
