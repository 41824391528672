import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserPublicDto } from '../../services/User/userService.dto';
import { shortDescription, SHORT_DESC_LENGTH } from '../../pages/report/utils/reportConverters';
import { DetailViewData, DetailsViewTag } from '../typography/Headers';
import { DetailsViewText } from '../typography/Texts';
import { Container, DetailsContainer, RowContainer } from '../typography/DetailsViewCommons';
import ImagePreview from '../common/image/ImagePreview';

interface Props {
  userDetails: UserPublicDto;
}

const UserDetails = (props: Props) => {
  const { t } = useTranslation();

  const imgUrl = props.userDetails?.imageUrl ? [props.userDetails.imageUrl] : undefined;

  return (
    <Container>
      <ImagePreview imageUrls={imgUrl} />
      <DetailsContainer>
        <RowContainer>
          <DetailsViewTag>{t('user-details.name-label')}</DetailsViewTag>
          <DetailViewData>{props.userDetails.firstName + ' ' + props.userDetails.lastName}</DetailViewData>
        </RowContainer>
        <RowContainer>
          <DetailsViewTag>{t('user-details.about-label')}</DetailsViewTag>
          <DetailViewData>{shortDescription(props.userDetails.about)}</DetailViewData>
        </RowContainer>
        {props.userDetails.about && props.userDetails.about.length >= SHORT_DESC_LENGTH && (
          <DetailsViewText>{props.userDetails.about}</DetailsViewText>
        )}
        <RowContainer>
          <DetailsViewTag>{t('user-details.location-label')}</DetailsViewTag>
          <DetailViewData>{props.userDetails?.location?.city}</DetailViewData>
        </RowContainer>
      </DetailsContainer>
    </Container>
  );
};

export default UserDetails;
