import React, { useState } from 'react';
import styled from 'styled-components';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputWithLabel from '../inputs/InputWithLabel';
import closeIcon from '../../assets/svg/close.svg';
import { CloseButton, ConfirmButton } from '../buttons/popoverButtons';
import { FormHeader } from '../typography/PopoverTypography';
import { ManualRefundDto, ManualRefundStatus } from '../../services/Payment/paymentService.dto';
import DropdownWithLabel from '../inputs/DropdownWithLabel';
import { MANUAL_REFUND_OPTIONS } from './payment.types';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 320px;
`;

interface Props {
  open: boolean;
  manualRefund: ManualRefundDto;
  onClosePressed: () => void;
  onConfirmPress: (notes: string, status: ManualRefundStatus) => void;
}

const ManualRefundsUpdateDialog = ({ open, manualRefund, onClosePressed, onConfirmPress }: Props) => {
  const { t } = useTranslation();

  const [notes, setNotes] = useState(manualRefund.notes || '');
  const [status, setStatus] = useState<ManualRefundStatus>(manualRefund.status);

  return (
    <Dialog open={open} onClose={onClosePressed}>
      <FormContainer>
        <FormHeader>
          {t('manual-refunds.update-status-popover.title')} <b>{`${manualRefund.orderId}`} </b>
        </FormHeader>
        <CloseButton src={closeIcon} alt={'close'} onClick={onClosePressed} />

        <DropdownWithLabel
          label={t('manual-refunds.update-status-popover.status')}
          value={status}
          onChange={value => setStatus(value as ManualRefundStatus)}
          options={MANUAL_REFUND_OPTIONS}
          placeholder={t('manual-refunds.update-status-popover.status-placeholder')}
          minWidth={300}
        />

        <InputWithLabel
          label={t('manual-refunds.update-status-popover.notes')}
          onChange={setNotes}
          value={notes}
          multiline={true}
          minWidth={300}
        />

        <ConfirmButton
          label={t('manual-refunds.update-status-popover.confirm')}
          onClick={() => onConfirmPress(notes, status)}
          disabled={!notes || !status}
        />
      </FormContainer>
    </Dialog>
  );
};

export default ManualRefundsUpdateDialog;
