import i18n from 'i18next';
import { CredentialDto } from '../../../services/Auth/authService.dto';
import { TableFilterStateDto } from '../../../components/common/table/DataTable';

export const allowedDomains = process.env.REACT_APP_ALLOWED_DOMAINS!.split(',');

export enum credentialActionResult {
  UPDATED,
  IS_EMPLOYEE,
  IS_NOT_EMPLOYEE,
  WRONG_DOMAIN,
}

export interface RoleResult {
  actionResult: credentialActionResult;
  credentials: CredentialDto;
}

export interface AdminFilter {
  employee: boolean;
  username?: string;
}

export const actionErrorMap = new Map([
  [credentialActionResult.IS_EMPLOYEE, i18n.t('users.error.is-employee')],
  [credentialActionResult.IS_NOT_EMPLOYEE, i18n.t('users.error.is-not-employee')],
  [credentialActionResult.WRONG_DOMAIN, i18n.t('users.error.wrong-domain')],
]);

export const grantEmployeeRole = (credentials: CredentialDto): RoleResult => {
  if (credentials.isEmployee) return { actionResult: credentialActionResult.IS_EMPLOYEE, credentials };
  if (!allowedDomains.some(domain => credentials.username.includes(domain)))
    return { actionResult: credentialActionResult.WRONG_DOMAIN, credentials };

  credentials.authorities.push('EMPLOYEE');
  return { actionResult: credentialActionResult.UPDATED, credentials };
};

export const revokeEmployeeRole = (credentials: CredentialDto): RoleResult => {
  if (!credentials.isEmployee) return { actionResult: credentialActionResult.IS_NOT_EMPLOYEE, credentials };
  credentials.authorities = credentials.authorities.filter(role => role !== 'EMPLOYEE');
  return { actionResult: credentialActionResult.UPDATED, credentials };
};

export const convertFilters = (filter: TableFilterStateDto<any>) => {
  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'id,desc',
    username: filter.filtersMap?.username,
    isEmployee: filter.filtersMap?.employee ? filter.filtersMap.employee : undefined,
    inDomains: filter.filtersMap?.employee ? undefined : allowedDomains.join(','),
  };
};
