import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/common/PageContainer';
import { theme } from '../../assets/styles/theme';
import DataTableWithFilters from '../../components/common/table/DataTableWithFilters';
import { DataTableRef } from '../../components/common/table/dataTableRef';
import { Column, TableFilterStateDto } from '../../components/common/table/DataTable';
import { CampaignAdminDto } from '../../services/Campaign/campaignService.dto';
import { TableFilter } from '../../components/common/table/FiltersPanel';
import { campaignService } from '../../services/Campaign/campaignService';
import { useGlobalData } from '../../providers/GlobalDataProvider';
import SortingBoostPopover from '../../components/campaigns/SortingBoostPopover';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import CellWithEdit from '../../components/common/table/CellWithEdit';
import CampaignActions from '../../components/campaigns/CampaignActions';
import CellWithPopover from '../../components/common/table/CellWithPopover';
import CampaignDetailsPopover from '../../components/campaign/CampaignDetailsPopover';
import CellWithNavigate from '../../components/common/table/CellWithNavigate';
import { convertFiltersToCampaignFilterDto } from './utils/filterConverter';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const Campaigns = () => {
  const { t } = useTranslation();
  const { campaignCategories: categories } = useGlobalData();
  const { handleError } = useGlobalError();

  const dataTableRef = useRef<DataTableRef>(null);

  const [selectedCampaign, setSelectedCampaign] = useState<CampaignAdminDto>();
  const [sortingBoostAnchor, setSortingBoostAnchor] = useState<HTMLDivElement | null>(null);

  const toCategoryName = (code: string) => categories.find(category => category.code === code)?.displayName || code;
  const categoryPicklist = categories.map(category => category.displayName);

  const columns: Column<CampaignAdminDto>[] = [
    {
      id: 'id',
      title: t('campaigns.columns.id'),
      isSortable: true,
      minWidth: 40,
      maxWidth: 40,
      align: 'center',
      renderCell: data => (
        <CellWithPopover popover={<CampaignDetailsPopover campaignId={data.id} />}>{data.id}</CellWithPopover>
      ),
    },
    {
      id: 'name',
      title: t('campaigns.columns.name'),
      isSortable: true,
      minWidth: 100,
      maxWidth: 400,
      renderCell: data => data.name,
    },
    {
      id: 'category',
      title: t('campaigns.columns.category'),
      minWidth: 70,
      maxWidth: 100,
      isSortable: true,
      renderCell: data => toCategoryName(data.category),
    },
    {
      id: 'status',
      title: t('campaigns.columns.status'),
      minWidth: 70,
      maxWidth: 100,
      isSortable: true,
      renderCell: data => data.status,
    },
    {
      id: 'ownerId',
      title: t('campaigns.columns.owner-id'),
      isSortable: true,
      minWidth: 70,
      maxWidth: 100,
      align: 'center',
      renderCell: data => (
        <CellWithNavigate
          url={`/users/fundraisers/${data.ownerId}`}
          title={t('campaigns.go-to-fundraisers-tooltip', { id: data.ownerId })}>
          {data.ownerId}
        </CellWithNavigate>
      ),
    },
    {
      id: 'moneyCollected',
      title: t('campaigns.columns.money-collected'),
      isSortable: true,
      minWidth: 70,
      maxWidth: 100,
      align: 'center',
      renderCell: data => data.moneyCollected,
    },
    {
      id: 'moneyCollectedWithBoost',
      title: t('campaigns.columns.money-collected-with-boost'),
      isSortable: true,
      minWidth: 70,
      maxWidth: 100,
      align: 'center',
      renderCell: data => data.moneyCollectedWithBoost,
    },
    {
      id: 'adminSortingBoost',
      title: t('campaigns.columns.sorting-boost'),
      isSortable: true,
      minWidth: 60,
      maxWidth: 100,
      align: 'center',
      renderCell: data => (
        <CellWithEdit onClick={element => handleSortingBoostEdit(element, data)}>{data.adminSortingBoost}</CellWithEdit>
      ),
    },
    {
      id: 'actions',
      title: t('users.columns.actions'),
      align: 'center',
      minWidth: 60,
      renderCell: data => <CampaignActions campaign={data} onUpdated={() => dataTableRef.current?.refresh()} />,
    },
  ];

  const filters: TableFilter[] = [
    { type: 'text', name: 'id', label: t('campaigns.filters.id') },
    { type: 'text', name: 'name', label: t('campaigns.filters.name') },
    {
      type: 'dropdown',
      name: 'category',
      label: t('campaigns.filters.category'),
      options: categoryPicklist,
    },
    { type: 'text', name: 'ownerId', label: t('campaigns.filters.owner-id') },
  ];

  const handleTableStateChange = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const campaignFilter = convertFiltersToCampaignFilterDto(tableState, categories);
    return campaignService.fetchCampaigns(campaignFilter).then(response => response.data);
  }, []);

  const handleSortingBoostEdit = (anchor: HTMLDivElement, campaign: CampaignAdminDto) => {
    setSortingBoostAnchor(anchor);
    setSelectedCampaign(campaign);
  };

  const handleSortingBoostPopupClose = () => {
    setSelectedCampaign(undefined);
    setSortingBoostAnchor(null);
  };

  const handleSortingBoostUpdate = (campaignId: number, adminSortingBoost: number) => {
    campaignService
      .updateSortingBoost(campaignId, adminSortingBoost)
      .then(() => dataTableRef.current?.refresh())
      .catch(error => handleError(error));

    handleSortingBoostPopupClose();
  };

  return (
    <PageContainer>
      <Container>
        <DataTableWithFilters
          ref={dataTableRef}
          columns={columns}
          filters={filters}
          onTableStateChanged={handleTableStateChange}
        />
      </Container>
      {selectedCampaign && sortingBoostAnchor && (
        <SortingBoostPopover
          anchor={sortingBoostAnchor}
          campaignData={selectedCampaign}
          onConfirmPress={handleSortingBoostUpdate}
          onClosePressed={handleSortingBoostPopupClose}
        />
      )}
    </PageContainer>
  );
};

export default Campaigns;
