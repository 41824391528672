import React from 'react';
import styled from 'styled-components';

const PointerContainer = styled.div`
  cursor: pointer;
  margin-right: 5px;
`;

interface Props {
  jsonData: string;
  onPress: (element: HTMLDivElement, data: string) => void;
}

const SHORT_DESCRIPTION_LENGTH = 64;

const JSONCell = (props: Props) => {
  return (
    <PointerContainer onClick={e => props.onPress(e.currentTarget, props.jsonData)}>
      {props.jsonData.substring(0, SHORT_DESCRIPTION_LENGTH) + '...'}
    </PointerContainer>
  );
};

export default JSONCell;
