import React, { useState } from 'react';
import styled from 'styled-components';
import eyeIcon from '../../assets/svg/show-password.svg';
import { theme } from '../../assets/styles/theme';

const InputContainer = styled.div`
  position: relative;
  padding: 11px 15px;
  background-color: ${theme.color.lighterGray};
  border-radius: ${theme.borderRadiusStandard}px;
  min-width: 300px;
`;

const PasswordInput = styled.input`
  max-width: 1400px;
  display: flex;
  background-color: ${theme.color.lighterGray};
  font-size: ${theme.font.size.xs};
  font-family: ${theme.font.family.main};
  align-items: center;
  border: 0;
  outline: 0;
  width: 92%;
`;

const EyeIcon = styled.button<{ isActive: boolean }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  outline: none;
  filter: ${props => (props.isActive ? 'brightness(60%)' : 'none')};
  transition: filter 0.3s;

  &:hover {
    filter: brightness(60%);
  }
`;

interface Props {
  placeholder?: string;
  onChange: (value: any) => void;
  value: string;
}

const PasswordInputField = (props: Props) => {
  const [isPasswordMasked, setIsPasswordMasked] = useState(true);

  const togglePasswordMask = () => {
    setIsPasswordMasked(!isPasswordMasked);
  };

  return (
    <InputContainer>
      <PasswordInput
        placeholder={props.placeholder}
        type={isPasswordMasked ? 'password' : 'text'}
        value={props.value}
        onChange={props.onChange}
        maxLength={255}
      />
      <EyeIcon tabIndex={-1} onClick={togglePasswordMask} isActive={!isPasswordMasked}>
        <img src={eyeIcon} alt='' />
      </EyeIcon>
    </InputContainer>
  );
};

export default PasswordInputField;
