import React from 'react';
import styled from 'styled-components';
import { SwipeableDrawer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import { TextMain } from '../typography/Texts';
import RectangularButton from '../buttons/RectangularButton';
import ProfilePicture from '../common/ProfilePicture';
import { theme } from '../../assets/styles/theme';
import { messagesNavItem, navItems } from './navItems';
import NavMenuItem from './NavMenuItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 250px;
`;

const ProfileInfoContainer = styled.div`
  padding: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${theme.color.lightGray};
`;

const UserName = styled(TextMain)`
  font-weight: bold;
  margin-left: 10px;
`;

const LogoutButton = styled(RectangularButton)`
  position: absolute;
  bottom: 10px;
  left: 10%;
  right: 10%;
  padding: 5px;
  width: 80%;
  font-weight: normal;
  align-self: center;
`;

interface Props {
  toggleMenu: () => void;
  isMenuVisible: boolean;
}

const Drawer = (props: Props) => {
  const { currentUser, logout, unreadMessagesCount } = useAuth();
  const { t } = useTranslation();
  const { isMenuVisible, toggleMenu } = props;

  return (
    <SwipeableDrawer onClose={toggleMenu} onOpen={toggleMenu} open={isMenuVisible} anchor={'left'}>
      <Container>
        <ProfileInfoContainer>
          <ProfilePicture imageUrl={currentUser?.imageUrl} />
          <UserName>
            {currentUser?.firstName} {currentUser?.lastName}
          </UserName>
        </ProfileInfoContainer>
        <NavMenuItem navItem={messagesNavItem} unreadCount={unreadMessagesCount} />
        {navItems.map(item => (
          <NavMenuItem key={item.name} navItem={item} />
        ))}
        <LogoutButton label={t('my-profile.logout')} onClick={logout} />
      </Container>
    </SwipeableDrawer>
  );
};

export default Drawer;
