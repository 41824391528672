import React, { createContext, FC, ReactNode, useContext, useState } from 'react';
import styled from 'styled-components';
import { ClickAwayListener, Popover } from '@mui/material';
import closeIcon from '../assets/svg/close.svg';
import ConfirmDialog from '../components/common/dialogs/ConfirmDialog';

const PopoverContainer = styled.div`
  padding: 40px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 700px;
  height: 350px;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

interface PopupContextType {
  showPopover: (content: ReactNode, anchor?: SVGSVGElement | HTMLDivElement) => void;
  showConfirmDialog: (msg: string, onYesClick: () => void) => void;
  showDialog: (content: ReactNode) => void;
  hideDialog: () => void;
}

const PopupContext = createContext<PopupContextType>(null!);

const PopupProvider: FC<{ children: ReactNode }> = props => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popoverContent, setPopoverContent] = useState<ReactNode>();
  const [dialogContent, setDialogContent] = useState<ReactNode>();
  const [confirmDialogContent, setConfirmDialogContent] = useState<ReactNode>();
  const [anchor, setAnchor] = useState<SVGSVGElement | HTMLDivElement>();

  const showPopover = (content: ReactNode, anchor?: SVGSVGElement | HTMLDivElement) => {
    setIsPopoverOpen(true);
    setAnchor(anchor);
    setPopoverContent(content);
  };

  const showConfirmDialog = (msg: string, onYesClick: () => void) => {
    setConfirmDialogContent(
      <ConfirmDialog
        msg={msg}
        onYesClick={() => {
          onYesClick();
          setConfirmDialogContent(undefined);
        }}
        onNoClick={() => setConfirmDialogContent(undefined)}
      />
    );
  };

  const showDialog = (content: ReactNode) => {
    setDialogContent(content);
  };

  const hidePopover = () => {
    setIsPopoverOpen(false);
  };

  const hideDialog = () => {
    setDialogContent(undefined);
  };

  return (
    <PopupContext.Provider value={{ showPopover, showConfirmDialog, showDialog, hideDialog }}>
      {props.children}
      <Popover
        anchorEl={anchor}
        open={isPopoverOpen}
        onClose={hidePopover}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
        <ClickAwayListener onClickAway={hidePopover}>
          <PopoverContainer>
            <CloseButton src={closeIcon} alt={'close'} onClick={hidePopover} />
            {popoverContent}
          </PopoverContainer>
        </ClickAwayListener>
      </Popover>
      {!!dialogContent && dialogContent}
      {!!confirmDialogContent && confirmDialogContent}
    </PopupContext.Provider>
  );
};

const usePopup = () => useContext(PopupContext);

export { PopupProvider, usePopup };
