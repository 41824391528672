import React, { CSSProperties, forwardRef, Ref, useState } from 'react';
import styled from 'styled-components';
import PageableType from '../../../services/utils/pageableType';
import DataTable, { Column, TableFilterStateDto } from './DataTable';
import TableFiltersPanel, { TableFilter } from './FiltersPanel';
import { DataTableRef } from './dataTableRef';

const Container = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
`;

interface Props<DataType> {
  columns: Column<DataType>[];
  filters: TableFilter[];
  styleRow?: (data: DataType) => CSSProperties;
  onTableStateChanged: (tableState: TableFilterStateDto<Map<string, any>>) => Promise<PageableType<DataType>>;
  ref?: Ref<DataTableRef>;
  hideApplyButton?: boolean;
  refreshOnFiltersChange?: boolean;
  filterInitState?: Map<string, any>;
  disablePagination?: boolean;
}

function DataTableWithFilters<DataType>(props: Props<DataType>, ref: Ref<DataTableRef>) {
  const { columns, filters, onTableStateChanged, styleRow } = props;
  const { hideApplyButton, refreshOnFiltersChange, filterInitState, disablePagination } = props;
  const [filterData, setFilterData] = useState<Map<string, any>>(filterInitState || new Map());

  return (
    <Container>
      <TableFiltersPanel
        onApply={setFilterData}
        filters={filters}
        hideApplyButton={hideApplyButton}
        refreshOnChange={refreshOnFiltersChange}
        filterInitState={filterInitState}
      />
      <DataTable
        ref={ref}
        columns={columns}
        filterData={filterData}
        styleRow={styleRow}
        skipInitialFetch={true} // disabled initial fetch by DataTable, as TableFiltersPanel triggers initial fetch anyway
        onTableStateChanged={onTableStateChanged}
        disablePagination={disablePagination}
      />
    </Container>
  );
}

export default forwardRef(DataTableWithFilters) as typeof DataTableWithFilters;
