import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TableFiltersPanel, { TableFilter } from '../common/table/FiltersPanel';
import { TextMain } from '../typography/Texts';
import { theme } from '../../assets/styles/theme';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import RectangularButton from '../buttons/RectangularButton';
import { toCemeteryCountDto } from '../../pages/maintenance/utils/maintenanceConverter';
import { maintenanceService } from '../../services/Maintenance/maintenanceService';
import { CemeteryCountDto } from '../../services/Maintenance/maintenanceService.dto';
import CemeteryPullPopover from './CemeteryPullPopover';

const Container = styled.div`
  padding: ${theme.paddingStandard}px;
  display: flex;
  justify-content: space-between;
`;

const GroupContainer = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const TextMainWithBorders = styled(TextMain)`
  border: 1px solid ${theme.color.lightBlack};
  border-radius: 3px;
  padding: 3px;
  margin: 5px;
`;

const Button = styled(RectangularButton)`
  font-weight: normal;
  height: 30px;
  margin: 5px 30px 5px 5px;
`;

interface Props {
  databaseMessages: number;
  onApply: (filters: Map<string, any>) => void;
}

const CemeteryHeader = (props: Props) => {
  const { t } = useTranslation();
  const [cemeteryMessages, setCemeteryMessages] = useState<CemeteryCountDto>({ count: 0 });
  const [pullPopupAnchor, setPullPopupAnchor] = useState<HTMLButtonElement | null>(null);
  const { handleError } = useGlobalError();

  useEffect(() => {
    maintenanceService
      .cemeteryMessagesCount()
      .then(response => setCemeteryMessages(response.data))
      .catch(e => handleError(e));
  }, []);

  const filterInitState = new Map([
    ['deleted', 'false'],
    ['requeued', 'false'],
  ]);

  const dropdownOptionsMap = new Map<string, string>([
    ['true', t('maintenance.cemetery.yes')],
    ['false', t('maintenance.cemetery.no')],
  ]);

  const filters: TableFilter[] = [
    {
      type: 'dropdown',
      name: 'deleted',
      label: t('maintenance.cemetery.deleted-dropdown'),
      booleanMap: dropdownOptionsMap,
    },
    {
      type: 'dropdown',
      name: 'requeued',
      label: t('maintenance.cemetery.requeued-dropdown'),
      booleanMap: dropdownOptionsMap,
    },
    {
      type: 'text',
      name: 'firstDeathSearch',
      label: t('maintenance.cemetery.first-death-search'),
    },
    {
      type: 'text',
      name: 'lastDeathSearch',
      label: t('maintenance.cemetery.last-death-search'),
    },
  ];

  const openPullPopup = (anchor: HTMLButtonElement) => {
    setPullPopupAnchor(anchor);
  };

  const closePullPopup = () => {
    setPullPopupAnchor(null);
  };

  const pullMessages = (count: number) => {
    maintenanceService.pullToDatabase(toCemeteryCountDto(count));
    closePullPopup();
  };

  return (
    <Container>
      <GroupContainer>
        <TextMain>{t('maintenance.cemetery.in-cemetery')}</TextMain>
        <TextMainWithBorders>{cemeteryMessages.count}</TextMainWithBorders>
        <Button label={t('maintenance.cemetery.pull-btn')} onClick={openPullPopup} />
        <TableFiltersPanel onApply={props.onApply} filters={filters} filterInitState={filterInitState} />
      </GroupContainer>
      <GroupContainer>
        <TextMain>{t('maintenance.cemetery.in-database')}</TextMain>
        <TextMainWithBorders>{props.databaseMessages}</TextMainWithBorders>
      </GroupContainer>
      <CemeteryPullPopover anchor={pullPopupAnchor} onClosePressed={closePullPopup} onContinuePressed={pullMessages} />
    </Container>
  );
};

export default CemeteryHeader;
