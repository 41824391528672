import React from 'react';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import ProfilePicture from '../common/ProfilePicture';
import { TextMain } from '../typography/Texts';
import RectangularButton from '../buttons/RectangularButton';

const Container = styled.div`
  cursor: pointer;
`;

const ProfileInfoContainer = styled.div`
  padding: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const UserName = styled(TextMain)`
  font-weight: bold;
`;

const LogoutButton = styled(RectangularButton)`
  max-width: 50px;
  padding: 5px;
  font-weight: normal;
  margin-top: 10px;
`;

const MyProfile = () => {
  const { currentUser, logout } = useAuth();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  if (!currentUser) return <></>;
  return (
    <Container>
      <div onClick={e => setAnchorEl(e.currentTarget)}>
        <ProfilePicture imageUrl={currentUser?.imageUrl} />
      </div>

      <Popover
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <ProfileInfoContainer>
          <UserName>
            {currentUser?.firstName} {currentUser?.lastName}
          </UserName>
          <LogoutButton label={t('my-profile.logout')} onClick={logout} />
        </ProfileInfoContainer>
      </Popover>
    </Container>
  );
};

export default MyProfile;
