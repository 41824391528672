import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommentDto } from '../../services/Campaign/campaignService.dto';
import { shortDescription, SHORT_DESC_LENGTH } from '../../pages/report/utils/reportConverters';
import { DetailViewData, DetailsViewTag } from '../typography/Headers';
import { DetailsViewText } from '../typography/Texts';
import { Container, DetailsContainer, RowContainer } from '../typography/DetailsViewCommons';
import ImagePreview from '../common/image/ImagePreview';

interface Props {
  commentDetails: CommentDto;
}

const CommentDetails = (props: Props) => {
  const { t } = useTranslation();

  const imgUrl = props.commentDetails?.imageUrl ? [props.commentDetails.imageUrl] : undefined;

  return (
    <Container>
      <ImagePreview imageUrls={imgUrl} />
      <DetailsContainer>
        <RowContainer>
          <DetailsViewTag>{t('comment-details.content-label')}</DetailsViewTag>
          <DetailViewData>{shortDescription(props.commentDetails.content)}</DetailViewData>
        </RowContainer>
        {props.commentDetails.content && props.commentDetails.content.length >= SHORT_DESC_LENGTH && (
          <DetailsViewText>{props.commentDetails.content}</DetailsViewText>
        )}
        <RowContainer>
          <DetailsViewTag>{t('comment-details.author-label')}</DetailsViewTag>
          <DetailViewData>{props.commentDetails.authorId}</DetailViewData>
        </RowContainer>
        <RowContainer>
          <DetailsViewTag>{t('comment-details.replies-label')}</DetailsViewTag>
          <DetailViewData>{props.commentDetails.repliesCount}</DetailViewData>
        </RowContainer>
        <RowContainer>
          <DetailsViewTag>{t('comment-details.likes-label')}</DetailsViewTag>
          <DetailViewData>{props.commentDetails.likesCount}</DetailViewData>
        </RowContainer>
      </DetailsContainer>
    </Container>
  );
};

export default CommentDetails;
