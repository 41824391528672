import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessages } from '../../../providers/messages/MessagesProvider';
import { useSearchMessages } from '../../../providers/messages/useSearchMessages';
import ConversationList from '../ConversationList';
import SearchConversationList from '../SearchConversationList';
import LoadingIndicator from '../../common/LoadingIndicator';
import { ConversationStatus } from '../../../services/Message/messageService.dto';
import { useAuth } from '../../../providers/AuthProvider';
import { SearchType } from '../searchType';

interface Props {
  path: string;
  selectedConversationId?: number;
  onCloseConversation: () => void;
  searchValue: string;
  searchType: SearchType;
}

const ConversationTabQueue = (props: Props) => {
  const { searchValue, searchType } = props;
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const { supportingConversations, waitingConversations } = useMessages();

  const searchSupporting = useSearchMessages(ConversationStatus.ONGOING, currentUser?.id);
  const searchWaiting = useSearchMessages(ConversationStatus.WAITING);

  useEffect(() => {
    waitingConversations.fetchConversations();

    return () => {
      supportingConversations.resetConversations();
      waitingConversations.resetConversations();
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      supportingConversations.fetchConversations();
    }
  }, [currentUser]);

  useEffect(() => {
    searchSupporting.fetchMessagesBySearch(searchValue, searchType);
    searchWaiting.fetchMessagesBySearch(searchValue, searchType);
    if (searchValue) props.onCloseConversation();
  }, [searchValue, searchType]);

  const renderConversationsList = () => {
    if (searchValue) {
      if (!searchSupporting.searchResult || searchSupporting.isLoading) return <LoadingIndicator />;
      if (!searchWaiting.searchResult || searchWaiting.isLoading) return <LoadingIndicator />;
      return (
        <SearchConversationList
          path={props.path}
          conversations={[...searchSupporting.searchResult, ...searchWaiting.searchResult]}
          selectedConversationId={props.selectedConversationId}
        />
      );
    } else {
      return (
        <>
          {!currentUser ? (
            <LoadingIndicator />
          ) : (
            <ConversationList
              title={t('messages.header-supporting')}
              path={props.path}
              selectedConversationId={props.selectedConversationId}
              {...supportingConversations}
            />
          )}
          <ConversationList
            title={t('messages.header-waiting')}
            path={props.path}
            selectedConversationId={props.selectedConversationId}
            {...waitingConversations}
          />
        </>
      );
    }
  };

  return <>{renderConversationsList()}</>;
};

export default ConversationTabQueue;
