import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DropdownWithLabel from '../inputs/DropdownWithLabel';
import InputWithLabel from '../inputs/InputWithLabel';
import closeIcon from '../../assets/svg/close.svg';
import { ConfirmButton, CloseButton } from '../buttons/popoverButtons';
import { FormHeader } from '../typography/PopoverTypography';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 320px;
`;

interface Props {
  anchor: SVGSVGElement | HTMLDivElement | null;
  userData: { firstName: string; lastName: string };
  blockadeReasons: string[];
  onBlockPressed: (reason: string, comment?: string) => void;
  onClosePressed: () => void;
}

const BlockUserPopover = (props: Props) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState<string>();
  const [comment, setComment] = useState('');

  const handleConfirmPress = () => {
    if (reason) props.onBlockPressed(reason, comment);
  };

  return (
    <Popover
      anchorEl={props.anchor}
      open={props.anchor !== null}
      onClose={props.onClosePressed}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
      <FormContainer>
        <FormHeader>
          {t('users.block-label')} <b>{`${props.userData.firstName} ${props.userData.lastName}`}</b>
        </FormHeader>
        <CloseButton src={closeIcon} alt={'close'} onClick={props.onClosePressed} />
        <DropdownWithLabel
          label={t('users.filters.reason')}
          value={reason}
          onChange={setReason}
          options={props.blockadeReasons}
          placeholder={t('users.select-reason')}
          minWidth={300}
        />
        <InputWithLabel
          label={t('users.comment')}
          onChange={setComment}
          value={comment}
          multiline={true}
          minWidth={300}
        />
        <ConfirmButton label={t('users.block-btn')} onClick={handleConfirmPress} disabled={!reason} />
      </FormContainer>
    </Popover>
  );
};

export default BlockUserPopover;
