import React from 'react';
import styled from 'styled-components';
import profilePlaceholder from '../../assets/svg/profile-placeholder.svg';
import { convertToThumbnail300 } from '../../utils/thumbnails';

const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 25px;
`;

interface Props {
  imageUrl?: string;
}

const ProfilePicture = ({ imageUrl }: Props) => {
  const src = imageUrl ? convertToThumbnail300(imageUrl) : profilePlaceholder;
  return <Image src={src} alt={'profile picture'} />;
};

export default ProfilePicture;
