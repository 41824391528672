export enum ObjectType {
  CAMPAIGN = 'CAMPAIGN',
  COMMENT = 'COMMENT',
  ITEM = 'ITEM',
  USER = 'USER',
}

export enum ActionType {
  IGNORE = 'IGNORE',
  DELETE = 'DELETE',
  HIDE = 'HIDE',
  REMOVE = 'REMOVE',
  COMMENT = 'COMMENT',
}

const buildButtonNameMap = () => {
  return new Map([
    [ActionType.IGNORE, 'report.popover.button.ignore-btn'],
    [ActionType.DELETE, 'report.popover.button.delete-btn'],
    [ActionType.HIDE, 'report.popover.button.hide-btn'],
    [ActionType.REMOVE, 'report.popover.button.remove-btn'],
    [ActionType.COMMENT, 'report.popover.button.comment-btn'],
  ]);
};

const buildWarningMessageMap = () => {
  return new Map([
    [ActionType.IGNORE, 'report.popover.ignore-label'],
    [ActionType.DELETE, 'report.popover.delete-label'],
    [ActionType.HIDE, 'report.popover.hide-label'],
    [ActionType.REMOVE, 'report.popover.remove-label'],
    [ActionType.COMMENT, 'report.popover.add-comment-label'],
  ]);
};

export const buttonNameMap = buildButtonNameMap();
export const warningMessageMap = buildWarningMessageMap();
