import { AxiosPromise } from 'axios';
import { apiUrl, apiService } from '../apiService';
import PageableType from '../utils/pageableType';
import { ConversationDto, MessageAuditDto, MessageDto, MessageUrlsDto } from './messageService.dto';

const CONVERSATION_PAGE_SIZE = 20;

class MessageService {
  static BASE_URL = `${apiUrl}/api/message`;

  fetchConversations(
    pageNumber: number,
    status: string,
    supportEmployeeId?: number
  ): AxiosPromise<PageableType<ConversationDto>> {
    const url = `${MessageService.BASE_URL}/admin/support-conversations`;
    return apiService.get(url, {
      size: CONVERSATION_PAGE_SIZE,
      page: pageNumber,
      status,
      supportEmployeeId,
      sort: 'lastMessageCreated,desc',
    });
  }

  searchConversationsByUsers(
    searchValue: string,
    conversationStatus: string,
    pageSize: number,
    supporterId?: number
  ): AxiosPromise<PageableType<ConversationDto>> {
    const url = `${MessageService.BASE_URL}/admin/support-conversations`;
    return apiService.get(url, {
      size: pageSize,
      status: conversationStatus,
      supportEmployeeId: supporterId,
      interlocutorsConcatenated: searchValue,
      sort: 'lastMessageCreated,desc',
    });
  }

  fetchConversation(conversationId: number): AxiosPromise<ConversationDto> {
    const url = `${MessageService.BASE_URL}/admin/support-conversations/${conversationId}`;
    return apiService.get(url);
  }

  fetchConversationBySupportConversationId(supportConversationId: number): AxiosPromise<PageableType<ConversationDto>> {
    const url = `${MessageService.BASE_URL}/admin/support-conversations`;
    return apiService.get(url, { conversationId: supportConversationId });
  }

  updateConversation(conversationId: number, status: string, supportEmployeeId?: number) {
    const url = `${MessageService.BASE_URL}/admin/support-conversations/${conversationId}`;
    return apiService.patch(url, {}, {}, { status, supportEmployeeId });
  }

  resolveConversation(id: number) {
    const url = `${MessageService.BASE_URL}/admin/support-conversations/${id}/resolved`;
    return apiService.put(url);
  }

  unResolveConversation(id: number) {
    const url = `${MessageService.BASE_URL}/admin/support-conversations/${id}/resolved`;
    return apiService.delete(url);
  }

  createConversation(endUserId: number): AxiosPromise<ConversationDto> {
    const url = `${MessageService.BASE_URL}/admin/support-conversations`;
    return apiService.post(url, {}, {}, { endUserId });
  }

  fetchMessagesBeforeId(
    conversationId: number,
    size: number,
    beforeMessageId?: number,
    includeCurrentMessage?: boolean
  ): AxiosPromise<MessageDto[]> {
    const url = `${MessageService.BASE_URL}/admin/support-conversations/${conversationId}/messages`;
    return apiService.get(url, { size, beforeMessageId, includeCurrentMessage });
  }

  fetchMessagesAfterId(conversationId: number, size: number, afterMessageId?: number): AxiosPromise<MessageDto[]> {
    const url = `${MessageService.BASE_URL}/admin/support-conversations/${conversationId}/messages`;
    return apiService.get(url, { size, afterMessageId });
  }

  addMessage(conversationId: number, type: string, content: string): AxiosPromise<MessageDto> {
    const payload = type === 'TEXT' ? { type, content } : { type, mediaUrl: content };

    const url = `${MessageService.BASE_URL}/conversations/${conversationId}/messages`;
    return apiService.post(url, { conversationId }, {}, payload);
  }

  generateMsgUrls(conversationId: number): AxiosPromise<MessageUrlsDto[]> {
    const url = `${MessageService.BASE_URL}/conversations/${conversationId}/message-urls`;
    return apiService.post(url, { conversationId });
  }

  confirmMessages(messageIds: number[]) {
    const payload = messageIds.map(id => {
      return { messageId: id };
    });
    return apiService.post(`${MessageService.BASE_URL}/message-confirmations`, {}, {}, payload);
  }

  refreshMessageImages(messageIds: number[], conversationId: number): AxiosPromise<MessageDto[]> {
    const url = `${MessageService.BASE_URL}/refresh-images`;
    return apiService.post(url, {}, {}, { messageIds, conversationId });
  }

  fetchUnreadMessagesCount(): AxiosPromise<{ messagesCount: number }> {
    const url = `${MessageService.BASE_URL}/admin/support-conversations/unread-messages-count`;
    return apiService.get(url);
  }

  fetchAudit(conversationId: number): AxiosPromise<PageableType<MessageAuditDto>> {
    const url = `${MessageService.BASE_URL}/admin/support-conversations/${conversationId}/audit`;
    return apiService.get(url, {
      size: 1000,
      sort: 'created,desc',
    });
  }
}

const messageService = new MessageService();

export { messageService, MessageService };
