import { apiService } from '../apiService';

class ImageUploadService {
  uploadImage(presignedUrl: string, img: File) {
    return apiService.put(presignedUrl, {}, { 'Content-Type': img.type }, img);
  }
}

const imageUploadService = new ImageUploadService();

export { ImageUploadService, imageUploadService };
