import { AxiosPromise } from 'axios';
import { dateUtils } from '../../utils/dateUtils';
import { apiUrl, apiService } from '../apiService';
import PageableType from '../utils/pageableType';
import {
  ArchivedReportDto,
  ArchivedReportFilterDto,
  CashDonationDto,
  NewCashDonationDto,
  OrderDto,
  OrderFilterDto,
  RefundRequestDto,
  RefundRequestFilterDto,
  RefundRequestUpdateDto,
  ReportStartDateDto,
} from './orderService.dto';

class OrderService {
  BASE_URL = `${apiUrl}/api/order`;

  fetchReportStartDate(): AxiosPromise<ReportStartDateDto> {
    const url = `${this.BASE_URL}/admin/account/payouts/report-start-day`;
    return apiService.get(url);
  }

  fetchArchivedReports(filter: ArchivedReportFilterDto): AxiosPromise<PageableType<ArchivedReportDto>> {
    const url = `${this.BASE_URL}/admin/account/payouts/archived-reports`;
    return apiService.get(url, filter);
  }

  fetchArchivedReport(id: number): AxiosPromise<Blob> {
    const url = `${this.BASE_URL}/admin/account/payouts/reports/${id}`;
    return apiService.get(url, {}, {}, 'blob');
  }

  generateReport(startDay: Date, endDay: Date): AxiosPromise<Blob> {
    const url = `${this.BASE_URL}/admin/account/payouts-report-generation`;
    const data = { reportStartDay: dateUtils.toLocalDate(startDay), reportEndDay: dateUtils.toLocalDate(endDay) };
    return apiService.post(url, {}, {}, data, 'blob');
  }

  fetchConfirmedOrders(itemId: number, buyerId: number): AxiosPromise<PageableType<OrderDto>> {
    const url = `${this.BASE_URL}/admin/orders`;
    return apiService.get(url, { itemId, buyerId, status: 'CONFIRMED' });
  }

  fetchOrders(filter: OrderFilterDto): AxiosPromise<PageableType<OrderDto>> {
    const url = `${this.BASE_URL}/admin/orders`;
    return apiService.get(url, filter);
  }

  cancelOrder(id: number) {
    const url = `${this.BASE_URL}/admin/orders/${id}`;
    return apiService.patch(url, {}, {}, { status: 'CANCELED' });
  }

  fetchRefundRequests(filter: RefundRequestFilterDto): AxiosPromise<PageableType<RefundRequestDto>> {
    const url = `${this.BASE_URL}/admin/refund-requests`;
    return apiService.get(url, filter);
  }

  updateRefundRequest(id: number, data: RefundRequestUpdateDto) {
    const url = `${this.BASE_URL}/admin/refund-requests/${id}`;
    return apiService.patch(url, {}, {}, data);
  }

  createCashDonation(data: NewCashDonationDto): AxiosPromise<CashDonationDto> {
    const url = `${this.BASE_URL}/admin/donations`;
    return apiService.post(url, {}, {}, data);
  }
}

const orderService = new OrderService();

export { orderService, OrderService };
