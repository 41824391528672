import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DropdownWithLabel from '../../inputs/DropdownWithLabel';
import DateInputWithLabel, { DateTimePickerFormat } from '../../inputs/DateInputWithLabel';
import InputWithLabel from '../../inputs/InputWithLabel';
import RectangularButton from '../../buttons/RectangularButton';
import { theme } from '../../../assets/styles/theme';
import CheckboxInputWithLabel from '../../inputs/CheckboxInputWithLabel';
import MultiChoiceWithLabel from '../../inputs/MultiChoiceWithLabel';
import RangeInputWithLabel from '../../inputs/RangeInputWithLabel';

const Container = styled.div`
  flex-direction: row;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const FilterSection = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
`;

const SearchSection = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const ApplyButton = styled(RectangularButton)`
  min-width: 80px;
  font-weight: normal;
  height: 30px;
  margin: 5px;
`;

const ApplySearchButton = styled(IconButton)`
  width: 30px;
  height: 30px;

  & .MuiSvgIcon-root {
    margin: 0;
    height: 30px;
    width: 30px;
    padding: 2px;
    background-color: ${theme.color.red};
    color: ${theme.color.white};
    border-radius: 15px;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export type TableFilter =
  | {
      type: 'dropdown';
      name: string;
      label: string;
      options?: string[];
      booleanMap?: Map<string, string>;
      hideEmptyOption?: boolean;
    }
  | { type: 'date'; name: string; label: string }
  | { type: 'search'; name: string; label: string }
  | { type: 'text'; name: string; label: string }
  | { type: 'numeric'; name: string; label: string }
  | { type: 'range'; name: string; label: string; min?: number; max: number }
  | {
      type: 'checkbox';
      name: string;
      label: string;
      checked: boolean;
      onChange?: (name: string, value: boolean) => CheckboxFilter[];
    }
  | {
      type: 'multi-choice';
      name: string;
      label: string;
      options: string[];
    };

export interface CheckboxFilter {
  name: string;
  checked: boolean;
}

interface Props {
  onApply: (filterData: Map<string, any>) => void;
  filters: TableFilter[];
  filterInitState?: Map<string, any>;
  hideApplyButton?: boolean;
  refreshOnChange?: boolean;
}

const FiltersPanel = (props: Props) => {
  const { t } = useTranslation();

  const [filtersState, setFiltersState] = useState(new Map<string, any>());

  useEffect(() => {
    const initMap = props.filterInitState ? props.filterInitState : filtersState;
    const checkboxes = props.filters.filter(filter => filter.type === 'checkbox');
    checkboxes.map(checkbox => checkbox.type === 'checkbox' && initMap.set(checkbox.name, checkbox.checked));
    setFiltersState(new Map(initMap));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = (name: string, value: any) => {
    setFiltersState(new Map(filtersState.set(name, sanitizeValue(value))));
    if (props.refreshOnChange) {
      handleApply();
    }
  };

  const sanitizeValue = (value: any) => {
    if (value === '') {
      return null;
    } else {
      return value;
    }
  };

  const handleApply = () => {
    props.onApply(filtersState);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
      handleApply();
    }
  };

  return (
    <Container onKeyDown={handleKeyPress}>
      <FilterSection>
        {props.filters
          .filter(filter => filter.type !== 'search')
          .map(filter => {
            if (filter.type === 'dropdown') {
              return (
                <DropdownWithLabel
                  key={filter.name}
                  label={filter.label}
                  value={filtersState.get(filter.name)}
                  onChange={value => handleFilterChange(filter.name, value)}
                  options={filter.options}
                  optionsMap={filter.booleanMap}
                  hideEmptyOption={filter.hideEmptyOption}
                />
              );
            } else if (filter.type === 'date') {
              return (
                <DateInputWithLabel
                  key={filter.name}
                  label={filter.label}
                  value={filtersState.get(filter.name)}
                  onChange={value => handleFilterChange(filter.name, value)}
                  pickerFormat={DateTimePickerFormat.DATE}
                />
              );
            } else if (filter.type === 'text') {
              return (
                <InputWithLabel
                  key={filter.name}
                  label={filter.label}
                  value={filtersState.get(filter.name) || ''}
                  onChange={value => handleFilterChange(filter.name, value)}
                />
              );
            } else if (filter.type === 'numeric') {
              return (
                <InputWithLabel
                  key={filter.name}
                  label={filter.label}
                  value={filtersState.get(filter.name) || ''}
                  maxWidth={100}
                  inputMode={'number'}
                  onChange={value => handleFilterChange(filter.name, value)}
                />
              );
            } else if (filter.type === 'checkbox') {
              return (
                <CheckboxInputWithLabel
                  key={filter.name}
                  label={filter.label}
                  checked={filtersState.get(filter.name) || false}
                  onChange={value => {
                    if (filter.onChange) {
                      const checkboxesState = filter.onChange(filter.name, value);
                      checkboxesState.map(checkbox => handleFilterChange(checkbox.name, checkbox.checked));
                    }
                    handleFilterChange(filter.name, value);
                  }}
                />
              );
            } else if (filter.type === 'range') {
              return (
                <RangeInputWithLabel
                  key={filter.name}
                  label={filter.label}
                  min={filter.min}
                  max={filter.max}
                  onChange={value => {
                    handleFilterChange(filter.name, value);
                  }}
                />
              );
            } else if (filter.type === 'multi-choice') {
              return (
                <MultiChoiceWithLabel
                  key={filter.name}
                  label={filter.label}
                  options={filter.options}
                  values={filtersState.get(filter.name) || []}
                  onChange={value => handleFilterChange(filter.name, value)}
                />
              );
            } else throw Error('Unknown filter type');
          })}
        {!props.hideApplyButton && <ApplyButton label={t('common.apply')} onClick={handleApply} />}
      </FilterSection>
      {props.filters
        .filter(filter => filter.type === 'search')
        .map(filter => {
          return (
            <SearchSection key={filter.name}>
              <InputWithLabel
                label={filter.label}
                value={filtersState.get(filter.name) || ''}
                onChange={value => handleFilterChange(filter.name, value)}
                icon={<SearchIcon />}
              />
              <ApplySearchButton
                style={{ visibility: filtersState.get(filter.name) ? 'visible' : 'hidden' }}
                onClick={handleApply}>
                <KeyboardArrowRight />
              </ApplySearchButton>
            </SearchSection>
          );
        })}
    </Container>
  );
};

export default FiltersPanel;
