import { AggregatedShipmentCostsDto } from '../../../services/Shipment/shipmentService.dto';
import { dateUtils } from '../../../utils/dateUtils';

export const sortDesc = (aggregatedCosts: AggregatedShipmentCostsDto[]) => {
  return aggregatedCosts.sort((a, b) => parseInt(b.period) - parseInt(a.period));
};

export const convertPeriod = (aggregatedCosts: string) => {
  const year = parseInt(aggregatedCosts.substring(0, 4));
  const month = parseInt(aggregatedCosts.substring(4)) - 1;
  return dateUtils.formatMonthYear(new Date(year, month));
};

export const filterAggregatedShipmentCosts = (
  aggregatedCosts: AggregatedShipmentCostsDto[],
  from: Date | null,
  to: Date | null
) => {
  if (from) {
    const fromPeriod = periodFromDate(from);
    aggregatedCosts = aggregatedCosts.filter(costs => parseInt(costs.period) >= parseInt(fromPeriod));
  }
  if (to) {
    const toPeriod = periodFromDate(to);
    aggregatedCosts = aggregatedCosts.filter(costs => parseInt(costs.period) <= parseInt(toPeriod));
  }

  return aggregatedCosts;
};

const periodFromDate = (date: Date) => {
  let month = (date.getMonth() + 1).toString();
  if (month.length === 1) month = '0' + month;
  return date.getFullYear().toString() + month;
};
