import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DateInputWithLabel, { DateTimePickerFormat } from '../inputs/DateInputWithLabel';
import { theme } from '../../assets/styles/theme';
import RectangularButton from '../buttons/RectangularButton';

const FilterContainer = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;

const ApplyButton = styled(RectangularButton)`
  min-width: 80px;
  font-weight: normal;
  height: 30px;
  margin: 5px;
`;

interface Props {
  setFrom: (value: Date | null) => void;
  from: Date | null | undefined;
  setTo: (value: Date | null) => void;
  to: Date | null | undefined;
  onApply: () => void;
}

const ShipmentCostFilters = (props: Props) => {
  const { t } = useTranslation();
  return (
    <FilterContainer>
      <DateInputWithLabel
        label={t('common.date-from')}
        onChange={props.setFrom}
        value={props.from}
        maxDate={props.to!}
        pickerFormat={DateTimePickerFormat.MONTH_YEAR}
      />
      <DateInputWithLabel
        label={t('common.date-to')}
        onChange={props.setTo}
        value={props.to}
        minDate={props.from!}
        maxDate={new Date()}
        pickerFormat={DateTimePickerFormat.MONTH_YEAR}
      />
      <ApplyButton label={t('common.apply')} onClick={props.onApply} />
    </FilterContainer>
  );
};

export default ShipmentCostFilters;
