import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ImageContainer = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LargeImage = styled.img`
  width: 80%;
  margin: auto;
  border-radius: 12px;
  display: flex;
  aspect-ratio: 1;
  object-fit: cover;
`;

export const DetailsContainer = styled.div`
  width: 55%;
  height: 90%;
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  align-items: baseline;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`;

export const MiniImageContainer = styled.div`
  margin: auto;
`;

export const MiniImage = styled.img`
  max-width: 50px;
  max-height: 50px;
  margin: 5px;
  border-radius: 12px;
  cursor: pointer;
  object-fit: cover;
`;
