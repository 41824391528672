import React from 'react';
import styled from 'styled-components';
import { SwipeableDrawer } from '@mui/material';
import ActionsList from './ActionsList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 250px;
`;

interface Props {
  toggleDrawer: () => void;
  isVisible: boolean;
}

const ActionsDrawer = (props: Props) => {
  const { isVisible, toggleDrawer } = props;

  return (
    <SwipeableDrawer onClose={toggleDrawer} onOpen={toggleDrawer} open={isVisible} anchor={'right'}>
      <Container>
        <ActionsList />
      </Container>
    </SwipeableDrawer>
  );
};

export default ActionsDrawer;
