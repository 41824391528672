import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputWithLabel from '../inputs/InputWithLabel';
import closeIcon from '../../assets/svg/close.svg';
import { ConfirmButton, CloseButton } from '../buttons/popoverButtons';
import { FormHeader } from '../typography/PopoverTypography';
import { CampaignAdminDto } from '../../services/Campaign/campaignService.dto';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 320px;
`;

interface Props {
  anchor: HTMLDivElement | null;
  campaignData: CampaignAdminDto;
  onClosePressed: () => void;
  onConfirmPress: (campaignId: number, adminSortingBoost: number) => void;
}

const SortingBoostPopover = ({ anchor, campaignData, onConfirmPress, onClosePressed }: Props) => {
  const { t } = useTranslation();
  const [adminSortingBoost, setAdminSortingBoost] = useState(campaignData.adminSortingBoost.toString());

  const handleConfirmPress = () => {
    onConfirmPress(campaignData.id, Number(adminSortingBoost));
  };
  return (
    <Popover
      anchorEl={anchor}
      open={anchor !== null}
      onClose={onClosePressed}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
      <FormContainer>
        <FormHeader>{t('campaigns.sorting-boost-popover.header-label')}</FormHeader>
        <CloseButton src={closeIcon} alt={'close'} onClick={onClosePressed} />
        <InputWithLabel
          label={t('campaigns.sorting-boost-popover.input-label')}
          onChange={setAdminSortingBoost}
          value={adminSortingBoost}
          minWidth={200}
        />
        <ConfirmButton label={t('campaigns.sorting-boost-popover.confirm-label')} onClick={handleConfirmPress} />
      </FormContainer>
    </Popover>
  );
};

export default SortingBoostPopover;
