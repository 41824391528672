import numbro from 'numbro';
import { TableFilterStateDto } from '../../../components/common/table/DataTable';
import { ReportFilterDto } from '../../../services/Report/reportService.dto';
import { dateUtils } from '../../../utils/dateUtils';

export const convertFiltersToReportFilterDto = (filter: TableFilterStateDto<Map<string, any>>): ReportFilterDto => {
  const searchedWordsList = filter.filtersMap?.get('searchValue')
    ? filter.filtersMap.get('searchValue').replaceAll(' ', ',')
    : null;

  const dateFrom =
    filter.filtersMap?.get('dateFrom') && dateUtils.toLocalDate(new Date(filter.filtersMap?.get('dateFrom')));

  const dateTo = filter.filtersMap?.get('dateTo') && dateUtils.toLocalDate(new Date(filter.filtersMap?.get('dateTo')));

  const hidden = filter.filtersMap?.get('hidden');
  const visible = filter.filtersMap?.get('visible');

  let hiddenFilter;
  if (!(hidden && visible)) {
    if (hidden) hiddenFilter = true;
    if (visible) hiddenFilter = false;
  }

  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : undefined,
    hidden: hiddenFilter,
    objectType: filter.filtersMap?.get('objectType'),
    searchedWords: searchedWordsList,
    reportDateFrom: dateFrom,
    reportDateTo: dateTo,
  };
};

export const SHORT_DESC_LENGTH = 28;
export const shortDescription = (data: string) => {
  try {
    if (data.length < SHORT_DESC_LENGTH) return data;

    const lastSpace = data.lastIndexOf(' ', SHORT_DESC_LENGTH);
    return lastSpace ? data.substr(0, lastSpace) + ' ...' : data.substr(0, SHORT_DESC_LENGTH) + ' ...';
  } catch (e) {
    return '';
  }
};

export const toCurrencyString = (amount: number, currencyCode?: string) => {
  try {
    // TODO set currency symbol depends on currencyCode
    return numbro(amount).formatCurrency({
      thousandSeparated: true,
      mantissa: 2,
    });
  } catch (e) {
    return '';
  }
};
