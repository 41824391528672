export interface ReportStartDateDto {
  reportStartDay: string;
}

export interface ArchivedReportDto {
  reportId: number;
  filename: string;
  generationDate: string;
}

export interface ArchivedReportFilterDto {
  page: number;
  size: number;
  sort?: string;
}

export const ORDER_TYPES = ['DONATION', 'PURCHASE'] as const;

export const ORDER_STATUSES = [
  'CREATED',
  'CONFIRMED',
  'AUTHORIZED',
  'CHARGED',
  'PAID',
  'PICKED_UP',
  'SHIPPABLE',
  'SHIPPED',
  'COMPLETED',
  'CANCELED',
  'FAILED',
] as const;

export type OrderType = (typeof ORDER_TYPES)[number];
export type OrderStatus = (typeof ORDER_STATUSES)[number];

export const cancelableOrders: OrderStatus[] = [
  'CONFIRMED',
  'AUTHORIZED',
  'CHARGED',
  'PAID',
  'PICKED_UP',
  'SHIPPABLE',
  'SHIPPED',
  'COMPLETED',
];

export interface OrderDto {
  id: number;
  itemId: number;
  campaignId: number;
  type: OrderType;
  status: OrderStatus;
  ownerId: number;
  buyerId: number;
  amountTotal: number;
  basePrice: number;
  shipmentPrice?: number;
  transactionFee: number;
  transactionFeeAddedAmount: number;
  currency: string;
  shipmentRequired: boolean;
  shipmentId?: number;
  payForOffer: boolean;
  cancellingUserId?: boolean;
  refundRequested: boolean;
  anonymous: boolean;
  created: Date;
}

export interface OrderFilterDto {
  page: number;
  size: number;
  sort?: string;
  id?: number;
  creationDateFrom?: Date;
  creationDateTo?: Date;
  orderType?: string;
  status?: string;
  basePriceFrom?: number;
  basePriceTo?: number;
  campaignId?: number;
  itemId?: number;
  buyerId?: number;
}

export const REFUND_REQUEST_STATUSES = ['TODO', 'IN_PROGRESS', 'ACCEPTED', 'REJECTED'] as const;
export type RefundRequestStatus = (typeof REFUND_REQUEST_STATUSES)[number];

export const editableRefundRequestStatuses: RefundRequestStatus[] = ['TODO', 'IN_PROGRESS'];

export interface RefundRequestDto {
  id: number;
  orderId: number;
  refundReason: string;
  status: RefundRequestStatus;
  notes?: string;
  updatedBy?: number;
  creationDate: Date;
  updateDate: Date;
}

export interface RefundRequestFilterDto {
  page: number;
  size: number;
  sort?: string;
  orderId?: number;
  status?: string;
}

export interface RefundRequestUpdateDto {
  notes?: string;
  status: RefundRequestStatus;
}

export interface NewCashDonationDto {
  campaignId: number;
  userId: number;
  donationAmount: number;
  currency: string;
  anonymous: boolean;
}

export interface CashDonationDto {
  id: number;
  supportedCampaignId: number;
  orderType: OrderType;
  status: OrderStatus;
  ownerId: number;
  donorId: number;
  amountTotal: number;
  donationAmount: number;
  transactionFee: number;
  transactionFeeAddedAmount: number;
  currency: string;
  anonymous: boolean;
}

export interface CashDonationErrorResponseDto {
  code: 'USER_NOT_FOUND' | 'USER_NOT_VALID' | 'UNKNOWN_ERROR';
}
