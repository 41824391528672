export const convertToThumbnail300 = (imageUrl: string): string => {
  return thumbnails(imageUrl, 300);
};

export const convertToThumbnail600 = (imageUrl: string): string => {
  return thumbnails(imageUrl, 600);
};

const thumbnails = (imageUrl: string, width: number): string => {
  return `${imageUrl}_${width}`;
};
