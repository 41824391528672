import React from 'react';
import styled from 'styled-components';
import { UserDetailsDto } from '../../../services/User/userService.dto';
import { TextSmall } from '../../typography/Texts';
import { theme } from '../../../assets/styles/theme';
import ProfilePicture from '../../common/ProfilePicture';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background-color: ${theme.color.lightGray};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  user: UserDetailsDto;
  onClick: () => void;
}

const UsersListItem = ({ user, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <ProfilePicture imageUrl={user.profileImageUrl} />
      <InfoContainer>
        <TextSmall>
          [#{user.id}] {user.firstName} {user.lastName}
        </TextSmall>
        <TextSmall>{user.email}</TextSmall>
      </InfoContainer>
    </Container>
  );
};

export default UsersListItem;
