import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from './routes';
import { PrivateRoute } from './PrivateRoute';

const Navigation = () => {
  const routeComponents = routes.map(({ path, element, isPublic }, key) => {
    if (isPublic) return <Route path={path} element={element} key={key} />;
    else return <Route path={path} element={<PrivateRoute>{element}</PrivateRoute>} key={key} />;
  });
  return (
    <BrowserRouter>
      <Routes>{routeComponents}</Routes>
    </BrowserRouter>
  );
};

export default Navigation;
