import styled from 'styled-components';
import RectangularButton from './RectangularButton';

export const ConfirmButton = styled(RectangularButton)`
  min-width: 150px;
  font-weight: normal;
  height: 30px;
  margin: 5px;
`;

export const CloseButton = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 15px;
`;
