import React from 'react';
import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';
import Navbar from '../navbar/Navbar';

const MainContainer = styled.section`
  position: relative;
  display: flex;
  height: 100vh;
  margin: 0 auto;
  padding-top: ${theme.navbarHeight}px;
`;

const PageContainer = ({ children }: any) => {
  return (
    <>
      <Navbar />
      <MainContainer>{children}</MainContainer>
    </>
  );
};

export default PageContainer;
