import { TableFilterStateDto } from '../../../components/common/table/DataTable';
import {
  CemeteryCountDto,
  CemeteryDto,
  CemeteryFilterDto,
  PartialMessageUpdateDto,
} from '../../../services/Maintenance/maintenanceService.dto';

export const convertFiltersToCemeteryFilterDto = (filter: TableFilterStateDto<Map<string, any>>): CemeteryFilterDto => {
  let deleted: boolean | undefined = false;
  let requeued: boolean | undefined = false;

  if (filter.filtersMap) {
    deleted = filter.filtersMap.get('deleted') ? filter.filtersMap.get('deleted') === 'true' : undefined;
    requeued = filter.filtersMap.get('requeued') ? filter.filtersMap.get('requeued') === 'true' : undefined;
  }

  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : undefined,
    deleted,
    requeued,
    firstDeathQueue: filter.filtersMap?.get('firstDeathSearch') ? filter.filtersMap.get('firstDeathSearch') : undefined,
    lastDeathQueue: filter.filtersMap?.get('lastDeathSearch') ? filter.filtersMap.get('lastDeathSearch') : undefined,
  };
};

export const toCemeteryCountDto = (count: number): CemeteryCountDto | undefined => {
  return count ? { count } : undefined;
};

export const toPartialMessageUpdateDto = (message: CemeteryDto): PartialMessageUpdateDto => {
  return {
    payload: JSON.parse(message.payload),
    requeuedRoutingKey: '',
    requeuedToExchange: '',
  };
};

export const getRoutingKeyAsString = (message: CemeteryDto) => {
  const xDeath: [] = JSON.parse(message.headers)['x-death'];
  return xDeath[xDeath.length - 1]['routing-keys'][0];
};
