import React, { useState } from 'react';
import { Popover } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CloseButton, ConfirmButton } from '../buttons/popoverButtons';
import { FormText } from '../typography/PopoverTypography';
import closeIcon from '../../assets/svg/close.svg';
import PasswordInputField from '../inputs/PasswordInputField';

const ButtonsWrapper = styled.div`
  flex-direction: row;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 350px;
`;

interface Props {
  anchor: HTMLButtonElement | null;
  onClosePressed: () => void;
  onContinuePressed: (recoveryPassword: string) => void;
}

const MIN_PASSWORD_LENGTH = 16;

const RecoveryPasswordPopover = (props: Props) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState<string>('');

  return (
    <Popover
      anchorEl={props.anchor}
      open={props.anchor !== null}
      onClose={props.onClosePressed}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <FormContainer>
        <CloseButton src={closeIcon} alt={'close'} onClick={props.onClosePressed} />
        <FormText>{t('maintenance.recovery.password-popover')}</FormText>
        <ButtonsWrapper>
          <PasswordInputField onChange={event => setPassword(event.target.value)} value={password} />
          <ConfirmButton
            label={t('common.confirm-btn')}
            onClick={() => props.onContinuePressed(password)}
            disabled={password.length < MIN_PASSWORD_LENGTH}
          />
        </ButtonsWrapper>
      </FormContainer>
    </Popover>
  );
};

export default RecoveryPasswordPopover;
