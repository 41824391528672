import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TransactionErrorInfoDto } from '../../services/Payment/paymentService.dto';
import { theme } from '../../assets/styles/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DetailHeader = styled.h3`
  color: ${theme.color.red};
`;

export const DetailDescription = styled.h4`
  color: ${theme.color.lightBlack};
  width: 80%;
  padding: 12px;
  overflow-wrap: anywhere;
`;

interface Props {
  errors: TransactionErrorInfoDto[];
}

const TransactionErrorsDetails = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      {props.errors.map(it => {
        return (
          <>
            <DetailContainer>
              <DetailHeader>{t('transactions.errors-popover.error-type')}</DetailHeader>
              <DetailDescription>
                {t(`transactions.error-type.${it.errorType}`, { defaultValue: '' })
                  ? `${it.errorType} (${t(`transactions.error-type.${it.errorType}`)})`
                  : it.errorType}
              </DetailDescription>
            </DetailContainer>
            <DetailContainer>
              <DetailHeader>{t('transactions.errors-popover.description')}</DetailHeader>
              <DetailDescription>{it.description}</DetailDescription>
            </DetailContainer>
            <DetailContainer>
              <DetailHeader>{t('transactions.errors-popover.error-date')}</DetailHeader>
              <DetailDescription>{it.errorDate}</DetailDescription>
            </DetailContainer>
          </>
        );
      })}
    </Container>
  );
};

export default TransactionErrorsDetails;
