import { AxiosPromise } from 'axios';
import { apiUrl, apiService } from '../apiService';
import PageableType from '../utils/pageableType';
import { SearchMessageDto } from './searchService.dto';

class SearchService {
  static BASE_URL = `${apiUrl}/api/search`;

  fetchMessages(
    content: string,
    status: string,
    size: number,
    supporterId?: number
  ): AxiosPromise<PageableType<SearchMessageDto>> {
    const url = `${SearchService.BASE_URL}/admin/support-messages`;
    return apiService.get(url, { content, status, size, supporterId });
  }
}

const searchService = new SearchService();

export { searchService, SearchService };
