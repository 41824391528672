import React from 'react';
import { Popover } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CredentialDto } from '../../services/Auth/authService.dto';
import { ConfirmButton } from '../buttons/popoverButtons';
import { FormHeader } from '../typography/PopoverTypography';

const FormContainer = styled.div`
  padding: 10px;
`;

const ButtonsContainer = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
`;

interface Props {
  anchor?: SVGSVGElement;
  credentials?: CredentialDto;
  grantAuthorities?: boolean;
  onClosePressed: () => void;
  onConfirm: (credentials?: CredentialDto) => void;
}

const AdminsPopup = (props: Props) => {
  const { t } = useTranslation();
  const username = props.credentials ? props.credentials.username : '';
  return (
    <Popover
      anchorEl={props.anchor}
      open={props.anchor !== undefined}
      onClose={props.onClosePressed}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
      <FormContainer>
        <FormHeader>
          {props.grantAuthorities ? t('users.grant-employee', { username }) : t('users.revoke-employee', { username })}
        </FormHeader>
        <ButtonsContainer>
          <ConfirmButton label={t('common.confirm-btn')} onClick={() => props.onConfirm(props.credentials)} />
          <ConfirmButton label={t('common.cancel')} onClick={props.onClosePressed} />
        </ButtonsContainer>
      </FormContainer>
    </Popover>
  );
};

export default AdminsPopup;
