import React from 'react';
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import PaidIcon from '@mui/icons-material/Paid';
import { useTranslation } from 'react-i18next';
import {
  editableRefundRequestStatuses,
  RefundRequestDto,
  RefundRequestStatus,
  RefundRequestUpdateDto,
} from '../../services/Order/orderService.dto';
import { usePopup } from '../../providers/PopupProvider';
import RefundRequestUpdateDialog from './RefundRequestUpdateDialog';

const Container = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-self: center;
`;

const StyledLink = styled.a`
  cursor: pointer;
  display: flex;
  justify-self: center;
  text-decoration: none;
  color: inherit;
`;

const iconStyle = {
  fontSize: 20,
  cursor: 'pointer',
};

interface Props {
  refundRequest: RefundRequestDto;
  onEditRefundRequest: (id: number, data: RefundRequestUpdateDto) => void;
}

const RefundRequestActions = ({ refundRequest, onEditRefundRequest }: Props) => {
  const { t } = useTranslation();
  const { showDialog, hideDialog } = usePopup();

  const isEditable = () => editableRefundRequestStatuses.includes(refundRequest.status);

  const showEditPopover = () => {
    const handleConfirm = (notes: string, status: RefundRequestStatus) => {
      onEditRefundRequest(refundRequest.id, { notes, status });
      hideDialog();
    };
    showDialog(
      <RefundRequestUpdateDialog
        refundRequest={refundRequest}
        onConfirmPress={handleConfirm}
        onClosePressed={hideDialog}
      />
    );
  };

  return (
    <Container>
      {isEditable() && (
        <StyledButton title={t('orders.edit-tooltip')} onClick={showEditPopover}>
          <EditIcon style={iconStyle} />
        </StyledButton>
      )}
      {refundRequest.status === 'ACCEPTED' && (
        <StyledLink
          title={t('orders.go-to-manual-refunds-tooltip')}
          href={`/payments/manual-refunds/${refundRequest.orderId}`}>
          <PaidIcon style={iconStyle} />
        </StyledLink>
      )}
    </Container>
  );
};

export default RefundRequestActions;
