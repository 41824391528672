import { PhoneDto } from '../services/User/userService.dto';

class PhoneUtils {
  convertToPhoneDto(phoneNumber: string): PhoneDto {
    const phoneNumberNumeric = phoneNumber.replace(/[^0-9]/g, '');
    return {
      countryCode: phoneNumberNumeric.substring(0, 1), // TODO
      areaCode: phoneNumberNumeric.substring(1, 4),
      number: phoneNumberNumeric.substring(4),
    };
  }

  convertToPhoneDisplayNumber(phone?: PhoneDto): string {
    return phone != null ? `${phone.countryCode}${phone.areaCode}${phone.number}` : '';
  }
}

export const phoneUtils = new PhoneUtils();
