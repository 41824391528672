import React, { useState } from 'react';
import { Popover } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FormHeader } from '../typography/PopoverTypography';
import { ConfirmButton } from '../buttons/popoverButtons';
import InputWithLabel from '../inputs/InputWithLabel';
import { removeNonNumberChar } from '../../utils/validationUtils';

const ButtonsWrapper = styled.div`
  flex-direction: row;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  width: 400px;
`;

interface Props {
  anchor: HTMLButtonElement | null;
  onClosePressed: () => void;
  onContinuePressed: (messageCount: number) => void;
}

const CemeteryPullPopover = (props: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('');

  const setOnlyIfNumber = (val: string) => {
    setValue(removeNonNumberChar(val));
  };

  const valueToNumber = () => {
    props.onContinuePressed(parseInt(value));
  };

  return (
    <Popover
      anchorEl={props.anchor}
      open={props.anchor !== null}
      onClose={props.onClosePressed}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <FormContainer>
        <FormHeader>{t('maintenance.cemetery.popover.header-label')}</FormHeader>
        <InputWithLabel
          label={t('maintenance.cemetery.popover.count-input')}
          onChange={setOnlyIfNumber}
          value={value}
        />
        <ButtonsWrapper>
          <ConfirmButton label={t('common.confirm-btn')} onClick={valueToNumber} disabled={value === '0'} />
          <ConfirmButton label={t('common.cancel')} onClick={props.onClosePressed} />
        </ButtonsWrapper>
      </FormContainer>
    </Popover>
  );
};

export default CemeteryPullPopover;
