import styled from 'styled-components';
import { TextMain, TextSmall } from './Texts';

export const FormHeader = styled(TextMain)`
  width: 100%;
  padding: 5px 25px 5px 10px;
`;

export const FormText = styled(TextSmall)`
  width: 100%;
  padding: 5px 25px 5px 10px;
`;
