import React, { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../components/common/PageContainer';
import { theme } from '../../assets/styles/theme';
import { Column, TableFilterStateDto } from '../../components/common/table/DataTable';
import DataTableWithFilters from '../../components/common/table/DataTableWithFilters';
import { TableFilter } from '../../components/common/table/FiltersPanel';
import { DataTableRef } from '../../components/common/table/dataTableRef';
import { orderService } from '../../services/Order/orderService';
import { ORDER_STATUSES, OrderDto } from '../../services/Order/orderService.dto';
import { dateUtils } from '../../utils/dateUtils';
import UserDetailsPopover from '../../components/users/UserDetailsPopover';
import CellWithPopover from '../../components/common/table/CellWithPopover';
import CampaignDetailsPopover from '../../components/campaign/CampaignDetailsPopover';
import ItemDetailsPopover from '../../components/item/ItemDetailsPopover';
import OrderActions from '../../components/orders/OrderActions';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import { messageService } from '../../services/Message/messageService';
import { MESSAGES_TAB_ONGOING_PATH } from '../../providers/messages/messagesPaths';
import { convertFiltersToOrderFilterDto } from './utils/filterConverter';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

type UrlParams = {
  orderId: string;
};

const Orders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleError } = useGlobalError();
  const dataTableRef = useRef<DataTableRef>(null);
  const { orderId } = useParams<UrlParams>();

  const columns: Column<OrderDto>[] = [
    {
      id: 'id',
      title: t('orders.columns.id'),
      isSortable: true,
      maxWidth: 20,
      align: 'center',
      renderCell: data => data.id,
    },
    {
      id: 'created',
      title: t('orders.columns.created'),
      isSortable: true,
      minWidth: 60,
      maxWidth: 60,
      align: 'center',
      renderCell: data => dateUtils.formatDateDate(new Date(data.created)),
    },
    {
      id: 'orderType',
      title: t('orders.columns.type'),
      maxWidth: 40,
      isSortable: true,
      renderCell: data => data.type,
    },
    {
      id: 'status',
      title: t('orders.columns.status'),
      maxWidth: 40,
      isSortable: true,
      renderCell: data => data.status,
    },
    {
      id: 'basePrice',
      title: t('orders.columns.base-price'),
      maxWidth: 35,
      isSortable: true,
      renderCell: data => data.basePrice,
    },
    {
      id: 'shipmentPrice',
      title: t('orders.columns.shipment-price'),
      maxWidth: 35,
      isSortable: true,
      renderCell: data => data.shipmentPrice,
    },
    {
      id: 'transactionFee',
      title: t('orders.columns.transaction-fee'),
      maxWidth: 35,
      isSortable: true,
      renderCell: data => data.transactionFee,
    },
    {
      id: 'transactionFeeAddedAmount',
      title: t('orders.columns.transaction-fee-added-amount'),
      maxWidth: 35,
      isSortable: true,
      renderCell: data => data.transactionFeeAddedAmount,
    },
    {
      id: 'amountTotal',
      title: t('orders.columns.total-price'),
      maxWidth: 35,
      isSortable: true,
      renderCell: data => data.amountTotal,
    },
    {
      id: 'currency',
      title: t('orders.columns.currency'),
      maxWidth: 30,
      isSortable: true,
      renderCell: data => data.currency,
    },
    {
      id: 'campaignId',
      title: t('orders.columns.campaign-id'),
      maxWidth: 30,
      isSortable: true,
      renderCell: data => (
        <CellWithPopover popover={<CampaignDetailsPopover campaignId={data.campaignId} />}>
          {data.campaignId}
        </CellWithPopover>
      ),
    },
    {
      id: 'itemId',
      title: t('orders.columns.item-id'),
      maxWidth: 30,
      isSortable: true,
      renderCell: data =>
        data.itemId && (
          <CellWithPopover popover={<ItemDetailsPopover itemId={data.itemId} />}>{data.itemId}</CellWithPopover>
        ),
    },
    {
      id: 'buyerId',
      title: t('orders.columns.buyer-id'),
      maxWidth: 30,
      isSortable: true,
      renderCell: data => (
        <CellWithPopover popover={<UserDetailsPopover userId={data.buyerId} />}>{data.buyerId}</CellWithPopover>
      ),
    },
    {
      id: 'shipmentId',
      title: t('orders.columns.shipment-id'),
      maxWidth: 30,
      isSortable: true,
      renderCell: data => data.shipmentId,
    },
    {
      id: 'actions',
      title: t('orders.columns.actions'),
      align: 'center',
      maxWidth: 30,
      renderCell: data => (
        <OrderActions order={data} onCancelOrder={handleCancelOrder} onContactUser={handleContactUser} />
      ),
    },
  ];

  const filters: TableFilter[] = [
    { type: 'numeric', name: 'id', label: i18n.t('orders.filters.id') },
    { type: 'date', name: 'date', label: i18n.t('orders.filters.date') },
    {
      type: 'multi-choice',
      name: 'type',
      options: ['PURCHASE', 'DONATION'],
      label: i18n.t('orders.filters.type'),
    },
    {
      type: 'multi-choice',
      name: 'status',
      options: [...ORDER_STATUSES],
      label: i18n.t('orders.filters.status'),
    },
    { type: 'numeric', name: 'basePriceFrom', label: i18n.t('orders.filters.base-price-from') },
    { type: 'numeric', name: 'basePriceTo', label: i18n.t('orders.filters.base-price-to') },
    { type: 'numeric', name: 'campaignId', label: i18n.t('orders.filters.campaign-id') },
    { type: 'numeric', name: 'itemId', label: i18n.t('orders.filters.item-id') },
    { type: 'numeric', name: 'buyerId', label: i18n.t('orders.filters.buyer-id') },
  ];

  const handleCancelOrder = (orderId: number) => {
    dataTableRef.current?.refresh();
    orderService
      .cancelOrder(orderId)
      .then(() => dataTableRef.current?.refresh())
      .catch(e => handleError(e));
  };

  const handleContactUser = (userId: number) => {
    messageService.createConversation(userId).then(({ data }) => navigate(`${MESSAGES_TAB_ONGOING_PATH}/${data.id}`));
  };

  const handleTableStateChange = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const filters = convertFiltersToOrderFilterDto(tableState);
    return orderService.fetchOrders(filters).then(response => response.data);
  }, []);

  const filterInitialState = useMemo(() => {
    const orderIdFromPath = orderId ? parseInt(orderId) : undefined;
    if (orderIdFromPath && Number.isInteger(orderIdFromPath)) {
      return new Map([['id', orderIdFromPath]]);
    } else {
      return new Map();
    }
  }, [orderId]);

  return (
    <PageContainer>
      <Container>
        <DataTableWithFilters
          ref={dataTableRef}
          columns={columns}
          filters={filters}
          filterInitState={filterInitialState}
          onTableStateChanged={handleTableStateChange}
        />
      </Container>
    </PageContainer>
  );
};

export default Orders;
