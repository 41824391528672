import { AxiosPromise } from 'axios';
import { apiUrl, apiService } from '../apiService';
import PageableType from '../utils/pageableType';
import { ReportDto, ReportFilterDto } from './reportService.dto';

class ReportService {
  static BASE_URL = `${apiUrl}/api/report`;

  fetchReport(filter: ReportFilterDto): AxiosPromise<PageableType<ReportDto>> {
    const url = `${ReportService.BASE_URL}/admin/reports`;
    return apiService.get(url, { ...filter });
  }

  clearUserData(objectId: number, objectType: string, comment?: string) {
    const url = `${ReportService.BASE_URL}/admin/object-data-clearing`;
    return apiService.post(url, {}, {}, { objectId, objectType, comment });
  }

  hideObject(objectId: number, objectType: string, comment?: string) {
    const url = `${ReportService.BASE_URL}/admin/object-hiding`;
    return apiService.post(url, {}, {}, { objectId, objectType, comment });
  }

  ignoreReport(objectId: number, objectType: string, comment?: string) {
    const url = `${ReportService.BASE_URL}/admin/object-report-ignoring`;
    return apiService.post(url, {}, {}, { objectId, objectType, comment });
  }

  addComment(objectId: number, objectType: string, comment?: string) {
    const url = `${ReportService.BASE_URL}/admin/object-comment`;
    return apiService.post(url, {}, {}, { objectId, objectType, comment });
  }
}

const reportService = new ReportService();

export { reportService, ReportService };
