export const theme = {
  font: {
    family: {
      main: 'SFProText, sans-serif',
      title: 'SFProDisplay, serif',
    },
    size: {
      xs: '1.4rem',
      s: '1.6rem',
      m: '2.4rem',
      l: '3.6rem',
      xl: '7.2rem',
    },
    weight: {
      light: '300',
      regular: '400',
      bold: '700',
    },
  },
  color: {
    white: '#fff',
    lightBlack: '#24253d',
    brightBlue: '#21B6F7',
    vividBlue: '#3389FF',
    blue: '#40629F',
    disabled: '#c2cacc',
    sea: '#AFBED3',
    black: '#000',
    shadow: '#455154',
    lightRed: '#ffeaeb',
    red: '#fc686f',
    darkRed: '#FC0000',
    green: '#57B83A',
    lightBlue: '#d9eff5',
    whiteSmoke: '#e9e9e9',
    lighterGray: '#f4f6f6',
    lightGray: '#e4e4e7',
    gray: '#E8E8E8',
    middleGray: '#D8D8D8',
    darkGray: '#c4cacc',
    silver: '#bbbbbb',
    darkerGray: '#969fa2',
    lighterBlue: '#eff1f3',
    lightCyan: '#F8FBFF',
    transparent: 'transparent',
  },
  zIndex: {
    specialCase: 99999,
    notification: 9999,
    modal: 999,
    fullScreenFeatures: 99,
    primaryNav: 7,
    tooltip: 6,
    header: 5,
    secondaryNav: 4,
    dropdown: 3,
    overlay: 2,
    default: 1,
    under: -1,
  },
  mq: {
    phone: `@media (min-width: 576px)`,
    tablet: `@media (min-width: 768px)`,
    desktop: `@media (min-width: 1024px)`,
    huge: `@media(min-width: 1440px)`,
  },
  navbarHeight: 60,
  navbarPadding: 90,
  paddingStandard: 15,
  borderRadiusStandard: 6,
};
