import { AxiosPromise } from 'axios';
import { apiUrl, apiService } from '../apiService';
import PageableType from '../utils/pageableType';
import {
  AggregatedShipmentCostsDto,
  FailedShipmentDto,
  FailedShipmentFilterDto,
  ShipmentCostsFilterDto,
  ShipmentCostReportDto,
  ShipmentReprocessingDto,
} from './shipmentService.dto';

class ShipmentService {
  BASE_URL = `${apiUrl}/api/shipment`;

  fetchFailedShipments(filter: FailedShipmentFilterDto): AxiosPromise<PageableType<FailedShipmentDto>> {
    const url = `${this.BASE_URL}/admin/failed-shipments`;
    return apiService.get(url, filter);
  }

  reprocessFailedShipment(id: number): AxiosPromise<ShipmentReprocessingDto> {
    const url = `${this.BASE_URL}/admin/shipments/${id}`;
    return apiService.patch(url, {}, {}, { shipmentStatus: 'SHIPPED' });
  }

  fetchAggregatedShipmentCosts(): AxiosPromise<AggregatedShipmentCostsDto[]> {
    const url = `${this.BASE_URL}/admin/aggregated-shipment-costs`;
    return apiService.get(url);
  }

  fetchShipmentCostsReport(filter: ShipmentCostsFilterDto): AxiosPromise<PageableType<ShipmentCostReportDto>> {
    const url = `${this.BASE_URL}/admin/shipment-costs`;
    return apiService.get(url, filter);
  }

  uploadGeolocationData(file: File, countryCode: string) {
    const url = `${this.BASE_URL}/admin/geolocation`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('countryCode', countryCode);

    return apiService.post(
      url,
      {},
      {
        'Content-Type': 'multipart/form-data',
      },
      formData
    );
  }
}

const shipmentService = new ShipmentService();

export { shipmentService, ShipmentService };
