import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';

const Container = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  display: flex;
  background-color: ${theme.color.lighterGray};
  font-family: ${theme.font.family.main};
  font-size: ${theme.font.size.s};
  line-height: 2.2rem;
  letter-spacing: -0.4px;
  align-items: center;
  border: 0;
  outline: 0;
  flex: 1;
  border-radius: 22px;
  padding: 10px 10px 10px 30px;
`;

const StyledIcon = styled(SearchIcon)`
  position: absolute;
  left: 10px;
`;

const ClearBtn = styled(HighlightOffIcon)`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

interface Props {
  onChange: (value: string) => void;
  value: string;
}

const SearchInput = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <StyledIcon />
      <StyledInput
        value={props.value}
        placeholder={t('common.search')}
        onChange={e => props.onChange(e.target.value)}
      />
      {props.value !== '' && <ClearBtn onClick={() => props.onChange('')} />}
    </Container>
  );
};

export default SearchInput;
