import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TextMain } from '../../typography/Texts';
import { useMessages } from '../../../providers/messages/MessagesProvider';
import MessageThreadHeader from './MessageThreadHeader';
import MessageChat from './MessageChat';
import MessageInput from './MessageInput';
import SearchMessagesList from './SearchMessagesList';

const Container = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  margin: 10px 0;
  position: relative;
`;

const ErrorMsgWrapper = styled(TextMain)`
  margin: 10px auto;
`;

interface Props {
  conversationId?: number;
  onCloseConversation: () => void;
  searchValue: string;
}

const MessageThread = (props: Props) => {
  const { fetchConversation, conversation, selectedMessageId, conversationErrorMsg } = useMessages();

  // TODO
  const shouldRenderSearchResults = () => {
    if (selectedMessageId) return false;
    if (conversation?.searchMessages && conversation.searchMessages.length > 1) return true;
    return false;
  };

  useEffect(() => {
    fetchConversation(props.conversationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.conversationId]);

  if (conversationErrorMsg) return <ErrorMsgWrapper>{conversationErrorMsg}</ErrorMsgWrapper>;
  if (!conversation) return <></>;
  return (
    <Container>
      <MessageThreadHeader conversation={conversation} onCloseConversation={props.onCloseConversation} />
      {shouldRenderSearchResults() && (
        <SearchMessagesList conversation={conversation} searchValue={props.searchValue} />
      )}
      {!shouldRenderSearchResults() && (
        <>
          <MessageChat />
          <MessageInput />
        </>
      )}
    </Container>
  );
};

export default MessageThread;
