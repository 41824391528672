import React, { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DataTableRef } from '../../components/common/table/dataTableRef';
import DataTable, { Column, TableFilterStateDto } from '../../components/common/table/DataTable';
import { ShipmentCostReportDto } from '../../services/Shipment/shipmentService.dto';
import { shipmentService } from '../../services/Shipment/shipmentService';
import PageContainer from '../../components/common/PageContainer';
import { theme } from '../../assets/styles/theme';
import { dateUtils } from '../../utils/dateUtils';
import { DEFAULT_CURRENCY } from '../../components/payment/payment.types';

const Container = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  display: flex;
  width: 100%;
`;

type UrlParams = {
  period: string;
};

const ShipmentCosts = () => {
  const { period } = useParams<UrlParams>();
  const { t } = useTranslation();
  const dataTableRef = useRef<DataTableRef>(null);

  const columns: Column<ShipmentCostReportDto>[] = [
    {
      id: 'id',
      title: t('shipment-costs.columns.id'),
      align: 'center',
      renderCell: data => data.id,
    },
    {
      id: 'deliveryStartDate',
      title: t('shipment-costs.columns.date'),
      isSortable: true,
      align: 'center',
      renderCell: data => dateUtils.formatDate(new Date(data.deliveryStartDate)),
    },
    {
      id: 'senderName',
      title: t('shipment-costs.columns.sender-name'),
      align: 'center',
      renderCell: data => `${data.senderAddress.firstName} ${data.senderAddress.lastName}`,
    },
    {
      id: 'senderAddress',
      title: t('shipment-costs.columns.sender-address'),
      align: 'center',
      renderCell: data => `${data.senderAddress.addressLine1} ${data.senderAddress.addressLine2}`,
    },
    {
      id: 'senderZip',
      title: t('shipment-costs.columns.sender-zip'),
      align: 'center',
      renderCell: data => data.senderAddress.postalCode,
    },
    {
      id: 'receiverName',
      title: t('shipment-costs.columns.receiver-name'),
      align: 'center',
      renderCell: data => `${data.receiverAddress.firstName} ${data.receiverAddress.lastName}`,
    },
    {
      id: 'receiverAddress',
      title: t('shipment-costs.columns.receiver-address'),
      align: 'center',
      renderCell: data => `${data.receiverAddress.addressLine1} ${data.receiverAddress.addressLine2}`,
    },
    {
      id: 'receiverZip',
      title: t('shipment-costs.columns.receiver-zip'),
      align: 'center',
      renderCell: data => data.receiverAddress.postalCode,
    },
    {
      id: 'carrier',
      title: t('shipment-costs.columns.carrier'),
      align: 'center',
      renderCell: data => data.carrier,
    },
    {
      id: 'trackingNumber',
      title: t('shipment-costs.columns.tracking-number'),
      align: 'center',
      renderCell: data => data.trackingNumber,
    },
    {
      id: 'customerShipmentPrice',
      title: t('shipment-costs.columns.charged'),
      align: 'center',
      renderCell: data =>
        `${data.customerShipmentPrice.toFixed(2)} ${
          data.customerShipmentPriceCurrency ? data.customerShipmentPriceCurrency : DEFAULT_CURRENCY
        }`,
    },
    {
      id: 'totalCharge',
      title: t('shipment-costs.columns.cost'),
      align: 'center',
      renderCell: data => `${data.totalCharge.toFixed(2)} ${DEFAULT_CURRENCY}`,
    },
  ];

  const onTableStateChange = useCallback((state: TableFilterStateDto<any>) => {
    return shipmentService.fetchShipmentCostsReport(convertFilters(state)).then(response => response.data);
  }, []);

  const convertFilters = (filter: TableFilterStateDto<any>) => ({
    page: filter.page!,
    size: filter.pageSize!,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : undefined,
    reportPeriod: period!,
  });

  return (
    <PageContainer>
      <Container>
        <DataTable ref={dataTableRef} columns={columns} onTableStateChanged={onTableStateChange} />
      </Container>
    </PageContainer>
  );
};

export default ShipmentCosts;
