import React from 'react';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormHeader } from '../typography/PopoverTypography';
import { CloseButton, ConfirmButton } from '../buttons/popoverButtons';
import closeIcon from '../../assets/svg/close.svg';
import { FundraiserDetailsDto } from '../../services/User/userService.dto';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  width: 320px;
`;

interface Props {
  anchor: HTMLDivElement | null;
  fundraiser: FundraiserDetailsDto;
  onClosePressed: () => void;
  onConfirmPressed: (userId: number) => void;
}

const FundraiserVerificationPopover = ({ anchor, fundraiser, onConfirmPressed, onClosePressed }: Props) => {
  const { t } = useTranslation();

  return (
    <Popover
      anchorEl={anchor}
      open={anchor !== null}
      onClose={onClosePressed}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
      <FormContainer>
        <FormHeader>
          {t('users.fundraiser-verification-reminder', { username: `${fundraiser.firstName} ${fundraiser.lastName}` })}
        </FormHeader>
        <ConfirmButton label={t('users.fundraiser-verification-btn')} onClick={() => onConfirmPressed(fundraiser.id)} />
        <CloseButton src={closeIcon} alt={'close'} onClick={onClosePressed} />
      </FormContainer>
    </Popover>
  );
};

export default FundraiserVerificationPopover;
