import { Reducer, useReducer } from 'react';
import {
  ConversationStatus,
  ConversationWithDataDto,
  MessageAuditDto,
  MessageDto,
  MessageType,
} from '../../services/Message/messageService.dto';
import { UserPublicDto } from '../../services/User/userService.dto';
import i18n from '../../translations/config';

export enum MessagesActionTypes {
  MESSAGES_FETCH_REQUEST = 'MESSAGES_FETCH_REQUEST',
  MESSAGES_FETCH_SUCCESS = 'MESSAGES_FETCH_SUCCESS',
  MESSAGES_AFTER_FETCH_SUCCESS = 'MESSAGES_AFTER_FETCH_SUCCESS',
  MESSAGES_BEFORE_FETCH_SUCCESS = 'MESSAGES_BEFORE_FETCH_SUCCESS',
  MESSAGES_FETCH_ERROR = 'MESSAGES_FETCH_ERROR',
  MESSAGES_FETCH_CONVERSATION_REQUEST = 'MESSAGES_FETCH_CONVERSATION_REQUEST',
  MESSAGES_FETCH_CONVERSATION_SUCCESS = 'MESSAGES_FETCH_CONVERSATION_SUCCESS',
  MESSAGES_SELECT_CONVERSATION = 'MESSAGES_SELECT_CONVERSATION',
  MESSAGES_FETCH_CONVERSATION_ERROR = 'MESSAGES_FETCH_CONVERSATION_ERROR',
  MESSAGES_REFRESH_IMAGES = 'MESSAGES_REFRESH_IMAGES',
  MESSAGES_CONFIRM = 'MESSAGES_CONFIRM',
  MESSAGES_ADD = 'MESSAGES_ADD',
  MESSAGES_FETCH_AUDIT_SUCCESS = 'MESSAGES_FETCH_AUDIT_SUCCESS',
}

type Action =
  | { type: MessagesActionTypes.MESSAGES_FETCH_REQUEST; payload?: { resetMessages?: boolean } }
  | { type: MessagesActionTypes.MESSAGES_FETCH_SUCCESS; payload: MessageDto[] }
  | { type: MessagesActionTypes.MESSAGES_AFTER_FETCH_SUCCESS; payload: MessageDto[] }
  | {
      type: MessagesActionTypes.MESSAGES_BEFORE_FETCH_SUCCESS;
      payload: {
        messages: MessageDto[];
        isLastMessage?: boolean;
      };
    }
  | { type: MessagesActionTypes.MESSAGES_FETCH_ERROR; payload: string }
  | { type: MessagesActionTypes.MESSAGES_FETCH_CONVERSATION_REQUEST }
  | {
      type: MessagesActionTypes.MESSAGES_FETCH_CONVERSATION_SUCCESS;
      payload: {
        conversation: ConversationWithDataDto;
        user: UserPublicDto;
        supporters: UserPublicDto[];
      };
    }
  | {
      type: MessagesActionTypes.MESSAGES_SELECT_CONVERSATION;
      payload: {
        conversation?: ConversationWithDataDto;
        messageId?: number;
      };
    }
  | { type: MessagesActionTypes.MESSAGES_FETCH_CONVERSATION_ERROR; payload: string }
  | { type: MessagesActionTypes.MESSAGES_REFRESH_IMAGES; payload: MessageDto[] }
  | { type: MessagesActionTypes.MESSAGES_CONFIRM; payload: number[] }
  | { type: MessagesActionTypes.MESSAGES_ADD; payload: MessageDto }
  | {
      type: MessagesActionTypes.MESSAGES_FETCH_AUDIT_SUCCESS;
      payload: {
        messageAuditDtos: MessageAuditDto[];
        supporters: UserPublicDto[];
      };
    };

interface State {
  conversation?: ConversationWithDataDto;
  messages: MessageDto[];
  conversationErrorMsg: string;
  isLoading: boolean;
  pageSize: number;
  selectedMessageId?: number;
  isLastMessage: boolean;
  isFirstMessage: boolean;
  auditMessages?: MessageDto[];
}

const initialState: State = {
  conversation: undefined,
  messages: [],
  conversationErrorMsg: '',
  isLoading: false,
  pageSize: 20,
  isLastMessage: false,
  isFirstMessage: false,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case MessagesActionTypes.MESSAGES_FETCH_CONVERSATION_REQUEST:
      return initialState;
    case MessagesActionTypes.MESSAGES_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        messages: action?.payload?.resetMessages ? [] : state.messages,
      };
    case MessagesActionTypes.MESSAGES_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        messages: [...state.messages, ...action.payload],
      };
    case MessagesActionTypes.MESSAGES_AFTER_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        messages: [...action.payload.reverse(), ...state.messages],
        isLastMessage: action.payload.length < state.pageSize,
      };
    case MessagesActionTypes.MESSAGES_BEFORE_FETCH_SUCCESS:
      if (!action.payload) return state;
      return {
        ...state,
        isLoading: false,
        messages: [...state.messages, ...action.payload.messages],
        isFirstMessage: action.payload.messages.length < state.pageSize,
        isLastMessage: action.payload.isLastMessage !== undefined ? action.payload.isLastMessage : state.isLastMessage,
      };
    case MessagesActionTypes.MESSAGES_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        conversationErrorMsg: action.payload,
        messages: [],
      };
    case MessagesActionTypes.MESSAGES_FETCH_CONVERSATION_SUCCESS:
      const conv = action.payload.conversation;
      return {
        ...state,
        conversationErrorMsg: '',
        conversation: {
          ...action.payload.conversation,
          endUser: action.payload.user,
          supporter: conv.supportEmployeeId
            ? action.payload.supporters.filter(user => user.id === conv.supportEmployeeId)[0]
            : undefined,
        },
      };
    case MessagesActionTypes.MESSAGES_SELECT_CONVERSATION:
      return {
        ...state,
        conversationErrorMsg: '',
        conversation: action.payload.conversation,
        selectedMessageId: action.payload.messageId,
        messages: [],
        isLastMessage: false,
        isFirstMessage: false,
        auditMessages: undefined,
      };
    case MessagesActionTypes.MESSAGES_FETCH_CONVERSATION_ERROR:
      return {
        ...state,
        conversationErrorMsg: action.payload,
      };
    case MessagesActionTypes.MESSAGES_REFRESH_IMAGES:
      return {
        ...state,
        messages: state.messages.map(item => {
          const responseMsg = action.payload.filter(m => m.id === item.id)[0];
          if (responseMsg) {
            return {
              ...item,
              mediaUrl: responseMsg.mediaUrl,
            };
          }
          return item;
        }),
      };
    case MessagesActionTypes.MESSAGES_CONFIRM:
      return {
        ...state,
        messages: state.messages.map(item => {
          if (action.payload.includes(item.id)) {
            return {
              ...item,
              confirmationReceived: true,
            };
          }
          return item;
        }),
      };
    case MessagesActionTypes.MESSAGES_ADD:
      return {
        ...state,
        messages: [action.payload, ...state.messages],
      };
    case MessagesActionTypes.MESSAGES_FETCH_AUDIT_SUCCESS:
      return {
        ...state,
        auditMessages: action.payload.messageAuditDtos
          .filter(auditMsg => auditMsg.destinationStatus === ConversationStatus.ONGOING)
          .map(auditMsg => {
            const supporter = action.payload.supporters.filter(user => user.id === auditMsg.supportEmployeeId)[0];
            return {
              id: auditMsg.id,
              content: i18n.t('messages.conversation-overtaken', {
                user: supporter
                  ? `${supporter.firstName} ${supporter.lastName}`
                  : `User with id ${auditMsg.supportEmployeeId}`,
              }),
              conversationId: auditMsg.supportConversationId,
              type: MessageType.AUDIT,
              createdAt: auditMsg.created,
            };
          }),
      };
    default:
      return state;
  }
};

export const useMessagesReducer = () => {
  const [messagesState, dispatchMessages] = useReducer<Reducer<State, Action>>(reducer, initialState);
  return { messagesState, dispatchMessages };
};
