import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TextSmall } from '../../typography/Texts';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

interface Props {
  totalNumberOfRows: number;
  page: number;
  rowsPerPage: number;
  onPageChanged: (newPage: number) => void;
}

const Pagination = (props: Props) => {
  const { totalNumberOfRows, page, rowsPerPage, onPageChanged } = props;

  const numberOfPages = Math.ceil(totalNumberOfRows / rowsPerPage);

  const handleFirstPageButtonClick = () => {
    onPageChanged(0);
  };

  const handleBackButtonClick = () => {
    onPageChanged(page - 1);
  };

  const handleNextButtonClick = () => {
    onPageChanged(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onPageChanged(Math.max(0, numberOfPages - 1));
  };

  if (numberOfPages <= 1) return <></>;
  return (
    <Container>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        <KeyboardArrowLeft />
      </IconButton>
      <TextSmall>
        {page + 1} of {numberOfPages}
      </TextSmall>
      <IconButton onClick={handleNextButtonClick} disabled={page >= numberOfPages - 1}>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= numberOfPages - 1}>
        <LastPageIcon />
      </IconButton>
    </Container>
  );
};

export default Pagination;
