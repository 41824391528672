import React, { useEffect, useState } from 'react';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import LoadingIndicator from '../common/LoadingIndicator';
import { CampaignPublicDto } from '../../services/Campaign/campaignService.dto';
import { campaignService } from '../../services/Campaign/campaignService';
import CampaignDetails from './CampaignDetails';

interface Props {
  campaignId: number;
}

const CampaignDetailsPopover = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState<CampaignPublicDto>();
  const { handleError } = useGlobalError();

  useEffect(() => {
    setIsLoading(true);
    campaignService
      .fetchCampaign(props.campaignId)
      .then(response => {
        setCampaignDetails(response.data);
      })
      .catch(error => {
        handleError(error, error.message);
      })
      .finally(() => setIsLoading(false));
  }, [props.campaignId]);

  if (!campaignDetails || isLoading) return <LoadingIndicator />;
  return <CampaignDetails campaignDetails={campaignDetails} />;
};

export default CampaignDetailsPopover;
