import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';

const borderRadius = `${theme.borderRadiusStandard}px`;
const padding = `${theme.paddingStandard}px`;

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  padding: ${padding};
`;

const ClickableContainer = styled('div')`
  border: 1px solid ${theme.color.lightGray};
  padding: 8px;
  cursor: pointer;
  width: 30%;
  :hover {
    text-decoration: underline;
  }
`;

const SearchContainer = styled(ClickableContainer)`
  border-radius: ${borderRadius} 0 0 ${borderRadius};
`;

const AdminsContainer = styled(ClickableContainer)`
  border-radius: 0 ${borderRadius} ${borderRadius} 0;
`;

interface Props {
  displayAdmins?: boolean;
  setDisplayAdmins: (display: boolean) => void;
}

const AdminsSelector = (props: Props) => {
  const { t } = useTranslation();
  const activeSelector = {
    backgroundColor: theme.color.red,
    color: theme.color.white,
  };

  return (
    <Container>
      <SearchContainer
        onClick={() => props.setDisplayAdmins(false)}
        style={props.displayAdmins ? undefined : activeSelector}>
        {t('users.admins-search')}
      </SearchContainer>
      <AdminsContainer
        onClick={() => props.setDisplayAdmins(true)}
        style={props.displayAdmins ? activeSelector : undefined}>
        {t('users.admins-admins')}
      </AdminsContainer>
    </Container>
  );
};

export default AdminsSelector;
