import i18n from '../../translations/config';

export interface NavItemType {
  name: string;
  path: string;
  subItems?: NavItemType[];
}

export const messagesNavItem: NavItemType = { name: i18n.t('messages.title'), path: '/messages/' };

export const navItems: NavItemType[] = [
  {
    name: i18n.t('shipment.title'),
    path: '/shipment',
    subItems: [
      { name: i18n.t('shipment.title'), path: '/shipment/error' },
      { name: i18n.t('shipment-costs.title'), path: '/shipment/aggregated-costs' },
    ],
  },
  {
    name: i18n.t('payments.title'),
    path: '/payments',
    subItems: [
      { name: i18n.t('withdraw-money.title'), path: '/payments/withdraw-money' },
      { name: i18n.t('manual-refunds.title'), path: '/payments/manual-refunds' },
      { name: i18n.t('transactions.title'), path: '/payments/transactions' },
      { name: i18n.t('payment-providers.title'), path: '/payments/providers' },
    ],
  },
  { name: i18n.t('report.title'), path: '/report' },
  { name: i18n.t('tags.title'), path: '/tags' },
  {
    name: i18n.t('users.title'),
    path: '/users',
    subItems: [
      { name: i18n.t('users.title-blocked'), path: '/users/blocked' },
      { name: i18n.t('users.title-find'), path: '/users/find-user' },
      { name: i18n.t('users.title-admins'), path: '/users/admins' },
      { name: i18n.t('users.title-fundraisers'), path: '/users/fundraisers' },
    ],
  },
  { name: i18n.t('campaigns.title'), path: '/campaigns' },
  {
    name: 'Maintenance',
    path: '/maintenance',
    subItems: [
      { name: i18n.t('maintenance.cemetery.title'), path: '/maintenance/cemetery' },
      { name: i18n.t('maintenance.recovery.title'), path: '/maintenance/reprocessing' },
    ],
  },
  {
    name: i18n.t('feature-flags.title'),
    path: '/feature-flags',
  },
  {
    name: i18n.t('auctions.title'),
    path: '/auctions',
    subItems: [
      { name: i18n.t('auctions.title-unpaid'), path: '/auctions/unpaid' },
      { name: i18n.t('auctions.title-deleted'), path: '/auctions/deleted' },
    ],
  },
  {
    name: i18n.t('orders.title'),
    path: '/orders',
    subItems: [
      { name: i18n.t('orders.title-manage-orders'), path: '/orders/manage' },
      { name: i18n.t('orders.title-refund-requests'), path: '/orders/refund-requests' },
    ],
  },
  {
    name: i18n.t('geolocation.title'),
    path: '/geolocation',
  },
];
