import React from 'react';
import styled from 'styled-components';
import ChatIcon from '@mui/icons-material/Chat';
import ActivateUser from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import { FundraiserDetailsDto } from '../../services/User/userService.dto';

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-self: center;
`;

interface Props {
  fundraiser: FundraiserDetailsDto;
  onContactUser: () => void;
  onVerify: (element: HTMLDivElement, fundraiser: FundraiserDetailsDto) => void;
}

const FundraiserActions = (props: Props) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const { fundraiser, onVerify, onContactUser } = props;

  const isVerifiedByAdmin = fundraiser.fundraiserVerificationCompleted;

  return (
    <Container>
      {!isVerifiedByAdmin && (
        <StyledButton title={t('users.fundraisers-verify')} onClick={e => onVerify(e.currentTarget, fundraiser)}>
          <ActivateUser style={{ fontSize: 20 }} />
        </StyledButton>
      )}

      {currentUser?.id !== fundraiser.id && (
        <StyledButton title={t('users.contact-label')} onClick={onContactUser}>
          <ChatIcon style={{ fontSize: 20 }} />
        </StyledButton>
      )}
    </Container>
  );
};

export default FundraiserActions;
