import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/svg/logo-with-name-horizontal.svg';
import menu from '../../assets/svg/menu.svg';
import { theme } from '../../assets/styles/theme';
import { SectionHeader } from '../typography/Headers';
import { useAuth } from '../../providers/AuthProvider';
import NavMenuItem from './NavMenuItem';
import MyProfile from './MyProfile';
import Drawer from './Drawer';
import { messagesNavItem, navItems } from './navItems';

const NavBarContainer = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${theme.color.white};
  position: absolute;
  height: ${theme.navbarHeight}px;
  width: 100%;
  right: 0;
  z-index: ${theme.zIndex.primaryNav};

  &:after {
    content: ' ';
    position: absolute;
    bottom: -2px;
    left: 50%;
    height: 2px;
    transform: translate(-50%, -100%);
    width: 100vw;
    background-color: ${theme.color.lightGray};
  }

  ${theme.mq.tablet} {
    background-color: ${theme.color.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

const ContentContainer = styled.div`
  position: absolute;
  left: ${theme.navbarPadding}px;
  right: ${theme.navbarPadding}px;
  display: flex;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding-top: 5px;
`;

const MenuIconContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  ${theme.mq.tablet} {
    display: none;
  }
`;

const Title = styled(SectionHeader)`
  margin: 0 10px;
  ${theme.mq.tablet} {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  display: none;
  width: 100%;

  ${theme.mq.tablet} {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:after {
      display: none;
    }
  }
`;

const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${theme.mq.tablet} {
    flex-direction: row;
  }
`;

const ProfileWrapper = styled.div`
  width: 50px;
  position: absolute;
  right: 10px;
  display: none;
  ${theme.mq.tablet} {
    display: flex;
  }
`;

const Navbar = () => {
  const { t } = useTranslation();
  const { unreadMessagesCount } = useAuth();
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  return (
    <NavBarContainer>
      <LogoContainer>
        <Link to='/'>
          <img src={logo} alt='Giveo' width={90} height={45} />
        </Link>
      </LogoContainer>

      <ContentContainer>
        <Title>
          <b>{t('app.title')}</b>
        </Title>
        <MenuWrapper>
          <Menu>
            <NavMenuItem navItem={messagesNavItem} unreadCount={unreadMessagesCount} />
            {navItems.map(item => (
              <NavMenuItem key={item.name} navItem={item} />
            ))}
          </Menu>
        </MenuWrapper>
      </ContentContainer>

      <ProfileWrapper>
        <MyProfile />
      </ProfileWrapper>

      <MenuIconContainer onClick={toggleMenu}>
        <img src={menu} alt='menu' />
      </MenuIconContainer>

      <Drawer toggleMenu={toggleMenu} isMenuVisible={isMenuVisible} />
    </NavBarContainer>
  );
};

export default Navbar;
