import React from 'react';
import Home from '../pages/Home';
import Messages from '../pages/Messages';
import Login from '../pages/Login';
import Tags from '../pages/Tags';
import UsersBlocked from '../pages/users/UsersBlocked';
import FindUser from '../pages/users/FindUser';
import Report from '../pages/report/Report';
import WithdrawMoney from '../pages/payment/WithdrawMoney';
import Shipment from '../pages/shipment/Shipment';
import Cemetery from '../pages/maintenance/Cemetery';
import Recovery from '../pages/maintenance/Recovery';
import Admins from '../pages/users/Admins';
import AggregatedShipmentCosts from '../pages/shipment/AggregatedShipmentCosts';
import ShipmentCosts from '../pages/shipment/ShipmentCosts';
import ManualRefunds from '../pages/payment/ManualRefunds';
import Campaigns from '../pages/campaigns/Campaigns';
import FeatureFlags from '../pages/featureFlags/FeatureFlags';
import UnpaidAuctions from '../pages/auctions/UnpaidAuctions';
import DeletedAuctions from '../pages/auctions/DeletedAuctions';
import Fundraisers from '../pages/users/Fundraisers';
import Orders from '../pages/orders/Orders';
import RefundRequests from '../pages/orders/RefundRequests';
import Geolocation from '../pages/geolocation/Geolocation';
import Transactions from '../pages/payment/Transactions';
import PaymentProviders from '../pages/payment/PaymentProviders';

interface RoutesInterface {
  path: string;
  element: React.ReactNode;
  isPublic?: boolean;
}

export const routes: RoutesInterface[] = [
  { path: '/', element: <Home /> },
  { path: '/login', element: <Login />, isPublic: true },
  { path: '/messages/:tabName?/:conversationId?', element: <Messages /> },
  { path: '/tags', element: <Tags /> },
  { path: '/users/blocked', element: <UsersBlocked /> },
  { path: '/users/find-user', element: <FindUser /> },
  { path: '/users/admins', element: <Admins /> },
  { path: '/users/fundraisers/:userId?', element: <Fundraisers /> },
  { path: '/campaigns', element: <Campaigns /> },
  { path: '/report', element: <Report /> },
  { path: '/payments/withdraw-money', element: <WithdrawMoney /> },
  { path: '/payments/transactions', element: <Transactions /> },
  { path: '/payments/manual-refunds/:orderId?', element: <ManualRefunds /> },
  { path: '/payments/providers', element: <PaymentProviders /> },
  { path: '/maintenance/cemetery', element: <Cemetery /> },
  { path: '/maintenance/reprocessing', element: <Recovery /> },
  { path: '/shipment/error', element: <Shipment /> },
  { path: '/shipment/aggregated-costs', element: <AggregatedShipmentCosts /> },
  { path: '/shipment/costs/:period', element: <ShipmentCosts /> },
  { path: '/feature-flags', element: <FeatureFlags /> },
  { path: '/auctions/unpaid', element: <UnpaidAuctions /> },
  { path: '/auctions/deleted', element: <DeletedAuctions /> },
  { path: '/orders/manage/:orderId?', element: <Orders /> },
  { path: '/orders/refund-requests/:orderId?', element: <RefundRequests /> },
  { path: '/geolocation', element: <Geolocation /> },
];
