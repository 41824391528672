import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Checkbox, Dialog } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { CloseButton } from '../buttons/popoverButtons';
import closeIcon from '../../assets/svg/close.svg';
import { CampaignAdminDto } from '../../services/Campaign/campaignService.dto';
import UserSelector from '../users/UserSelector/UserSelector';
import { UserDto } from '../../services/User/userService.dto';
import RectangularButton from '../buttons/RectangularButton';
import { SectionHeader } from '../typography/Headers';
import { theme } from '../../assets/styles/theme';
import PriceInput from '../inputs/PriceInput';
import { usePopup } from '../../providers/PopupProvider';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import LoadingIndicator from '../common/LoadingIndicator';
import { CashDonationFromDialogDto } from './types';
import { useProcessCashDonations } from './useProcessCashDonations';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  gap: 10px;
  width: 500px;
  height: 450px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  & tr {
    border-bottom: 2px solid ${theme.color.lighterGray};
    height: 55px;
  }
  & tr:last-child {
    border-bottom: none;
  }
  & td {
    padding: 0 10px;
  }
  & td:last-child {
    width: 80%;
    border-left: 2px solid ${theme.color.lighterGray};
  }
`;

const SelectedUser = styled.div`
  display: flex;
`;

const DonateButton = styled(RectangularButton)`
  width: 100%;
  font-weight: bold;
  height: 40px;
  position: absolute;
  bottom: 0;
  border-radius: 0;
`;

const Icon = styled(EditIcon)`
  cursor: pointer;
  color: ${theme.color.red};
  margin: 0 10px;
`;

const StyledCheckbox = styled(Checkbox)`
  transform: scale(1.5);

  &.MuiCheckbox-root,
  &.MuiCheckbox-root.Mui-checked {
    color: ${theme.color.red};
  }
`;

const Comment = styled.textarea`
  display: flex;
  background-color: ${theme.color.lighterGray};
  font-family: ${theme.font.family.main};
  font-size: ${theme.font.size.s};
  line-height: 2.2rem;
  letter-spacing: -0.4px;
  margin: 5px 0;
  border-radius: 5px;
  border: 0;
  outline: 0;
  flex: 1;
  width: 100%;
  padding: 5px;
  resize: none;
`;

interface RowProps {
  label: string;
  children: JSX.Element | string;
}

const Row = ({ label, children }: RowProps) => {
  return (
    <tr>
      <td>{label}</td>
      <td>{children}</td>
    </tr>
  );
};

interface Props {
  campaign: CampaignAdminDto;
  onClosePressed: () => void;
  onDonationCompleted: () => void;
}

const AddManualDonationDialog = ({ campaign, onDonationCompleted, onClosePressed }: Props) => {
  const { t } = useTranslation();
  const { showConfirmDialog } = usePopup();
  const { errorMessage } = useGlobalError();
  const { process, isProcessing } = useProcessCashDonations();
  const [user, setUser] = useState<UserDto>();
  const [amount, setAmount] = useState(0);
  const [anonymous, setAnonymous] = useState(false);
  const [comment, setComment] = useState('');
  const userInfo = useMemo(() => (user ? `${user.firstName} ${user.lastName} (#${user.id})` : ''), [user]);

  const handleOnClick = () => {
    if (!user || !amount || !comment) {
      errorMessage('Invalid cash donation request');
      return;
    }
    const cashDonation: CashDonationFromDialogDto = {
      campaignId: campaign.id,
      userId: user.id,
      donationAmount: amount,
      currency: 'USD',
      anonymous: anonymous,
      comment: comment,
    };
    showConfirmDialog(
      t('campaigns.manual-donations.confirmation-question', {
        amount: amount,
        currency: cashDonation.currency,
        user: userInfo,
      }),
      () => process(cashDonation, onDonationCompleted)
    );
  };

  return (
    <Dialog open={true} onClose={onClosePressed}>
      <FormContainer>
        {isProcessing && <LoadingIndicator />}
        {!isProcessing && (
          <>
            <SectionHeader>{t('campaigns.manual-donations.header')}</SectionHeader>
            <CloseButton src={closeIcon} alt={'close'} onClick={onClosePressed} />
            <Table>
              <tbody>
                <Row label={t('campaigns.manual-donations.campaign')}>{campaign.name}</Row>
                <Row label={t('campaigns.manual-donations.user')}>
                  {!!user ? (
                    <SelectedUser>
                      <span>{userInfo}</span>
                      <Icon style={{ fontSize: '20px' }} onClick={() => setUser(undefined)} />
                    </SelectedUser>
                  ) : (
                    <UserSelector onUserSelected={setUser} />
                  )}
                </Row>
                <Row label={t('campaigns.manual-donations.amount')}>
                  <PriceInput onChange={setAmount} value={amount} />
                </Row>
                <Row label={t('campaigns.manual-donations.anonymous')}>
                  <StyledCheckbox checked={anonymous} onChange={(event, checked) => setAnonymous(checked)} />
                </Row>
                <Row label={t('campaigns.manual-donations.comment')}>
                  <Comment value={comment} rows={3} onChange={e => setComment(e.target.value)} />
                </Row>
              </tbody>
            </Table>

            <DonateButton
              label={t('campaigns.manual-donations.donate-btn')}
              onClick={handleOnClick}
              disabled={!user || !amount || !comment}
            />
          </>
        )}
      </FormContainer>
    </Dialog>
  );
};

export default AddManualDonationDialog;
