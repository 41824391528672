import React, { useEffect, useState } from 'react';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import LoadingIndicator from '../common/LoadingIndicator';
import { ItemPublicDto } from '../../services/Item/itemService.dto';
import { itemService } from '../../services/Item/itemService';
import ItemDetails from './ItemDetails';

interface Props {
  itemId: number;
}

const ItemDetailsPopover = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState<ItemPublicDto>();
  const { handleError } = useGlobalError();

  useEffect(() => {
    setIsLoading(true);
    itemService
      .fetchItem(props.itemId)
      .then(response => {
        setItemDetails(response.data);
      })
      .catch(error => {
        handleError(error, error.message);
      })
      .finally(() => setIsLoading(false));
  }, [props.itemId]);

  if (!itemDetails || isLoading) return <LoadingIndicator />;
  return <ItemDetails itemDetails={itemDetails} />;
};

export default ItemDetailsPopover;
