import EditIcon from '@mui/icons-material/Edit';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const DataContainer = styled.div`
  width: 80%;
  padding-left: 20%;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  padding: 2px 5px 0 5px;
  align-items: center;
`;

interface Props {
  onClick: (element: HTMLDivElement) => void;
  children: ReactNode;
}

const CenteredCellWithEdit = ({ onClick, children }: Props) => {
  return (
    <Container>
      <DataContainer>{children}</DataContainer>
      <ButtonContainer onClick={event => onClick(event.currentTarget)}>
        <EditIcon />
      </ButtonContainer>
    </Container>
  );
};

export default CenteredCellWithEdit;
