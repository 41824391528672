import React from 'react';
import styled from 'styled-components';
import { Radio, FormControlLabel } from '@mui/material';
import { theme } from '../../assets/styles/theme';
import { TextMain } from '../typography/Texts';

const Container = styled.div`
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled(TextMain)`
  color: ${theme.color.darkerGray};

  &:hover {
    color: ${theme.color.black};
  }
`;

interface Props {
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

const RadioButtonWithLabel = (props: Props) => {
  return (
    <Container>
      <FormControlLabel
        control={
          <Radio
            checked={props.checked}
            onChange={radioButton => props.onChange(radioButton.target.checked)}
            style={{ color: theme.color.red }}
          />
        }
        label={<Label>{props.label}</Label>}
      />
    </Container>
  );
};

export default RadioButtonWithLabel;
