import React, { useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../providers/AuthProvider';
import { theme } from '../assets/styles/theme';
import { SubTitle } from '../components/typography/Headers';
import logo from '../assets/svg/logo-with-name-vertical.svg';
import errorIcon from '../assets/svg/circle-error.svg';
import PasswordInputField from '../components/inputs/PasswordInputField';
import EmailInputField from '../components/inputs/EmailInputField';
import RectangularButton from '../components/buttons/RectangularButton';
import { TextSmall } from '../components/typography/Texts';

const Container = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
  margin: 0 auto;
`;

const Section = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 350px;

  ${theme.mq.tablet} {
    min-height: 100vh;
    justify-content: center;
  }
`;

const Logo = styled.img`
  width: 80px;
  height: 144px;
  margin-bottom: 20px;
  margin-top: 50px;
  ${theme.mq.tablet} {
    margin-top: 0;
  }
`;

const StyledTitle = styled(SubTitle)`
  text-align: center;
  margin-bottom: 40px;
`;

const InputContainer = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const StyledRectangularButton = styled(RectangularButton)`
  padding: 11px 20px;
  margin-bottom: 20px;
  height: auto;
  width: 100%;
`;

const ErrorInfoContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: auto;
  width: 100%;
`;

const ErrorInfoTxt = styled(TextSmall)`
  margin-left: 10px;
`;

const Login = () => {
  const location = useLocation();
  const referer = location.state?.from || '/';
  const { t } = useTranslation();
  const { token, login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleLogin = () => {
    login(email, password).catch(error => setErrorMsg(error.message));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  if (token) return <Navigate to={referer} />;
  return (
    <Container onKeyDown={handleKeyPress}>
      <Section>
        <Logo src={logo} alt='Giveo' />
        <StyledTitle>{t('app.title')}</StyledTitle>

        <InputContainer>
          <EmailInputField
            onChange={event => setEmail(event.target.value)}
            value={email}
            placeholder={t('login.input-email-placeholder')}
          />
        </InputContainer>

        <InputContainer>
          <PasswordInputField
            onChange={event => setPassword(event.target.value)}
            value={password}
            placeholder={t('login.input-password-placeholder')}
          />
        </InputContainer>

        {errorMsg !== '' && (
          <ErrorInfoContainer>
            <img src={errorIcon} alt='' />
            <ErrorInfoTxt>{errorMsg}</ErrorInfoTxt>
          </ErrorInfoContainer>
        )}

        <StyledRectangularButton
          label={t('login.button-login')}
          onClick={handleLogin}
          disabled={password === '' || email === ''}
        />
      </Section>
    </Container>
  );
};

export default Login;
