import React from 'react';
import styled from 'styled-components';
import PageContainer from '../../components/common/PageContainer';
import { theme } from '../../assets/styles/theme';
import ManualRefundsTable from '../../components/payment/ManualRefundsTable';

const Container = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const ManualRefunds = () => {
  return (
    <PageContainer>
      <Container>
        <ManualRefundsTable />
      </Container>
    </PageContainer>
  );
};

export default ManualRefunds;
