import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ConversationWithDataDto } from '../../services/Message/messageService.dto';
import { useMessages } from '../../providers/messages/MessagesProvider';
import ConversationListItem from './ConversationListItem';
import ConversationEmptyList from './ConversationEmptyList';

const Container = styled.div`
  flex: 1;
  margin: 10px 0;
  overflow-y: auto;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

interface Props {
  path: string;
  selectedConversationId?: number;
  conversations: ConversationWithDataDto[];
}

const SearchConversationList = (props: Props) => {
  const { selectConversation } = useMessages();

  const renderConversations = () => {
    if (props.conversations.length === 0) {
      return <ConversationEmptyList />;
    } else {
      return props.conversations.map(item => {
        const isSelected = item.id === props.selectedConversationId;
        const messageId = item.searchMessages?.length === 1 ? item.searchMessages[0].messageId : undefined;
        return (
          <StyledLink key={item.id} to={`${props.path}/${item.id}`} onClick={() => selectConversation(item, messageId)}>
            <ConversationListItem key={item.id} isSelected={isSelected} conversation={item} />
          </StyledLink>
        );
      });
    }
  };

  return <Container>{renderConversations()}</Container>;
};

export default SearchConversationList;
