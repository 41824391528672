import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';
import { CampaignPublicDto } from '../../services/Campaign/campaignService.dto';
import { shortDescription, SHORT_DESC_LENGTH, toCurrencyString } from '../../pages/report/utils/reportConverters';
import { DetailViewData, DetailsViewTag } from '../typography/Headers';
import { DetailsViewText } from '../typography/Texts';
import { Container, DetailsContainer, RowContainer } from '../typography/DetailsViewCommons';
import ImagePreview from '../common/image/ImagePreview';

const VideoLink = styled.a`
  text-decoration: none;
  color: ${theme.color.lightBlack};
`;

interface Props {
  campaignDetails: CampaignPublicDto;
}

const CampaignDetails = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ImagePreview imageUrls={props.campaignDetails.imageUrls} />
      <DetailsContainer>
        <RowContainer>
          <DetailsViewTag>{t('campaign-details.title-label')}</DetailsViewTag>
          <DetailViewData>{props.campaignDetails.name}</DetailViewData>
        </RowContainer>
        <RowContainer>
          <DetailsViewTag>{t('campaign-details.description-label')}</DetailsViewTag>
          <DetailViewData>{shortDescription(props.campaignDetails.description)}</DetailViewData>
        </RowContainer>
        {props.campaignDetails.description && props.campaignDetails.description.length >= SHORT_DESC_LENGTH && (
          <DetailsViewText>{props.campaignDetails.description}</DetailsViewText>
        )}
        <RowContainer>
          <DetailsViewTag>{t('campaign-details.location-label')}</DetailsViewTag>
          <DetailViewData>{props.campaignDetails.campaignLocation.city}</DetailViewData>
        </RowContainer>
        <RowContainer>
          <DetailsViewTag>{t('campaign-details.goal-label')}</DetailsViewTag>
          <DetailViewData>{toCurrencyString(props.campaignDetails.moneyGoal)}</DetailViewData>
        </RowContainer>
        <RowContainer>
          <DetailsViewTag>{t('campaign-details.collected-label')}</DetailsViewTag>
          <DetailViewData>{toCurrencyString(props.campaignDetails.fundsRaised)}</DetailViewData>
        </RowContainer>
        <RowContainer>
          <DetailsViewTag>{t('campaign-details.supporting-items')}</DetailsViewTag>
          <DetailViewData>{props.campaignDetails.supportersNumber}</DetailViewData>
        </RowContainer>
        <RowContainer>
          <DetailsViewTag>{t('campaign-details.video-link')}</DetailsViewTag>
          {props.campaignDetails?.videoUrl && (
            <DetailViewData>
              <VideoLink href={props.campaignDetails.videoUrl} target='_blank'>
                {t('campaign-details.video-link-desc')}
              </VideoLink>
            </DetailViewData>
          )}
        </RowContainer>
      </DetailsContainer>
    </Container>
  );
};

export default CampaignDetails;
