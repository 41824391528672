import { AxiosPromise } from 'axios';
import { apiService, apiUrl } from '../apiService';
import PageableType from '../utils/pageableType';
import {
  CampaignAdminDto,
  CampaignCategoryDto,
  CampaignFilterDto,
  CampaignPublicDto,
  CommentDto,
} from './campaignService.dto';

class CampaignService {
  static BASE_URL = `${apiUrl}/api/campaign`;

  fetchCampaign(id: number): AxiosPromise<CampaignPublicDto> {
    const url = `${CampaignService.BASE_URL}/admin/campaigns/${id}`;
    return apiService.get(url);
  }

  fetchComment(id: number): AxiosPromise<CommentDto> {
    const url = `${CampaignService.BASE_URL}/admin/comments/${id}`;
    return apiService.get(url);
  }

  fetchCampaigns(filter: CampaignFilterDto): AxiosPromise<PageableType<CampaignAdminDto>> {
    const url = `${CampaignService.BASE_URL}/admin/campaigns`;
    return apiService.get(url, filter);
  }

  fetchCategories(): AxiosPromise<CampaignCategoryDto[]> {
    const url = `${CampaignService.BASE_URL}/public/categories`;
    return apiService.get(url);
  }

  updateSortingBoost(campaignId: number, adminSortingBoost: number): AxiosPromise<void> {
    const url = `${CampaignService.BASE_URL}/admin/campaigns/${campaignId}/admin-sorting-boost`;
    return apiService.patch(url, {}, {}, { adminSortingBoost });
  }
}

const campaignService = new CampaignService();

export { campaignService, CampaignService };
