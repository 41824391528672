import React from 'react';
import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';

const InputContainer = styled.div`
  position: relative;
  padding: 11px 15px;
  background-color: ${theme.color.lighterGray};
  border-radius: ${theme.borderRadiusStandard}px;
  min-width: 300px;
`;

const EmailInput = styled.input`
  max-width: 1400px;
  display: flex;
  background-color: ${theme.color.lighterGray};
  font-size: ${theme.font.size.xs};
  font-family: ${theme.font.family.main};
  align-items: center;
  border: 0;
  outline: 0;
  width: 92%;
`;

interface Props {
  placeholder?: string;
  onChange: (value: any) => void;
  value: string;
}

const EmailInputField = (props: Props) => {
  return (
    <InputContainer>
      <EmailInput
        placeholder={props.placeholder}
        type={'email'}
        value={props.value}
        onChange={props.onChange}
        maxLength={255}
        autoCapitalize='none'
        autoCorrect='none'
        autoComplete='email'
      />
    </InputContainer>
  );
};

export default EmailInputField;
